import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import CreateProcess from "../../components/section/process/create-process";

function CreateInspectionWrapper({setIsInspectionModalOpen,handleRefresh, processUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateProcess handleRefresh={handleRefresh} setIsInspectionModalOpen={setIsInspectionModalOpen} processUpdated={processUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateInspectionWrapper;
