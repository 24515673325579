import { ReactNode } from "react";

interface IconTextBtnProps {
  label: string;
  icon?: ReactNode;
  btnSize: string;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  style?: string;
  type?: "submit" | "reset" | "button" | undefined;
  isLoading?: false;
}
function IconTextBtn({
  label,
  icon,
  btnSize,
  handleClick,
  disabled,
  style,
  type,
  isLoading,
}: IconTextBtnProps) {
  return (
    <button
      type={type}
      className="w-auto flex justify-center items-center gap-3 border-none bg-none disabled:opacity-50"
      onClick={(e) => {
        e.preventDefault(); // Prevent the default behavior
        if (handleClick) {
          handleClick(e); // Call handleClick with the event
        }
      }}
      disabled={disabled}
    >
      {!isLoading && (
        <>
          <div
            className={`${
              btnSize === "small" ? "w-[1.2rem]" : "w-[1.8rem]"
            } h-auto`}
          >
            {icon}
          </div>
          <span
            className={`${
              btnSize === "small" ? "text-[1.2rem]" : "text-[1.8rem]"
            } font-inter font-normal text-io-gray-47 dark:text-io-black-d1 ${
              style ? style : ""
            }`}
          >
            {label}
          </span>
        </>
      )}
      {isLoading && (
        <div className="w-full h-auto flex justify-center items-center gap-4">
          <div className="w-[2rem] h-[2rem]">
            <div className="btn-loader"></div>
          </div>
        </div>
      )}
    </button>
  );
}
export default IconTextBtn;
