import { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/global-layout/page-header";
import ProcessGrid from "../../components/section/process/process-grid";
import { ProcessPageHeaderIcon } from "../../components/svg";
import ProcessMoreInfo from "../../components/section/process/process-more-info";
import ProcessCards from "../../components/section/process/process-cards";
import GridToolBar from "../../components/UI/grid-toolbar";
import GridColumn from "../../components/section/user-management/grid-columns";
import PageLoader from "../../components/UI/page-loader";
import { processServices } from "../../services/InspectionOneServices";
import excelExport from "../../services/excelExport";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Authentication from "../../auth/Authentication";
import { Outlet, useNavigate } from "react-router-dom";
import CreateInspectionWrapper from "./create-inspection-wrapper";
import { formatDateTime } from "../../utils/common";

interface Item {
  processName: string;
  processLogic: string;
  inspectedBy: string;
  verificationStatus: string;
  createdDate: string;
  id: string;
}

function Process({ processUpdated, setProcessUpdated }: any) {
  const authService = Authentication();
  const { t } = useTranslation();
  const generateGuid = () => {
    return uuidv4();
  };
  const navigate = useNavigate();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [processData, setProcessData] = useState<any>([]);
  const [statusDetail, setStatusDetail] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [pageLoader, setPageLoader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [resetFilter, setResetFilter] = useState<any>(false);
  const [siteConfigDetails, setSiteConfigDetails] = useState<any>(false);
  const [accessMode, setAccessMode] = useState<string>("");
  const [userProfileImage, setUserProfileImage] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "processName",
    "processLogic",
    "subProcessLogic",
    "inspectedBy",
    "createdDate",
    "verificationStatus",
  ]);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [isInspectionModalOpen, setIsInspectionModalOpen] =
    useState<any>(false);
  const columnsData = [
    {
      label: "Serial Number",
      token: "web.process.label.name",
      key: "processName",
      isView: 1,
    },
    {
      label: "Logic Name",
      token: "web.common.label.logicname",
      key: "processLogic",
      isView: 1,
    },
    {
      label: "Screen Mapping Name",
      token: "web.common.label.screenmappingname",
      key: "subProcessLogic",
      isView: 1,
    },
    {
      label: "Inspected By",
      token: "web.common.label.inspectedby",
      key: "inspectedBy",
      isView: 1,
    },
    {
      label: "Created Date and Time",
      token: "web.common.label.createddate",
      key: "createdDate",
      isView: 1,
    },
    ...(siteConfigDetails.isShiftEnabled
      ? [
          {
            label: "Shift",
            token: "web.common.label.shift",
            key: "shift",
            isView: 0,
          },
        ]
      : []),
    {
      label: "Verification Status",
      token: "web.common.label.verificationstatus",
      key: "verificationStatus",
      isView: 1,
    },
  ];
  useEffect(() => {
    let parsedLoginDetails = null;
    parsedLoginDetails = authService.decryptData("UserDetails", 2);
    parsedLoginDetails = parsedLoginDetails || "{}";
    setAccessMode(parsedLoginDetails.isViewAccess);
    setUserProfileImage(parsedLoginDetails.imageUrl);
    let siteConfigDetails = null;
    siteConfigDetails = authService.decryptData("siteConfigDetails", 2);
    siteConfigDetails = siteConfigDetails || "{}";
    setSiteConfigDetails(siteConfigDetails);
  }, []);

  const handleOpenMoreInfo = (item: Item) => {
    setSelectedItem(item);
    setShowMoreInfo(true);
  };

  const handleRefresh = () => {
    console.log("helloo");

    if (!isLoading) {
      setProcessData([]);
      setSortingColumnName("");
      setSorting(0);
      setStartIndex(0);
      setIsRefreshed((prev: any) => !prev);
      setPageLoader(false);
      setSearchedQuery("");
      setSearchedInputVal("");
      setResetSearch(true);
      setResetFilter(true);
      resetProcessFilterState();
    }
  };

  const createNewInspection = () => {
    setIsInspectionModalOpen(true);
  };

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };

  //#region Use Effect
  useEffect(() => {
    const toolItems = ["search", "export", "refresh", "gridfilter"];
    if (siteConfigDetails?.isGetStarted === 1) {
      toolItems.unshift("create");
    }
    if (isGridView === true) {
      setToolItems([...toolItems]);
    } else {
      setToolItems([...toolItems, "columns"]);
    }
  }, [isGridView, siteConfigDetails]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setProcessData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);
  const [processFilterState, setProcessFilterState] = useState({
    dateRange: { startDate: "", endDate: "" },
    shift: { startTime: "", endTime: "", uniqueId: "" },
    processLogicId: "",
    subProcessLogicId: "",
    refresh: false,
  });
  const resetProcessFilterState = () => {
    setProcessFilterState({
      dateRange: { startDate: "", endDate: "" },
      shift: { startTime: "", endTime: "", uniqueId: "" },
      processLogicId: "",
      subProcessLogicId: "",
      refresh: false,
    });
  };
  useEffect(() => {
    if (processData === true) {
      setProcessData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [processUpdated]);
  useEffect(() => {
    if (!isLoading) {
      fetchProcessData(searchedInputVal);
      setResetSearch(false);
      setResetFilter(false);
      setProcessUpdated(undefined);
    }
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isRefreshed,
    processUpdated,
  ]);
  useEffect(() => {
    if (processFilterState.refresh) {
      handleRefresh();
      fetchProcessData(searchedInputVal);
      const updatedFilterState = {
        ...processFilterState,
        refresh: !processFilterState.refresh,
      };
      setProcessFilterState(updatedFilterState);
    }
  }, [resetFilter, processFilterState]);

  const handleNavigate = async (cardData: any) => {
    setPageLoader(true);

    const webSdkUrl = process.env.REACT_APP_WEB_SDK_URL;

    if (cardData && cardData.id) {
      const baseUrl = `${webSdkUrl}/InspectionOne`;
      try {
        const requestJson = {
          uniqueId: generateGuid(),
          logicUniqueId: cardData.processLogicId,
          subLogicUniqueId: cardData.subProcessLogicId,
          processUniqueId: cardData.id,
          sdkPortalLink: baseUrl,
          UserType:
            cardData.verificationStatus === "Verified"
              ? "External"
              : accessMode === "0"
              ? "Internal"
              : "External",
          languageCode: "en",
          userProfileImage: userProfileImage,
        };
        const response: any = await processServices.ProcessAuthTokenAction(
          requestJson
        );
        if (response.success) {
          const { authToken, siteId, languageCode } = response.data;
          const url = `${baseUrl}/${authToken}/${siteId}/${languageCode}`;
          window.open(url, "_blank");
        }
        setPageLoader(false);
      } catch (error) {
        setPageLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  //#endregion
  const getProcessStatusData = (
    processLogicId: any,
    subProcessLogicId: any,
    statusDetail: any,
    statusId: any
  ) => {
    if (statusDetail) {
      const optionData =
        (statusDetail[processLogicId] &&
          statusDetail[processLogicId][subProcessLogicId]) ||
        [];
      const filteredStatuses = optionData.filter((status: any) => {
        return status.id === statusId;
      });
      return filteredStatuses;
    }
    return [];
  };

  //#region list Fetch
  const fetchProcessData = async (query: any) => {
    if (!isLoading) {
      setIsLoading(true);
      setIsSkeletonLoader(true);
      setFinalFetch(false);
      setNoDataFound(false);
      try {
        const requestJson = {
          filterDetails: {
            searchValue: query,
            startIndex: startIndex,
            limit: limit,
            startDate: processFilterState.dateRange.startDate,
            endDate: processFilterState.dateRange.endDate,
            sorting: sorting,
            columnName: sortingColumnName,
            isShiftEnabled: siteConfigDetails?.isShiftEnabled ?? 0,
            startTime: processFilterState?.shift?.startTime ?? "",
            endTime: processFilterState?.shift?.endTime ?? "",
            processLogicUniqueId: processFilterState.processLogicId ?? "",
            subProcessLogicUniqueId: processFilterState.subProcessLogicId ?? "",
          },
        };
        const response: any = await processServices.ProcessLists(requestJson);
        if (response.success) {
          const responseData = response.data.processDetails;
          setTotalCount(response.data.totalCount);
          setStatusDetail(response.data.statusDetail);
          const newArray = responseData.map((item: any) => ({
            processLogicId: item.processLogicId,
            subProcessLogicId: item.subProcessLogicId,
            processName: item.title === "" ? "No Label" : item.title,
            processLogic: item.processLogicName,
            subProcessLogic: item.subProcessLogicName,
            inspectedBy: item.createdBy,
            verificationStatus: item.displayStatus,
            isViewOnly: item.isViewOnly,
            verificationColour: getProcessStatusData(
              item.processLogicId,
              item.subProcessLogicId,
              response.data.statusDetail,
              item.statusId
            ),
            createdDate: formatDateTime(item.utcTime),
            moreDetails: item.moreDetails,
            statusId: item.statusId,
            processLogicUniqueId: item.processLogicUniqueId,
            icon: item.icon,
            uniqueId: item.uniqueId,
            id: item.id,
            isOverridden: item.isOverridden,
          }));
          setProcessData((prevData: any) => [...prevData, ...newArray]);
          setIsLoading(false);
          setIsSkeletonLoader(false);
          if (!responseData.length) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
          if (startIndex < limit) {
            setIntialLoading(false);
          }
        }
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setProcessData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "ailogicresults";
    let fileName = "AI Logic Results";
    const sheetName = "AI_Logic_Results";

    if (
      processFilterState?.dateRange?.endDate ||
      processFilterState?.dateRange?.startDate ||
      (processFilterState?.shift?.startTime &&
        processFilterState?.shift?.endTime)
    ) {
      const formatDate = (date: string) => {
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(date));
      };

      const formatTime = (time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes);
        return new Intl.DateTimeFormat("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }).format(date);
      };

      let datePart = "";

      if (
        processFilterState.dateRange.startDate ||
        processFilterState.dateRange.endDate
      ) {
        if (
          processFilterState.dateRange.startDate ===
          processFilterState.dateRange.endDate
        ) {
          // If startDate and endDate are the same, only show a single date
          datePart = `${formatDate(processFilterState.dateRange.startDate)}`;
        } else {
          // If startDate and endDate are different, show the range
          datePart = `${formatDate(
            processFilterState.dateRange.startDate
          )} - ${formatDate(processFilterState.dateRange.endDate)}`;
        }
      } else if (processFilterState.dateRange.startDate) {
        // If only startDate is provided
        datePart = formatDate(processFilterState.dateRange.startDate);
      } else {
        // If no dates are provided
        datePart = "";
      }

      const shiftPart =
        processFilterState.shift.startTime && processFilterState.shift.endTime
          ? `${formatTime(processFilterState.shift.startTime)} - ${formatTime(
              processFilterState.shift.endTime
            )}`
          : "";

      // Construct the file name dynamically
      //fileName += ` (${datePart}, ${shiftPart})`;
      fileName += ` (${datePart})`;
    }

    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData,
        "",
        siteConfigDetails.shiftsDetails,
        processFilterState.dateRange.startDate,
        processFilterState.dateRange.endDate,
        processFilterState.shift.startTime,
        processFilterState.shift.endTime,
        siteConfigDetails.isShiftEnabled ?? 0
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };

  const handleExportClick = async (rowData: any) => {
    try {
      console.log(rowData);
      const requestJson = {
        id: rowData.id,
        processLogicId: rowData.processLogicId,
        subProcessLogicId: rowData.subProcessLogicId,
        startDate: processFilterState.dateRange.startDate,
        endDate: processFilterState.dateRange.endDate,
      };
      const response: any = await processServices.ProcessExportAction(
        requestJson
      );
      const link = document.createElement("a");
      link.href = response.data;
      link.setAttribute("download", "exported_file_name.extension");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  };

  //#endregion

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <PageHeader
          title={t("web.toolbar.label.ailogicresults")}
          icon={<ProcessPageHeaderIcon />}
        />
        <div className="w-full flex-1">
          <div className="w-full h-full flex flex-col">
            <GridToolBar
              columnCount={totalCount}
              gridType={t("web.toolbar.label.ailogicresults")}
              selectView={true}
              tools={toolItems}
              handleAddRow={createNewInspection}
              handleDelete={() => setShowDeletePopup(true)}
              handleColumns={() => setShowColumns(true)}
              isGridView={isGridView}
              fetchData={(query: any) => setSearchedQuery(query)}
              getSearchedQuery={(query: any) => setSearchedQuery(query)}
              handleRefresh={handleRefresh}
              handleExcel={handleExcel}
              isExcelLoading={isExcelLoading}
              isSkeletonLoader={isSkeletonLoader}
              resetSearch={resetSearch}
              resetFilter={resetFilter}
              setIsGridView={(isChecked: any) => setIsGridView(isChecked)}
              siteConfigDetails={siteConfigDetails}
              processFilterState={processFilterState}
              setProcessFilterState={setProcessFilterState}
            />
            <div className="w-full flex-1">
              {isGridView ? (
                <ProcessCards
                  handleNavigate={handleNavigate}
                  rowData={processData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  handleOpenMoreInfo={handleOpenMoreInfo}
                  handleExportClick={handleExportClick}
                  noDataFound={noDataFound}
                  siteConfigDetails={siteConfigDetails}
                />
              ) : (
                <ProcessGrid
                  selectedColumns={selectedColumns}
                  rowSelection={false}
                  moreOptions={true}
                  handleNavigate={handleNavigate}
                  rowData={processData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  sorting={sorting}
                  sortingColumnName={sortingColumnName}
                  handleSort={handleSort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleOpenMoreInfo={handleOpenMoreInfo}
                  noDataFound={noDataFound}
                  siteConfigDetails={siteConfigDetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showMoreInfo && (
        <ProcessMoreInfo
          statusDetail={statusDetail}
          selectedItem={selectedItem ? [selectedItem] : []}
          handleClose={() => setShowMoreInfo(false)}
          processUpdated={(val: boolean) => setProcessUpdated(val)}
          handleRefresh={handleRefresh}
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      {pageLoader && <PageLoader />}
      {/* <Outlet /> */}
      {isInspectionModalOpen && (
        <CreateInspectionWrapper
          handleRefresh={handleRefresh}
          setIsInspectionModalOpen={setIsInspectionModalOpen}
          processUpdated={() => setProcessUpdated((pre: any) => !pre)}
        />
      )}
    </>
  );
}
export default Process;
