import { useNavigate, useParams } from "react-router-dom";
import { InputField, SelectField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { masterAccess } from "../../../../services/InspectionOneServices";
import MasterDetails from "../../../../pages/master-details/master-details";
import UploadImage from "../../../form/upload-image";
import {
  MultiSelectInput,
  SelectInput,
  SelectInputWithoutForm,
} from "../../../form/select-input";

function CreateUserMasterForm({
  headerDetails,
  userMasterUpdated,
  dependentData,
}: any) {
  const { id } = useParams();
  const [initialData, setInitialData] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>([]);
  const [dynamicShape, setDynamicShape] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (initialData.length > 0) {
      const titles = initialData.map((item: any) => {
        const keys = Object.keys(item);
        const values = Object.values(item);
        const headerDetailsList = headerDetails;
        const labelValuePairs = keys.map((key, i) => ({
          label: key,
          value: values[i],
          isMandatory:
            headerDetailsList[i - 1] !== undefined
              ? headerDetailsList[i - 1].isMandatory
              : 0,
          type:
            headerDetailsList[i - 1] !== undefined
              ? headerDetailsList[i - 1].type
              : 0,
          headerName:
            headerDetailsList[i - 1] !== undefined
              ? headerDetailsList[i - 1].headerName
              : "",
        }));
        return labelValuePairs;
      });
      setFieldData(titles[0]);
      const shape = titles[0].reduce((acc: any, item: any) => {
        if (item.type === 4 || item.type === 2) {
          item.value = getDependentOptions(
            item.headerName,
            item.value,
            dependentData,
            item.type
          );
        }
        if (item.isMandatory) {
          acc[item.label] =
            item.type === 2
              ? yup.object()
              : item.type === 4
              ? yup.array().required(`${item.label} is required`)
              : yup.string().required(`${item.label} is required`);
        } else {
          acc[item.label] =
            item.type === 2
              ? yup.object()
              : item.type === 4
              ? yup.array()
              : yup.string();
        }
        return acc;
      }, {});
      setDynamicShape(shape);
    }
  }, [initialData]);

  function getDependentOptions(
    label: string,
    value: any,
    dependentData: any,
    type: any
  ) {
    const dependentObject = dependentData.find(
      (item: any) => item.uniqueId === label
    );
    const data = dependentObject ? dependentObject.dependentValues : [];
    const finalData = (data || []).map((value: any) => ({
      id: value.id,
      value: value.value,
      label: value.value,
    }));
    const valueArray =
      typeof value === "string" && value.trim() !== "" ? value.split(",") : [];
    if (type === 2) {
      const matchedObject = finalData.find(
        (item: any) => item.id === parseInt(valueArray[0], 10)
      );
      return matchedObject || {};
    } else if (type === 4) {
      const filteredArray = finalData.filter((item: any) =>
        valueArray.includes(String(item.id))
      );
      return filteredArray.length > 0 ? filteredArray : [];
    }
    return [];
  }

  useEffect(() => {
    if (id !== "new") {
      handleMasterEdit(id);
    } else {
      const initialObject = headerDetails.reduce((acc: any, column: any) => {
        acc[column.field] = "";
        return acc;
      }, {});
      setInitialData([{ id: 0, ...initialObject }]);
    }
  }, [id]);

  const [dependentOptions, setDependentOptions] = useState<
    Record<string, any[]>
  >({});

  useEffect(() => {
    const optionsMap: Record<string, { value: string; label: string }[]> =
      dependentData.reduce(
        (
          acc: Record<string, { value: string; label: string }[]>,
          item: any
        ) => {
          acc[item.uniqueId] = (item.dependentValues || []).map(
            (value: any) => ({
              id: value.id,
              value: value.value,
              label: value.value,
            })
          );
          return acc;
        },
        {}
      );
    setDependentOptions(optionsMap);
  }, [dependentData]);

  const [isImageLoader, setImageLoader] = useState(false);
  const [formData, setFormData] = useState({ imageUrl: "" });

  const createFormSchema = yup.object().shape(dynamicShape);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(createFormSchema),
  });

  const navigate = useNavigate();

  const handleFormSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      data.id = id !== "new" && id ? id : 0;
      const subMode = id !== "new" ? 2 : 1;
      let newData = { ...data };

      newData = Object.keys(newData).reduce((acc: any, key: string) => {
        if (Array.isArray(newData[key])) {
          acc[key] = newData[key].map((item: any) => item.id).join(",");
        } else if (typeof newData[key] === "object" && newData[key] !== null) {
          acc[key] = newData[key].id ? newData[key].id : newData[key];
        } else {
          acc[key] = newData[key];
        }
        return acc;
      }, {});

      createFormSchema.validate(data);
      const requestJson = {
        accessUniqueId: masterId.trim(),
        masterValueId: id,
        actionMode: 1,
        actionSubMode: subMode,
        masterDetails: [newData],
      };
      const response = await masterAccess.MasterDetailsAction(requestJson);
      if (response.success) {
        setIsLoading(false);
        //setInitialData(response.data.masterValueDetails);
        userMasterUpdated(true);
        navigate(-1);
      } else {
        setIsLoading(false);
        navigate(-1);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  const currentUrl: string = window.location.href;
  const parts = currentUrl.split("/");
  const masterId = parts[parts.length - 3];

  const handleMasterEdit = async (uniqueId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        masterAccessId: masterId.trim(),
        masterValueId: uniqueId.trim(),
      };
      const response = await masterAccess.MasterDetailsActionFetch(requestJson);
      if (response.success) {
        setIsLoading(false);
        setInitialData(response.data.masterValueDetails);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const getDependentOptions = (label: string, dependentData: any) => {
  //   debugger
  //   const dependentObject = dependentData.find((item: any) => item.uniqueId === label);
  //   return dependentObject ? dependentObject.dependentValues : [];
  // };

  return (
    <div className="w-full h-full flex flex-col">
      <form
        className="w-full h-full flex flex-col"
        onSubmit={handleSubmit((data) => handleFormSubmit(data))}
      >
        <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
          <div className="w-[93%] h-auto">
            <div className="w-full h-auto flex justify-between items-start pt-4">
              <div className="w-[60%] ">
                <div className="w-full h-auto">
                  <div className="w-full mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <>
                        {fieldData.map((field: any, index: number) => {
                          const isHidden = index === 0;
                          const getInputField = () => {
                            switch (field.type) {
                              case 1:
                                setValue(field.label, field.value);
                                return (
                                  <InputField
                                    labelName={field.headerName}
                                    type="text"
                                    id={field.label}
                                    isRequired={field.isMandatory === 1}
                                    formHandle={register(field.label)}
                                    error={
                                      (errors as any)[field.label]?.message
                                    }
                                  />
                                );
                              case 2:
                                setValue(field.label, field.value);
                                return (
                                  <SelectInput
                                    labelName={field.headerName}
                                    error={""}
                                    idName={field.label}
                                    name={field.label}
                                    control={control}
                                    isRequired={true}
                                    options={
                                      dependentOptions[field.headerName] || []
                                    }
                                  />
                                );
                              case 3:
                                return (
                                  <InputField
                                    labelName={field.headerName}
                                    type="datetime-local"
                                    id={field.label}
                                    isRequired={field.isMandatory === 1}
                                    formHandle={register(field.label)}
                                    error={
                                      (errors as any)[field.label]?.message
                                    }
                                  />
                                );
                              case 4:
                                setValue(field.label, field.value || []);
                                return (
                                  <MultiSelectInput
                                    labelName={field.headerName}
                                    idName={field.label}
                                    isMulti={true}
                                    name={field.label}
                                    control={control}
                                    error={""}
                                    isSearchable={false}
                                    options={
                                      dependentOptions[field.headerName] || []
                                    }
                                    getOptionLabel={(option: any) =>
                                      option.value
                                    }
                                    getOptionValue={(option: any) => option.id}
                                    isRequired={true}
                                  />
                                );
                              case 5:
                                // Ensure formData is populated with the initial image if editing
                                if (!formData.imageUrl && field.value) {
                                  setFormData((prev: any) => ({
                                    ...prev,
                                    imageUrl: field.value,
                                  }));
                                }
                                setValue(field.label, formData.imageUrl); // Update the form value with the current image
                                return (
                                  <UploadImage
                                    formData={formData}
                                    setFormData={setFormData}
                                    isImageLoader={isImageLoader}
                                    setImageLoader={setImageLoader}
                                  />
                                );
                              default:
                                return null;
                            }
                          };

                          return (
                            <div
                              key={field.label}
                              style={{
                                marginBottom: "20px",
                                display: isHidden ? "none" : "block",
                              }}
                            >
                              {getInputField()}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalPageFooter
          footerType="form"
          handleCancel={() => handleFormCancel()}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
export default CreateUserMasterForm;
