import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../../global-layout/page-header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { questionnaireOptions } from "../../../../services/InspectionOneServices";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IconBtn from "../../../form/icon-btn";
import {
  AccordionDropDownIcon,
  AddNewScreenIcon,
  DeleteIcon,
  DragSixDotGrayIcon,
  EditIcon,
  MakeAnCopyIcon,
  PreviewCloseIcon,
  PreviewExpandIcon,
  TickIcon,
} from "../../../svg";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import IconTextBtn from "../../../form/icon-text-btn";
import { ToggleButton } from "../../../form/toggle-btn";
import { useTranslation } from "react-i18next";
import {
  ResponseAcknowledgement,
  ResponseAudio,
  ResponseDateTime,
  ResponseGridsName,
  ResponseImage,
  ResponseImageWithInputs,
  ResponseInformation,
  ResponseLocation,
  ResponseMagicContainer,
  ResponseMultipleChoice,
  ResponseNumber,
  ResponsePersonName,
  ResponseScanner,
  ResponseSignature,
  ResponseSiteName,
  ResponseTenantName,
  ResponseText,
  ResponseToggle,
  ResponseVideo,
} from "../question-type-renderer";
import ResponseSettingsNew from "../response-settings-new";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import ProcessPreviewNew from "../process-preview-new";
import ProcessPreviewModalNew from "../process-preview-modal-new";
import Authentication from "../../../../auth/Authentication";
import { typeOfResponse } from "../../../../services/questionConfig";
import { questionAction } from "../../../../services/authFunctions";
import ImageWithInput from "./indexImageWithInput";
import ConfigurationForm from "./indexConfiguration";
import GridComponent from "./indexGrid";

interface Item {
  id: string;
  order: number;
  label: string;
  icon: ReactNode;
}
interface AccordionsProps {
  process: any;
  processData: any;
  setProcessData: any;
  toggleAccordionHandle: (id: any) => void;
  open: boolean;
  onDragEnd: (data: any) => void;
  setEditingScreen: (data: any) => void;
  handleBlur: () => void;
  editingScreen: any;
  handleToggleQuestionOption: (item: any) => void;
  currentQuestion: any;
  t: any;
  selectedResVal: any;
  tippyRef: any;
  visible: boolean;
  show: () => void;
  hide: () => void;
  openResponseSettings: (item: any) => void;
  register: any;
  errors: any;
  watchedFields: any;
  handleChangeTypeOfResponse: (data: any) => void;
  handleDeleteQuestion: (data: any, index: number) => void;
  handleMakeAnCopy: (data: any, index: number) => void;
  handleAddNewQuestion: () => void;
  setSelectedScreen: (data: any) => void;
  selectedScreen: any;
  currentProcess: string;
  selectedResponseItem: any;
}

interface OptionItem {
  id: any;
  value: any;
  label: any;
}

const questionSchema = yup.object().shape({
  questionName: yup.string().required("Please Enter the name"),
  isMandatory: yup.bool(),
  isEvidence: yup.bool(),
  isNotes: yup.bool(),
  isAutoCapture: yup.bool(),
  autoCaptureType: yup.string(),
  autoCaptureConfiguration: yup.string(),
  flashlightMode: yup.bool(),
  isFlashlightAccessDisabled: yup.bool(),
  isOrientationLockerDisabled: yup.bool(),
  orientation: yup.bool(),
  isPreCheck: yup.bool(),
  photoResolution: yup.bool(),
  videoResolution: yup.bool(),
  videoLength: yup.bool(),
  resolutionType: yup.bool(),
  viewPort: yup.bool(),
  sourceEndpoint: yup.bool(),
  resolutionWidth: yup.bool(),
  resolutionHeight: yup.bool(),
  isWebViewInject: yup.bool(),
  webViewInjectScript: yup.bool(),
});

function ProcessScreensNew({ getToggleIsChecked, currentTheme }: any) {
  const { logicId, id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [genericDetails, setGenericDetails] = useState<any>({
    name: "",
    uniqueId: "",
  });
  const tippyRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const containerRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [processData, setProcessData] = useState<any>([]);
  const [selectedScreen, setSelectedScreen] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [currentProcess, setCurrentProcess] = useState("screencollection");
  const [currentAccordion, setCurrentAccordion] = useState<any>(null);
  const [disableReponseType, setDisableReponseType] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [containerMaster, setContainerMaster] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [showResponseSettings, setShowResponseSettings] = useState(false);
  const [responseSettingsType, setResponseSettingsType] = useState<any>({});
  const [responseValueOptions, setResponseValueOptions] = useState<any>([]);
  const [selectedChoice, setSelectedChoice] = useState<any>(
    responseValueOptions[0]
  );
  const [multipleOptionValidation, setMultipleOptionValidation] =
    useState(false);
  const [selectedResponseItem, setSelectedResponseItem] = useState<any>(
    responseValueOptions[0]
  );
  const [responseMagicContainerOptions, setResponseMagicContainerOptions] =
    useState<OptionItem[]>([]);
  const [bindingoptions, setBindingoptions] = useState<any>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editingScreen, setEditingScreen] = useState<{
    uniqueId: number;
    name: string;
  } | null>(null);
  const [showPreviewProcessModal, setShowPreviewProcessModal] = useState(false);
  // const [editingQuestionName, setEditingScreen] = useState<{ uniqueId: number; name: string } | null>(null);

  const [selectedResVal, setSelectedResVal] = useState<any>({
    groupId: "102",
    isChange: 1,
    options: [
      {
        uniqueId: "116e5250-91fc-4d0a-80cc-a67f54eed321",
        value: "Yes",
        color: "green",
        isDeleted: 0,
      },
      {
        uniqueId: "27616b66-e61d-4b6e-8227-e3826121d7c4",
        value: "No",
        color: "red",
        isDeleted: 0,
      },
    ],
  });

  const generateGuid = () => {
    return uuidv4();
  };

  const {
    register,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(questionSchema),
  });

  const watchedFields = {
    questionName: watch("questionName"),
    isMandatory: watch("isMandatory"),
    isEvidence: watch("isEvidence"),
    isNotes: watch("isNotes"),
    isAutoCapture: watch("isAutoCapture"),
    autoCaptureType: watch("autoCaptureType"),
    autoCaptureConfiguration: watch("autoCaptureConfiguration"),
    flashlightMode: watch("flashlightMode"),
    isFlashlightAccessDisabled: watch("isFlashlightAccessDisabled"),
    isOrientationLockerDisabled: watch("isOrientationLockerDisabled"),
    orientation: watch("orientation"),
    isPreCheck: watch("isPreCheck"),
    photoResolution: watch("photoResolution"),
    videoResolution: watch("videoResolution"),
    videoLength: watch("videoLength"),
    resolutionType: watch("resolutionType"),
    viewPort: watch("viewPort"),
    sourceEndpoint: watch("sourceEndpoint"),
    resolutionWidth: watch("resolutionWidth"),
    resolutionHeight: watch("resolutionHeight"),
    isWebViewInject: watch("isWebViewInject"),
    webViewInjectScript: watch("webViewInjectScript"),
  };

  const breadcrumbs = [
    { label: "AI Logic Studio", link: "/ai-logic-studio" },
    {
      label: "AI Sub Logic Studio",
      link: `/ai-logic-studio/process-logic/${logicId}`,
    },
    { label: "Automatic Inspection", link: undefined },
  ];

  useEffect(() => {
    if (containerMaster.length > 0) {
      const options = containerMaster.map(
        (item: any): OptionItem => ({
          id: item.uniqueId,
          value: item.uniqueId,
          label: item.name,
        })
      );
      setResponseMagicContainerOptions(options);
    }
  }, [containerMaster]);

  const fetchQuestionProcessData = async () => {
    setIsLoading(true);
    setIsSkeletonLoading(true);
    try {
      const requestJson = {
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        mode: pathname.includes("/survey/") ? "2" : "1",
      };
      const response: any = await questionnaireOptions.QuestionnaireDetails(
        requestJson
      );
      const responseData = response.data.screenDetails;
      if (response.success) {
        setGenericDetails(response.data.genericDetails);
        if (responseData.length > 0) {
          setProcessData(responseData || []);
          setSelectedScreen(responseData[0]);
          setCurrentAccordion(
            responseData[0].uniqueId ? responseData[0].uniqueId : null
          );
          watchedFields["questionName"] = responseData[0].title;
        }
        setIsLoading(false);
        setIsSkeletonLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchProcessData = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.QuestionnaireOptions(
        requestJson
      );
      const responseData = response.data.questionOption;
      if (response.success) {
        setResponseValueOptions(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBindingDetails = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.BindingDetails(
        requestJson
      );
      const responseData = response.data;
      if (response.success) {
        setBindingoptions(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchContainerMaster = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.ContainerMasterFetch(
        requestJson
      );
      const responseData = response.data.magicContainerLists;
      if (response.success) {
        setContainerMaster(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchQuestionProcessData();
    fetchProcessData();
    fetchBindingDetails();
    fetchContainerMaster();
  }, [refresh]);

  useEffect(() => {
    const randomString = generateGuid();
    if (pathname.includes("/survey/")) {
      setCurrentProcess("screencollection");
      setProcessData([
        {
          screenId: 0,
          uniqueId: randomString,
          flowId: randomString,
          processLogicUniqueId: logicId,
          getStartedSurveyUniqueId: id,
          name: "Screen 1",
          description: "",
          mode: 2,
          isDeleted: 0,
          orderNo: 1,
          questionDetails: [],
        },
      ]);
      setSelectedScreen({
        screenId: 0,
        uniqueId: randomString,
        flowId: randomString,
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        name: "Screen 1",
        description: "",
        mode: 2,
        isDeleted: 0,
        orderNo: 1,
        questionDetails: [],
      });
    } else if (pathname.includes("/get-started/")) {
      //   setIsAddBtnHide(true);
      setCurrentProcess("get-started");
      setProcessData([
        {
          screenId: 0,
          uniqueId: randomString,
          flowId: "startupscreen",
          processLogicUniqueId: logicId,
          getStartedSurveyUniqueId: id,
          name: "Startup screen",
          description: "",
          mode: 1,
          isDeleted: 0,
          questionDetails: [],
        },
      ]);
      setSelectedScreen({
        screenId: 0,
        uniqueId: randomString,
        flowId: "startupscreen",
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        name: "Startup screen",
        description: "",
        mode: 1,
        isDeleted: 0,
        questionDetails: [],
      });
    }
  }, [pathname]);
  const authService = Authentication();
  var userDetails = authService.decryptData("UserDetails", 2);
  type FormFields =
    | "questionName"
    | "isMandatory"
    | "isEvidence"
    | "isNotes"
    | "isAutoCapture"
    | "autoCaptureType"
    | "autoCaptureConfiguration"
    | "flashlightMode"
    | "isFlashlightAccessDisabled"
    | "isOrientationLockerDisabled"
    | "orientation"
    | "isPreCheck"
    | "photoResolution"
    | "videoResolution"
    | "videoLength"
    | "resolutionType"
    | "viewPort"
    | "sourceEndpoint"
    | "resolutionWidth"
    | "resolutionHeight"
    | "isWebViewInject"
    | "webViewInjectScript";

  useEffect(() => {
    if (currentQuestion?.uniqueId) {
      // Set the question fields first
      const questionFields: Record<
        "questionName" | "isMandatory" | "isEvidence" | "isNotes",
        any
      > = {
        questionName: currentQuestion.title,
        isMandatory: currentQuestion.isMandatory,
        isEvidence: currentQuestion.isEvidence,
        isNotes: currentQuestion.isNotes,
      };

      Object.entries(questionFields).forEach(([key, value]) => {
        setValue(key as FormFields, value);
      });

      // Set the camera fields next
      const cameraFields: Record<
        | "isAutoCapture"
        | "autoCaptureType"
        | "autoCaptureConfiguration"
        | "flashlightMode"
        | "isFlashlightAccessDisabled"
        | "isOrientationLockerDisabled"
        | "orientation"
        | "isPreCheck"
        | "photoResolution"
        | "videoResolution"
        | "videoLength"
        | "resolutionType"
        | "viewPort"
        | "sourceEndpoint"
        | "resolutionWidth"
        | "resolutionHeight"
        | "isWebViewInject"
        | "webViewInjectScript",
        any
      > = {
        isAutoCapture: currentQuestion?.cameraDetails?.isAutoCapture,
        autoCaptureType: currentQuestion?.cameraDetails?.autoCaptureType,
        autoCaptureConfiguration:
          currentQuestion?.cameraDetails?.autoCaptureConfiguration,
        flashlightMode: currentQuestion?.cameraDetails?.flashlightMode,
        isFlashlightAccessDisabled:
          currentQuestion?.cameraDetails?.isFlashlightAccessDisabled,
        isOrientationLockerDisabled:
          currentQuestion?.cameraDetails?.isOrientationLockerDisabled,
        orientation: currentQuestion?.cameraDetails?.orientation,
        isPreCheck: currentQuestion?.cameraDetails?.isPreCheck,
        photoResolution: currentQuestion?.cameraDetails?.photoResolution,
        videoResolution: currentQuestion?.cameraDetails?.videoResolution,
        videoLength: currentQuestion?.cameraDetails?.videoLength,
        resolutionType: currentQuestion?.cameraDetails?.resolutionType,
        viewPort: currentQuestion?.cameraDetails?.viewPort,
        sourceEndpoint: currentQuestion?.cameraDetails?.sourceEndpoint,
        resolutionWidth: currentQuestion?.cameraDetails?.resolutionWidth,
        resolutionHeight: currentQuestion?.cameraDetails?.resolutionHeight,
        isWebViewInject: currentQuestion?.cameraDetails?.isWebViewInject,
        webViewInjectScript:
          currentQuestion?.cameraDetails?.webViewInjectScript,
      };

      Object.entries(cameraFields).forEach(([key, value]) => {
        setValue(key as FormFields, value);
      });
    }
  }, [currentQuestion]);

  const requestDetails = (screenDetails: any) => ({
    screenDetails,
  });
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const requestJson = requestDetails(processData);
      const response: any = await questionAction(requestJson);
      if (response.success) {
        setIsLoading(false);
        setSubmitLoading(false);
      } else {
        setIsLoading(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSubmitLoading(false);
    }
  };
  const toggleAccordionHandle = (uniqueId: any) => {
    setCurrentAccordion(currentAccordion !== uniqueId ? uniqueId : null);
  };

  const reorder = (
    list: Item[],
    startIndex: number,
    endIndex: number
  ): Item[] => {
    const newList = Array.from(list);
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);
    return newList;
  };

  const copy = (
    source: any,
    destination: Item[],
    droppableSource: any,
    droppableDestination: any
  ): Item[] => {
    const item = source[droppableSource.index - 1];
    const randomString = generateGuid();
    const newItem = {
      ...item,
      id: randomString,
      uniqueId: randomString,
      questionDetailId: generateGuid(),
      questionId: 0,
      questionType: item.id,
      questionTypeId: item.id,
      title: "Question",
      inputType: item.id,
      subType: item.subType,
      optionGroupId: 0,
      isGroupMasterAvailable: 0,
      //   inputOptions: [],
      //   convertCase: "0",
      isMandatory: false,
      isEvidence: false,
      isNotes: false,
      isDeleted: 0,
      isApiRequired: 0,
      isBindparameter: 0,
      isMasterTable: 0,
      serviceAccessKey: "",
      masterAccessKey: "",
      magicContainerId: null,
      serviceBindValues: [],
      masterBindValues: [],
      orderNo: 1,
      type: item.type,
    };
    const newDestination = Array.from(destination);
    newDestination.splice(droppableDestination.index, 0, newItem);
    return newDestination;
  };

  const makeAnCopy = (
    destination: Item[],
    item: any,
    droppableDestination: any
  ): Item[] => {
    const randomString = generateGuid();
    const newItem = {
      ...item,
      id: randomString,
      uniqueId: randomString,
      questionDetailId: generateGuid(),
      questionId: 0,
      questionType: item.id,
      questionTypeId: item.inputType,
      title: item.title,
      inputType: item.inputType,
      subType: item.subType,
      inputOptions: [],
      convertCase: "0",
      isBindparameter: 0,
      orderNo: 1,
      type: item.type,
    };
    const newDestination = Array.from(destination);
    newDestination.splice(droppableDestination + 1, 0, newItem);
    return newDestination;
  };

  const addOrderNoToQuestionDetails = (screenData: any) => {
    const updatedQuestionDetails = screenData.questionDetails.map(
      (question: any, index: number) => {
        return { ...question, orderNo: index + 1 };
      }
    );
    return { ...screenData, questionDetails: updatedQuestionDetails };
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;
      if (!destination) {
        setDisableReponseType(false);
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          const itemIndex = processData.findIndex((item: any) => {
            return item.uniqueId === selectedScreen.uniqueId;
          });
          if (itemIndex !== -1) {
            const updatedProcessData = [...processData];

            const copiedVal = reorder(
              updatedProcessData[itemIndex].questionDetails,
              source.index,
              destination.index
            );
            updatedProcessData[itemIndex].questionDetails = copiedVal;
            const updatedScreenData = addOrderNoToQuestionDetails(
              updatedProcessData[itemIndex]
            );
            updatedProcessData[itemIndex] = updatedScreenData;

            setProcessData(updatedProcessData);
          }
          setDisableReponseType(false);
          break;
        default:
          setDisableReponseType(false);
          break;
      }
    },
    [selectedScreen]
  );

  useEffect(() => {
    if (processData.length > 0) {
      const screenData = processData.find((data: any, i: any) => {
        return data.uniqueId === selectedScreen?.uniqueId;
      });
      if (screenData && pathname.includes("/get-started/")) {
        screenData.flowId = "getstarted";
      }
      setSelectedScreen(screenData);
    }
  }, [processData]);

  const handleAddNewScreen = () => {
    const newScreen = {
      screenId: processData.length + 1,
      uniqueId: generateGuid(),
      processLogicUniqueId: logicId,
      getStartedSurveyUniqueId: id,
      name: `Screen ${processData.length + 1}`,
      description: "description",
      mode: pathname.includes("/survey/") ? "2" : "1",
      orderNo: processData.length + 1,
      isDeleted: 0,
      questionDetails: [],
    };
    setProcessData((prevProcessData: any) => [...prevProcessData, newScreen]);
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleAddNewQuestion = () => {
    const index = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen.uniqueId;
    });
    if (index !== -1) {
      const updatedProcessData = [...processData];

      const copiedVal = copy(
        typeOfResponse,
        updatedProcessData[index].questionDetails,
        { index: 1 },
        { index: updatedProcessData[index].questionDetails.length + 1 }
      );
      updatedProcessData[index].questionDetails = copiedVal;
      const updatedScreenData = addOrderNoToQuestionDetails(
        updatedProcessData[index]
      );
      updatedProcessData[index] = updatedScreenData;

      setProcessData(updatedProcessData);
    }
  };

  const handleMakeAnCopy = (item: Item, index: number) => {
    const itemIndex = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen.uniqueId;
    });
    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const copiedVal = makeAnCopy(
        updatedProcessData[itemIndex].questionDetails,
        item,
        index
      );
      updatedProcessData[itemIndex].questionDetails = copiedVal;
      const updatedScreenData = addOrderNoToQuestionDetails(
        updatedProcessData[itemIndex]
      );
      updatedProcessData[itemIndex] = updatedScreenData;
      setProcessData(updatedProcessData);
    }
  };

  const handleDeleteScreen = (uniqueId: any) => {
    const updatedProcessData = processData.filter(
      (screen: any) => screen.uniqueId !== uniqueId
    );
    setProcessData(updatedProcessData);
  };
  const handleMakeAnCopyScreen = (process: any, index: number) => {
    const questionToCopy = processData[index];
    const updatedQuestionDetails = questionToCopy.questionDetails.map(
      (detail: any) => {
        const randomString = generateGuid();
        return {
          ...detail,
          id: randomString,
          uniqueId: randomString,
          questionDetailId: generateGuid(),
        };
      }
    );

    const newScreen = {
      ...questionToCopy,
      screenId: processData.length + 1,
      uniqueId: generateGuid(),
      name: `Screen ${processData.length + 1}`,
      questionDetails: updatedQuestionDetails,
    };
    const newScreens = [
      ...processData.slice(0, index + 1),
      newScreen,
      ...processData.slice(index + 1),
    ];
    const updatedNewScreens = newScreens.map((q, i) => ({
      ...q,
      orderNo: i + 1,
    }));
    setProcessData(updatedNewScreens);
  };

  const handleScreenNameEdit = (item: any) => {
    const updatedProcessData = processData.map((screen: any) => {
      if (screen.uniqueId === item?.uniqueId) {
        return { ...screen, name: item?.name };
      }
      return screen;
    });

    setProcessData(updatedProcessData);
  };
  const handleBlur = () => {
    if (editingScreen) {
      handleScreenNameEdit(editingScreen);
      setEditingScreen(null);
    }
  };
  const handleToggleQuestionOption = (item: any) => {
    setCurrentQuestion(currentQuestion?.id !== item.id ? item : null);
    setResponseSettingsType(item);
    if (item.optionGroupId !== 0) {
      const selectedGroup = responseValueOptions.find(
        (group: any) => group.groupId === item.optionGroupId.toString()
      );

      if (selectedGroup) {
        selectedResponseValue(selectedGroup);
      } else {
        selectedResponseValue([]);
      }
    } else {
      selectedResponseValue([]);
    }
  };
  const selectedResponseValue = (selectedItem: any) => {
    setSelectedChoice(selectedItem);
    setSelectedResponseItem(selectedItem);
    setMultipleOptionValidation(false);
  };

  useEffect(() => {}, [processData]);

  const openResponseSettings = (item: any) => {
    setShowResponseSettings(true);
    setMultipleOptionValidation(true);
    setResponseSettingsType(item);
    debugger;
    const selectedGroup =
    item.optionGroupId !== null &&
    item.optionGroupId !== undefined &&
    item.optionGroupId !== 0
      ? responseValueOptions.find(
          (group: any) => group.groupId === item.optionGroupId.toString()
        )
      : null;
    selectedResponseValue(selectedGroup || []);
  };

  const handleCloseResponseSettings = () => {
    setShowResponseSettings(false);
    setResponseSettingsType({});
  };

  const handleImport = (file: File) => {
    // setShowImportResponse(true);
    // setFileName(file?.name);
  };

  const updateResponseSettings = (data: any, type: any) => {
    const itemIndex = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen.uniqueId;
    });

    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const updatedItem = { ...processData[itemIndex] };
      const screenDetailIndex = updatedItem.questionDetails.findIndex(
        (item: any) => {
          return item.id === type.id;
        }
      );
      const updatedScreenDetail = {
        ...updatedItem.questionDetails[screenDetailIndex],
      };
      // updatedScreenDetail.title = data.questionName;
      // updatedScreenDetail.isEvidence = data.isEvidence;
      // updatedScreenDetail.isMandatory = data.isMandatory;
      // updatedScreenDetail.isNotes = data.notes;
      if (data?.responseStyle) {
        updatedScreenDetail.inputType === 24
          ? (updatedScreenDetail.magicContainerId = data.responseStyle.id)
          : (updatedScreenDetail.subType = data.responseStyle.id);
      }

      updatedScreenDetail.isApiRequired = data?.isApiRequiredKey ? 1 : 0;

      if (data?.apiRequired) {
        if (updatedScreenDetail.isApiRequired === 0) {
          updatedScreenDetail.serviceAccessKey = "";
          updatedScreenDetail.serviceBindValues = [];
        } else {
          updatedScreenDetail.serviceAccessKey = data.apiRequired.id;
          const transformed = data.serviceBindParameter.map(
            (item: any, index: any) => ({
              uniqueId: generateGuid(),
              questionId: item.serviceBindParameterTwo.id,
              key: item.serviceBindParameterOne.id,
              isDeleted: 0,
            })
          );
          updatedScreenDetail.serviceBindValues = transformed;
        }
      }
      updatedScreenDetail.isMasterTable = data?.isMasterRequiredKey ? 1 : 0;
      if (data?.masterTableOne) {
        if (updatedScreenDetail.isMasterTable === 0) {
          updatedScreenDetail.masterAccessKey = "";
          updatedScreenDetail.masterBindValues = [];
        } else {
          updatedScreenDetail.masterAccessKey = data.masterTableOne.id;
          const transformed = data.masterBindParameter.map(
            (item: any, index: any) => ({
              uniqueId: generateGuid(),
              questionId: item.masterBindParameterTwo.id,
              key: item.masterBindParameterOne.id,
              isDeleted: 0,
            })
          );
          updatedScreenDetail.masterBindValues = transformed;
        }
      }

      if (
        data?.responseStyle?.value === "radioButton" ||
        data?.responseStyle?.value === "multipleChoice" ||
        data?.responseStyle?.value === "dropDown" ||
        data?.responseStyle?.value === "dropdownMultiSelect"
      ) {
        if (data?.selectedMultiOptionChoice === undefined) {
          setMultipleOptionValidation(true);
          return;
        } else {
          setMultipleOptionValidation(false);
        }
      }

      updatedScreenDetail.optionGroupId = data?.selectedMultiOptionChoice
        ? data?.selectedMultiOptionChoice
        : 0;
      updatedScreenDetail.isGroupMasterAvailable =
        data?.selectedMultiOptionChoice ? 1 : 0;

      updatedItem.questionDetails[screenDetailIndex] = updatedScreenDetail;
      updatedProcessData[itemIndex] = updatedItem;
      setProcessData(updatedProcessData);
      setShowResponseSettings(false);
    }
  };
  const handleSaveResponse = (data: any) => {
    // updateSubProcess();
  };

  const passUpdatedResponseval = (data: any) => {
    setResponseValueOptions(data);
  };
  const handleQuestionDetEdit = (
    question: any,
    field: string,
    updatedValue: any
  ) => {
    const itemIndex = processData.findIndex(
      (item: any) => item.uniqueId === selectedScreen?.uniqueId
    );

    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const updatedItem = { ...processData[itemIndex] };
      const screenDetailIndex = updatedItem.questionDetails.findIndex(
        (item: any) => item?.uniqueId === question?.uniqueId
      );

      if (screenDetailIndex === -1) return;

      const updatedScreenDetail = {
        ...updatedItem.questionDetails[screenDetailIndex],
      };
      // Check if the field is related to cameraDetails
      if (userDetails.roleId == "10") {
        updatedScreenDetail.cameraDetails =
          updatedScreenDetail.cameraDetails || {};
        updatedScreenDetail.cameraDetails[field] = updatedValue;
      } else {
        if (field === "questionName") {
          field = "title";
        }
        updatedScreenDetail[field] = updatedValue;
      }
      if (field === "typeOfResponse") {
        updatedScreenDetail.questionTypeId = updatedValue?.id;
        updatedScreenDetail.inputType = updatedValue?.id;
        updatedScreenDetail.type = updatedValue?.type;
      }
      updatedItem.questionDetails[screenDetailIndex] = updatedScreenDetail;
      updatedProcessData[itemIndex] = updatedItem;
      setProcessData(updatedProcessData);
    }
  };

  useEffect(() => {
    Object.entries(watchedFields).forEach(([field, value]) => {
      handleQuestionDetEdit(currentQuestion, field, value);
    });
  }, [...Object.values(watchedFields)]);

  const handleChangeTypeOfResponse = (response: any) => {
    handleQuestionDetEdit(currentQuestion, "typeOfResponse", response);
  };

  const handleDeleteQuestion = (item: any, index: number) => {
    const screenIndex = processData.findIndex((itemIndex: any) => {
      return itemIndex.uniqueId === selectedScreen.uniqueId;
    });
    if (screenIndex !== -1) {
      const updatedScreen = [...processData];
      updatedScreen[screenIndex].questionDetails.splice(index, 1);
      const updatedScreenData = addOrderNoToQuestionDetails(
        updatedScreen[screenIndex]
      );
      updatedScreen[screenIndex] = updatedScreenData;
      setProcessData(updatedScreen);
    }
  };

  return (
    <>
      <PageHeader
        title={`${genericDetails?.name}`}
        breadcrumbs={breadcrumbs}
        hasSaveBtn={true}
        hasEditBtn={false}
        handleEdit={() => navigate("edit")}
        handleSaveResponse={handleSubmit}
        isLoading={submitLoading}
        isSkeletonLoading={isSkeletonLoading}
        hasToggleButton={true}
        getToggleIsChecked={getToggleIsChecked}
        currentTheme={currentTheme}
      />
      <div
        ref={containerRef}
        className="w-full flex-auto basis-0 overflow-y-auto relative"
      >
        <div className="w-full  ">
          <div className="w-full h-auto bg-[#F5F8FF] dark:bg-[#1A1C28] pl-[4.5rem] py-[4.5rem] ">
            <div className="w-full h-auto flex justify-between items-start gap-[3rem]">
              <div className="flex-1">
                {!isSkeletonLoading ? (
                  processData.map((process: any, index: number) => (
                    <div
                      key={index}
                      className="w-full flex justify-between items-start gap-[1.5rem]"
                    >
                      <div
                        className={`w-full h-auto bg-io-white dark:bg-io-black-23 rounded-[1.4rem] mb-[2.2rem] shadow-sm ${
                          currentAccordion === process?.uniqueId &&
                          "border border-io-primary"
                        }`}
                      >
                        <Accordions
                          process={process}
                          processData={processData}
                          setProcessData={setProcessData}
                          toggleAccordionHandle={toggleAccordionHandle}
                          open={currentAccordion === process?.uniqueId}
                          onDragEnd={onDragEnd}
                          editingScreen={editingScreen}
                          setEditingScreen={setEditingScreen}
                          handleBlur={handleBlur}
                          handleToggleQuestionOption={
                            handleToggleQuestionOption
                          }
                          currentQuestion={currentQuestion}
                          t={t}
                          selectedResVal={selectedResVal}
                          tippyRef={tippyRef}
                          visible={visible}
                          show={show}
                          hide={hide}
                          openResponseSettings={openResponseSettings}
                          register={register}
                          errors={errors}
                          watchedFields={watchedFields}
                          handleChangeTypeOfResponse={
                            handleChangeTypeOfResponse
                          }
                          handleDeleteQuestion={handleDeleteQuestion}
                          handleAddNewQuestion={handleAddNewQuestion}
                          handleMakeAnCopy={handleMakeAnCopy}
                          setSelectedScreen={setSelectedScreen}
                          selectedScreen={selectedScreen}
                          currentProcess={currentProcess}
                          selectedResponseItem={selectedResponseItem}
                        />
                      </div>
                      {processData[0]?.flowId !== "getstarted" && (
                        <div className="w-[4.5rem] h-auto sticky top-[4.5rem] right-0">
                          {currentAccordion === process?.uniqueId && (
                            <div className="w-full h-auto bg-io-white dark:bg-io-black-23 py-[1.4rem] rounded-[0.5rem] border border-io-gray-f7 dark:border-io-gray-32 shadow-sm">
                              <div className="w-full flex flex-col justify-center items-center gap-[1.4rem]">
                                <div className="w-auto mx-auto">
                                  <IconBtn
                                    icon={<DeleteIcon />}
                                    size="w-[2.1rem]"
                                    handleClick={() =>
                                      handleDeleteScreen(process?.uniqueId)
                                    }
                                  />
                                </div>
                                <div className="w-auto mx-auto">
                                  <IconBtn
                                    icon={<AddNewScreenIcon />}
                                    title="Add New Screen"
                                    size="w-[2.1rem]"
                                    handleClick={() => handleAddNewScreen()}
                                  />
                                </div>
                                <div className="w-auto mx-auto">
                                  <IconBtn
                                    icon={<MakeAnCopyIcon />}
                                    size="w-[2.1rem]"
                                    handleClick={() =>
                                      handleMakeAnCopyScreen(process, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    {Array.from({ length: 20 }).map((row: any, i: number) => (
                      <div key={i} className="w-full h-[8rem] mb-[2.2rem]">
                        <Skeleton
                          width="100%"
                          height="100%"
                          borderRadius={14}
                        />
                      </div>
                    ))}
                  </>
                )}

                {processData[0]?.flowId !== "getstarted" && (
                  <div className="w-full h-auto bg-io-white dark:bg-io-black-23 sticky bottom-0 left-0 rounded-[0.5rem]">
                    <div className="w-full h-auto flex justify-center items-center py-[1rem] after:content-[''] after:w-[95%] after:h-[1px] after:bg-[#D5EAF7] dark:after:bg-[#4d5062] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:z-0">
                      <div className="w-auto z-[1] bg-io-white dark:bg-io-black-23 px-[2rem]">
                        <IconTextBtn
                          icon={<AddNewScreenIcon />}
                          label="Add New Screen"
                          btnSize="large"
                          style="text-io-primary"
                          handleClick={() => handleAddNewScreen()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="w-auto sticky top-[4.5rem] right-0 scroll-m-36">
                <div
                  className={`w-full h-auto bg-[#33384A] dark:bg-[#33384a] pr-[2rem] pl-[3rem] text-[1.8rem] font-medium font-inter text-io-white py-[1.2rem] leading-none ${
                    showPreview ? "rounded-tl-[1.4rem]" : "rounded-s-full"
                  }`}
                  onClick={() => setShowPreview(true)}
                >
                  <div className="w-full flex justify-between items-center">
                    <p className="cursor-pointer">Preview</p>
                    {showPreview && (
                      <div className="w-auto flex justify-end items-center gap-[2.2rem]">
                        <IconBtn
                          icon={<PreviewExpandIcon />}
                          size="w-[1.6rem]"
                          handleClick={(e) => {
                            e.stopPropagation();
                            setShowPreviewProcessModal(true);
                          }}
                        />
                        <IconBtn
                          icon={<PreviewCloseIcon />}
                          size="w-[1.6rem]"
                          handleClick={(e) => {
                            e.stopPropagation();
                            setShowPreview(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                 {showPreview && (
                  <div className="w-full">
                    <div className="w-[51rem] h-auto bg-io-white dark:bg-io-black-23 pt-[5%] pb-[5%]">
                      <ProcessPreviewNew
                        processData={processData}
                        questionDetails={processData[0]?.questionDetails}
                        selectedScreen={selectedScreen}
                        handleExpandPreview={() => setShowPreview(true)}
                        handleHidePreview={() => setShowPreview(false)}
                        pageTitle={genericDetails?.name}
                        selectedResponseItem={selectedResponseItem}
                        responseValueOptions={responseValueOptions}
                      />
                    </div>
                  </div>
                )}  
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showResponseSettings && (
        <ResponseSettingsNew
          selectedScreen={selectedScreen}
          handleClose={handleCloseResponseSettings}
          responseSettingsType={responseSettingsType}
          handleImport={handleImport}
          handleReponseSettingsSubmit={updateResponseSettings}
          selectedResponseValue={selectedResponseValue}
          responseValueOptions={responseValueOptions}
          selectedResponseItem={selectedResponseItem}
          selectedChoice={selectedChoice}
          responseMagicContainerOptions={responseMagicContainerOptions}
          handleSaveResponse={(data: any) => handleSaveResponse(data)}
          passUpdatedResponseval={passUpdatedResponseval}
          bindingoptions={bindingoptions}
          watchedFields={watchedFields}
          multipleOptionValidation={multipleOptionValidation}
        />
      )}
      {showPreviewProcessModal && (
        <ProcessPreviewModalNew
          processData={processData}
          questionDetails={processData[0]?.questionDetails}
          selectedScreen={selectedScreen}
          selectedResponseItem={selectedResponseItem}
          responseValueOptions={responseValueOptions}
          pageTitle={genericDetails?.name}
          handleCancel={() => setShowPreviewProcessModal(false)}
        />
      )}
    </>
  );
}

const Accordions = ({
  process,
  processData,
  setProcessData,
  toggleAccordionHandle,
  open,
  onDragEnd,
  setEditingScreen,
  editingScreen,
  handleBlur,
  handleToggleQuestionOption,
  currentQuestion,
  t,
  selectedResVal,
  tippyRef,
  visible,
  show,
  hide,
  openResponseSettings,
  register,
  errors,
  watchedFields,
  handleChangeTypeOfResponse,
  handleDeleteQuestion,
  handleAddNewQuestion,
  handleMakeAnCopy,
  setSelectedScreen,
  selectedScreen,
  currentProcess,
  selectedResponseItem,
}: AccordionsProps) => {
  const isEditing =
    editingScreen && editingScreen.uniqueId === process.uniqueId;

  const renderResponseComponent = (item: any, isEditable: boolean) => {
    const componentMap: { [key: string]: any } = {
      1: (
        <ResponseTenantName
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      2: (
        <ResponseSiteName
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      3: (
        <ResponsePersonName
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      4: (
        <ResponseLocation
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      5: (
        <ResponseScanner
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      6: (
        <ResponseScanner
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      7: (
        <ResponseScanner
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      8: (
        <ResponseScanner
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      9: (
        <ResponseMultipleChoice
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      10: (
        <ResponseMultipleChoice
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      11: (
        <ResponseMultipleChoice
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      12: (
        <ResponseText
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      13: (
        <ResponseText
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      14: (
        <ResponseNumber
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      15: (
        <ResponseDateTime
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      16: (
        <ResponseDateTime
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      17: (
        <ResponseDateTime
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      18: (
        <ResponseAcknowledgement
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      19: (
        <ResponseInformation
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      20: (
        <ResponseSignature
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      21: (
        <ResponseImage
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      22: (
        <ResponseImageWithInputs
          questionData={item}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
        />
      ),
      23: (
        <ResponseVideo
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      24: (
        <ResponseMagicContainer
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      25: (
        <ResponseAudio
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      26: (
        <ResponseToggle
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      27: (
        <ResponseDateTime
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      28: (
        <ResponseMultipleChoice
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      29: (
        <ResponseGridsName
          questionData={item}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        />
      ),
      30: (
        <ResponseImageWithInputs
          questionData={item}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
          isEditable={isEditable}
          tippyRef={tippyRef}
          visible={visible}
          show={show}
          hide={hide}
        />
      ),
    };
    return componentMap[item.inputType] || <p>{t("web.label.Notfound")}</p>;
  };
  const shouldShowResponseButton = (type: number) => {
    const questionTypesWithButton = [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 27, 28, 24,
    ];
    return questionTypesWithButton.includes(type);
  };
  const shouldShowSelectedOptions = (type: number) => {
    const questionTypesWithButton = [9, 10, 11, 27];
    return questionTypesWithButton.includes(type);
  };
  const authService = Authentication();
  var userDetails = authService.decryptData("UserDetails", 2);

  return (
    <>
      <div
        className="w-full px-[3.3rem] py-[1.3rem]  group"
        onClick={() => {
          toggleAccordionHandle(process.uniqueId);
          setSelectedScreen(process);
        }}
      >
        <div className="w-full h-auto flex justify-between items-center ">
          <div className="flex-1">
            <div className="w-full flex justify-start items-center gap-[1.6rem]">
              {isEditing ? (
                <input
                  className="text-[2.2rem] font-inter font-medium bg-transparent text-io-black dark:text-io-white border border-io-gray-c8 dark:border-io-black-48 px-[1.5rem] py-[0.7rem] rounded-[0.5rem]"
                  value={editingScreen.name}
                  onChange={(e) =>
                    setEditingScreen({ ...editingScreen, name: e.target.value })
                  }
                  autoFocus
                />
              ) : (
                <p className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
                  {process.name}
                </p>
              )}
              {isEditing ? (
                <IconBtn
                  icon={<TickIcon />}
                  size="w-[2.2rem]"
                  handleClick={(e) => {
                    e.stopPropagation();
                    handleBlur();
                  }}
                />
              ) : (
                process?.flowId !== "getstarted" && (
                  <div className="hidden group-hover:block">
                    <IconBtn
                      icon={<EditIcon />}
                      size="w-[2.2rem]"
                      handleClick={(e) => {
                        e.stopPropagation();
                        setEditingScreen({
                          uniqueId: process.uniqueId,
                          name: process.name,
                        });
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div
            className={`w-auto transition-all duration-300 ${
              open ? "rotate-180" : "rotate-0"
            }`}
          >
            <IconBtn icon={<AccordionDropDownIcon />} size="w-[2.2rem]" />
          </div>
        </div>
      </div>
      <div
        className={`w-full  ${
          open === true ? "h-auto" : "h-0 overflow-hidden"
        }`}
      >
        <div className="w-full border-t border-io-gray-e9 dark:border-io-black-48 px-[7.5rem] pt-[1.5rem] pb-[2rem]">
          <div className="w-full flex justify-end mb-4">
            <IconTextBtn
              icon={<AddNewScreenIcon />}
              label="Add Question"
              btnSize="large"
              handleClick={() => handleAddNewQuestion()}
            />
          </div>

          {process?.questionDetails.length !== 0 ? (
            <div className="w-full h-auto border border-io-gray-c8 dark:border-io-black-48 rounded-[1.2rem] overflow-hidden">
              <div className="w-full h-auto">
                <div className="border-b border-io-gray-c8 dark:border-io-black-48">
                  <div className="flex gap-6 px-[3.3rem] py-[2rem]">
                    <div className="w-full text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white text-left">
                      Question
                    </div>
                    <div className="w-full text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white text-left">
                      Type of Response
                    </div>
                    <div className="w-[5rem]"></div>
                  </div>
                </div>
                <div className="w-full">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="RESPONSECONTAINER">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} className="w-full">
                          {process?.questionDetails.map(
                            (item: any, index: any) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`w-full h-auto mb-[1rem] pb-1 group ${
                                      snapshot.isDragging
                                        ? "dragging bg-io-white shadow-md"
                                        : ""
                                    } ${
                                      item.id === currentQuestion?.id
                                        ? "bg-[#F1F7FE] dark:bg-[#2d3445] "
                                        : "bg-[#fcfcfc] dark:bg-[#272936]"
                                    }`}
                                  >
                                    <div
                                      className={`w-full h-auto flex items-center gap-6 px-[2rem] py-[2.3rem] bg-[#fcfcfc] transition-all duration-200 group-hover:bg-[#F1F7FE] dark:group-hover:bg-[#2d3445] ${
                                        item.id === currentQuestion?.id
                                          ? "bg-[#F1F7FE] dark:bg-[#2d3445]"
                                          : "bg-[#fcfcfc] dark:bg-[#272936]"
                                      } `}
                                      onClick={() =>
                                        handleToggleQuestionOption(item)
                                      }
                                    >
                                      <div className="w-full">
                                        <div className="w-full flex justify-start items-center gap-[1.4rem]">
                                          <div className="w-[1.6rem]">
                                            <DragSixDotGrayIcon
                                              active={
                                                item.id === currentQuestion?.id
                                              }
                                            />
                                          </div>
                                          {item.id === currentQuestion?.id ? (
                                            <input
                                              className="w-[80%] text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.5rem] rounded-[0.5rem]"
                                              {...register("questionName")}
                                              name={"questionName"}
                                              autoFocus
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            />
                                          ) : (
                                            <p className="text-[1.6rem] flex-1 font-inter font-normal text-io-gray-66 dark:text-io-white">
                                              {item.title
                                                ? item.title
                                                : "Question"}{" "}
                                              {item.isMandatory && (
                                                <span className="text-[2rem] text-io-red">
                                                  *
                                                </span>
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="w-full">
                                        <div className="w-[70%] h-full">
                                          {renderResponseComponent(
                                            item,
                                            item.id === currentQuestion?.id
                                          )}
                                        </div>
                                      </div>
                                      <div className="w-auto h-auto">
                                        {item.id === currentQuestion?.id && (
                                          <div className="w-full flex flex-col justify-center items-center gap-[1.4rem]">
                                            <div className="w-auto mx-auto">
                                              <IconBtn
                                                icon={<DeleteIcon />}
                                                size="w-[1.6rem]"
                                                handleClick={(e) => {
                                                  e.stopPropagation();
                                                  handleDeleteQuestion(
                                                    item,
                                                    index
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="w-auto mx-auto">
                                              <IconBtn
                                                icon={<MakeAnCopyIcon />}
                                                size="w-[1.6rem]"
                                                handleClick={(e) => {
                                                  e.stopPropagation();
                                                  handleMakeAnCopy(item, index);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {item.id === currentQuestion?.id && (
                                      <div className="w-full h-auto border-y border-[#E6EFF8] dark:border-[#363846]">
                                        <div className="w-full flex justify-start items-center gap-[4rem] m-[2rem] ">
                                          <div className="w-auto question-seperator">
                                            <ToggleButton
                                              id="madatory"
                                              label={t(
                                                "web.logic.label.mandatory"
                                              )}
                                              size="large"
                                              labelPosition="right"
                                              formHandle={register(
                                                "isMandatory"
                                              )}
                                              value={
                                                watchedFields["isMandatory"]
                                              }
                                            />
                                          </div>
                                          <div className="w-auto question-seperator">
                                            <ToggleButton
                                              id="evidence"
                                              label={t(
                                                "web.logic.label.evidence"
                                              )}
                                              size="large"
                                              labelPosition="right"
                                              formHandle={register(
                                                "isEvidence"
                                              )}
                                              value={
                                                watchedFields["isEvidence"]
                                              }
                                            />
                                          </div>
                                          <div className="w-auto question-seperator">
                                            <ToggleButton
                                              id="notes"
                                              label={t("web.logic.label.notes")}
                                              size="large"
                                              labelPosition="right"
                                              formHandle={register("isNotes")}
                                              value={watchedFields["isNotes"]}
                                            />
                                          </div>
                                          <div className="w-auto">
                                            <div className="w-full flex justify-start items-center gap-4">
                                              {shouldShowResponseButton(
                                                item.inputType
                                              ) && (
                                                <IconTextBtn
                                                  btnSize="large"
                                                  style="text-io-primary dark:text-io-primary"
                                                  label="Response"
                                                  handleClick={() =>
                                                    openResponseSettings(item)
                                                  }
                                                />
                                              )}
                                              {shouldShowSelectedOptions(
                                                item.inputType
                                              ) && (
                                                <div className="flex-1 h-auto flex justify-start items-center flex-wrap gap-[0.7rem]">
                                                  {selectedResponseItem?.options?.map(
                                                    (
                                                      resVal: any,
                                                      i: number
                                                    ) => (
                                                      <div
                                                        key={i}
                                                        className={`w-auto h-auto px-3 py-[0.1rem] text-[1.3rem] rounded-full bg-opacity-10 
                                                            ${
                                                              resVal.color ===
                                                                "red" &&
                                                              "text-io-red bg-io-red"
                                                            }
                                                            ${
                                                              resVal.color ===
                                                                "green" &&
                                                              "text-io-green bg-io-green"
                                                            }
                                                            ${
                                                              resVal.color ===
                                                                "orange" &&
                                                              "text-io-orange bg-io-orange"
                                                            }
                                                            ${
                                                              resVal.color ===
                                                                "blue" &&
                                                              "text-io-blue bg-io-blue"
                                                            }
                                                            ${
                                                              resVal.color ===
                                                                "gray" &&
                                                              "text-io-gray-c8 bg-io-gray-c8"
                                                            }
                                                            `}
                                                      >
                                                        {resVal.value}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {item.id === currentQuestion?.id &&
                                      item.inputType === 29 && (
                                        <GridComponent
                                          selectedScreen={selectedScreen}
                                          register={register}
                                          processData={processData}
                                          setProcessData={setProcessData}
                                          currentQuestion={currentQuestion}
                                        />
                                      )}
                                    {item.id === currentQuestion?.id &&
                                      item.inputType === 22 && (
                                        <ImageWithInput
                                          selectedScreen={selectedScreen}
                                          register={register}
                                          processData={processData}
                                          setProcessData={setProcessData}
                                          currentQuestion={currentQuestion}
                                        />
                                      )}
                                    {item.id === currentQuestion?.id &&
                                      userDetails.roleId === 10 && (
                                        <ConfigurationForm
                                          register={register}
                                          t={t}
                                          watchedFields={watchedFields}
                                        />
                                      )}
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-[1.6rem] font-inter font-normal text-io-gray-33 dark:text-io-white text-center italic">
              No Questions Found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProcessScreensNew;
