import React from "react";
import { ToggleButton } from "../../../form/toggle-btn";

const ConfigurationForm = ({ register, t, watchedFields }: any) => {
  return (
    <>
      <div className="grid grid-cols-12 grid grid-cols-1 sm:grid-cols-2 gap-[1rem] gap-6 bg-white p-6 rounded-lg m-10">
        <div className="col-span-1 sm:col-span-1 flex items-center gap-[1.5rem]">
          <ToggleButton
            id="isAuto"
            size="large"
            label={t("web.magix.text.autocapture")}
            labelPosition="right"
            formHandle={register("isAutoCapture")}
            value={watchedFields["isAutoCapture"]}
          />
        </div>

        <div className="col-span-1 sm:col-span-1 flex items-center gap-[1.5rem]">
          <ToggleButton
            id="isWebViewInject"
            size="large"
            label={t("web.magix.text.isWebViewInject")}
            labelPosition="right"
            formHandle={register("isWebViewInject")}
            value={watchedFields["isWebViewInject"]}
          />
        </div>
        {/* Auto Capture Configuration */}
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Auto Capture Configuration</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("autoCaptureConfiguration")}
            name={"autoCaptureConfiguration"}
            placeholder="Auto Capture Configuration"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Auto Capture Type</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("autoCaptureType")}
            name={"autoCaptureType"}
            placeholder="Auto Capture Type"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        {/* Web Inject Section */}

        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Web Inject Script</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("webViewInjectScript")}
            name={"webViewInjectScript"}
            placeholder="webViewInjectScript"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* Resolution & Length Section */}
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Photo Resolution</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("photoResolution")}
            name={"photoResolution"}
            placeholder="Photo Resolution"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Video Resolution</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("videoResolution")}
            name={"videoResolution"}
            placeholder="Video Resolution"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* View Port & Source Endpoint Section */}
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">View Port Details</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("viewPort")}
            name={"viewPort"}
            placeholder="viewPort"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Source Endpoint</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("sourceEndpoint")}
            name={"sourceEndpoint"}
            placeholder="Source Endpoint"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* Resolution Type, Width & Height Section */}
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Resolution Type</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("resolutionType")}
            name={"resolutionType"}
            placeholder="Resolution Type"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Resolution Width</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("resolutionWidth")}
            name={"resolutionWidth"}
            placeholder="Resolution Width"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* Resolution Height Section */}
        <div className="col-span-1 sm:col-span-1">
          <div className="text-[1.2rem]">Resolution Height</div>
          <input
            className="w-[100%] text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
            {...register("resolutionHeight")}
            name={"resolutionHeight"}
            placeholder="Resolution Height"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* Toggle Buttons Section */}
        <div className="col-span-1 sm:col-span-1 flex flex-wrap gap-[1.5rem]">
          <ToggleButton
            id="orientation"
            size="large"
            label={t("web.magix.text.orientation")}
            labelPosition="right"
            formHandle={register("orientation")}
            value={watchedFields["orientation"]}
          />
          <ToggleButton
            id="isPreCheck"
            size="large"
            label={t("web.magix.text.isPreCheck")}
            labelPosition="right"
            formHandle={register("isPreCheck")}
            value={watchedFields["isPreCheck"]}
          />
          <ToggleButton
            id="flashlightMode"
            size="large"
            label={t("web.magix.text.flashlightMode")}
            labelPosition="right"
            formHandle={register("flashlightMode")}
            value={watchedFields["flashlightMode"]}
          />
          <ToggleButton
            id="isFlashlightAccessDisabled"
            size="large"
            label={t("web.magix.text.isFlashlightAccessDisabled")}
            labelPosition="right"
            formHandle={register("isFlashlightAccessDisabled")}
            value={watchedFields["isFlashlightAccessDisabled"]}
          />
          <ToggleButton
            id="isOrientationLockerDisabled"
            size="large"
            label={t("web.magix.text.isOrientationLockerDisabled")}
            labelPosition="right"
            formHandle={register("isOrientationLockerDisabled")}
            value={watchedFields["isOrientationLockerDisabled"]}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 bg-white p-6 rounded-lg m-6"></div>
    </>
  );
};

export default ConfigurationForm;
