import { InputFieldWithoutForm } from "../../form/input-fields";
import UploadImage from "../../form/upload-image";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMyContext } from "../../my-context/MyContext";
import { languageDetails } from "../../../utils/common";
import ModalPageFooter from "../../global-layout/modal-page-footer";
import Skeleton from "react-loading-skeleton";
import { SelectInputWithoutForm } from "../../form/select-input";
import { userValidation } from "../../../services/validation";
import { userCreate, userEdit } from "../../../services/authFunctions";
import i18n from "../../../utils/localization/i18n";
interface LanguageOption {
  id: any;
  short: string;
  value: string;
  localValue: string;
  threeShort: string;
  code: string;
}

function ViewAccountForm({ handleClose }: any) {
  const authService = Authentication();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoader, setImageLoader] = useState(false);
  const [mode] = useState("update");
  const { setLanguage } = useMyContext();
  const [languages] = useState<LanguageOption[]>(languageDetails);

  var userDetails = authService.decryptData("UserDetails", 2);
  const [formData, setFormData] = useState(userDetails);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    roleId: "",
  });
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const response = await userEdit(sessionStorage.getItem("userId"));
      if (response.success) {
        response.data.imageUrl = response.data.profileImageUrl;
        authService.encryptData(response.data, "UserDetails", 2);
        setFormData(response.data);
      }
      setIsLoading(false);
    };
    fetchUserData();
  }, []);
  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const user = userValidation(formData);

    setErrors({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      roleId: user.roleId,
    });

    return (
      !user.email &&
      !user.firstName &&
      !user.lastName &&
      !user.mobile &&
      !user.roleId
    );
  };
  const options = [
    { label: "Super Admin", id: 1 },
    { label: "Tenant Admin", id: 2 },
    { label: "Site Admin", id: 3 },
    { label: "Site User", id: 4 },
    { label: "Portal User", id: 4 },
    { label: "Portal Guest User", id: 6 },
    { label: "Mobile User", id: 7 },
    { label: "Mobile Guest User", id: 8 },
  ];
  const getRoleValue = (roleId: any) => {
    const defaultRole = options.find((option) => option.id === roleId);
    return defaultRole;
  };
  const handleLanguageChange = (value: any) => {
    const selectedLanguageDetails = languages.find((lang) => lang.id === value);
    sessionStorage.setItem(
      "selectedLanguageDetails",
      JSON.stringify(selectedLanguageDetails)
    );
    setLanguage(selectedLanguageDetails?.code || "en");
    i18n.changeLanguage(selectedLanguageDetails?.code);
  };
  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      setIsLoading(true);
      const formDataLowerCase = {
        ...formData,
        email: formData.email.toLowerCase(),
        languageId: formData.languageId,
      };
      const result = await userCreate(
        formDataLowerCase,
        mode,
        sessionStorage.getItem("userId"),
        "Profile"
      );

      if (result.success) {
        authService.encryptData("", "UserDetails", 2);
        authService.encryptData(formDataLowerCase, "UserDetails", 2);
        handleLanguageChange(formDataLowerCase.languageId);
        setTimeout(() => {
          handleClose();
          handleClose(true);
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handleFormCancel = () => {
    handleClose(true);
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.firstname")}
                      type="text"
                      id="newUserFirstName"
                      isRequired={true}
                      value={formData.firstName}
                      error={t(errors.firstName)}
                      onChange={(e) => handleInputChange("firstName", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.lastname")}
                      type="text"
                      id="newUserLastName"
                      isRequired={true}
                      value={formData.lastName}
                      error={t(errors.lastName)}
                      onChange={(e) => handleInputChange("lastName", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.email")}
                      type="email"
                      id="newUserEmail"
                      isRequired={true}
                      isReadOnly={true}
                      value={formData.email}
                      error={t(errors.email)}
                      onChange={(e) => handleInputChange("email", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.mobile")}
                      type="text"
                      id="newUserPhoneNumber"
                      isRequired={true}
                      value={formData.mobile}
                      error={t(errors.mobile)}
                      onChange={(e) => handleInputChange("mobile", e)}
                    />
                  )}
                </div>
                <div className="mb-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectInputWithoutForm
                      labelName={t("web.profile.label.role")}
                      id={`screenName`}
                      isRequired={true}
                      options={options}
                      isReadOnly={true}
                      defaultValue={getRoleValue(formData.roleId)}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.id}
                      getSelection={(option: any) =>
                        handleInputChange("roleId", option.id)
                      }
                    />
                  )}
                </div>
                <div className="mb-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectInputWithoutForm<LanguageOption>
                      labelName={t("language")}
                      id={`language`}
                      isRequired={true}
                      options={languages}
                      isReadOnly={false}
                      defaultValue={languages.find(
                        (option) => option.id === formData.languageId
                      )}
                      getOptionLabel={(option) => option.value}
                      getOptionValue={(option) => option.id}
                      getSelection={(option: any) =>
                        handleInputChange("languageId", option.id)
                      }
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.referenceId")}
                      type="text"
                      id="referenceId"
                      value={formData.referenceId}
                      onChange={(e) => handleInputChange("referenceId", e)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              {isLoading ? (
                <Skeleton width="100%" height={150} borderRadius={3} />
              ) : (
                <div className="w-full h-auto">
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isImageLoader={isImageLoader}
                    setImageLoader={setImageLoader}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={handleFormCancel}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        isImageLoader={isImageLoader}
      />{" "}
    </div>
  );
}
export default ViewAccountForm;
