import React, { useEffect, useState } from "react";
import IconTextBtn from "../../../form/icon-text-btn";
import { AddNewScreenIcon, DeleteIcon } from "../../../svg";
import IconBtn from "../../../form/icon-btn";
import { v4 as uuidv4 } from "uuid";
const GridComponent = ({
  register,
  processData,
  selectedScreen,
  currentQuestion,
  setProcessData,
  isReadOnly,
}: any) => {
  //#region Grid Function
  const [rows, setRows] = useState<string[][]>([]);
  const handleAddNewRow = () => {
    const newRows = [...rows, [""]];
    setRows(newRows);
    updateProcessDataWithRows(newRows);
  };
  const handleAddNewColumn = (rowIndex: number) => {
    const updatedRows = [...rows];
    if (updatedRows[rowIndex].length < 4) {
      updatedRows[rowIndex] = [...updatedRows[rowIndex], ""];
      setRows(updatedRows);
      updateProcessDataWithRows(updatedRows);
    }
  };
  const handleDeleteRow = (rowIndex: number) => {
    const updatedRows = rows.filter((_, i) => i !== rowIndex);
    setRows(updatedRows);
    updateProcessDataWithRows(updatedRows);
  };
  const handleDeleteColumn = (rowIndex: number, colIndex: number) => {
    const updatedRows = [...rows];
    if (updatedRows[rowIndex].length > 1) {
      updatedRows[rowIndex].splice(colIndex, 1);
      setRows(updatedRows);
      updateProcessDataWithRows(updatedRows);
    }
  };
  const handleInputChange = (
    rowIndex: number,
    colIndex: number,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][colIndex] = value;
    setRows(updatedRows);
    updateProcessDataWithRows(updatedRows);
  };
  const getRowsFromProcessData = () => {
    const item = processData.find(
      (item: any) => item.uniqueId === selectedScreen?.uniqueId
    );
    if (item && item.questionDetails) {
      const questionDetail = item.questionDetails.find(
        (detail: any) => detail.id === currentQuestion?.id
      );
      if (questionDetail && questionDetail.gridValues) {
        const rows = [];
        const rowCount =
          Math.max(
            ...questionDetail.gridValues.map((value: any) => value.rowIndex)
          ) + 1;

        for (let i = 0; i < rowCount; i++) {
          const row = questionDetail.gridValues
            .filter((value: any) => value.rowIndex === i)
            .map((value: any) => value.placeHolder);
          rows.push(row);
        }

        return rows;
      }
    }
    return [];
  };
  const generateGuid = () => {
    return uuidv4();
  };
  // Function to update processData whenever rows are modified
  const updateProcessDataWithRows = (updatedRows: string[][]) => {
    const itemIndex = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen.uniqueId;
    });

    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const updatedItem = { ...processData[itemIndex] };

      const screenDetailIndex = updatedItem.questionDetails.findIndex(
        (item: any) => item.id === currentQuestion?.id
      );

      if (screenDetailIndex !== -1) {
        const formattedResolutionType = updatedRows.flatMap((row, rIndex) => {
          const totalColumns = row.length; // Total columns in the current row
          const columnSize = Math.floor(12 / totalColumns); // Calculate column size dynamically
          return row.map((colValue, cIndex) => ({
            id: updatedItem.questionDetails[screenDetailIndex].id,
            uniqueId: generateGuid(),
            placeHolder: colValue || "",
            rowIndex: rIndex,
            columnIndex: cIndex,
            columnSize: columnSize, // Use the dynamically calculated column size
            type: 7,
            subType: 0,
            typeValidations: null,
            typeManipulations: null,
          }));
        });
        const updatedScreenDetail = {
          ...updatedItem.questionDetails[screenDetailIndex],
          gridValues: formattedResolutionType,
        };

        updatedItem.questionDetails[screenDetailIndex] = updatedScreenDetail;
        updatedProcessData[itemIndex] = updatedItem;

        // Update processData state
        setProcessData(updatedProcessData);
      }
    }
  };
  useEffect(() => {
    // Initialize rows from processData when component mounts or when selectedScreen changes
    const initialRows = getRowsFromProcessData();
    setRows(initialRows);
  }, [processData, selectedScreen]);
  //#endregion
  return (
    <div className="grid grid-cols-1 gap-4 mb-4 p-3 m-10 bg-white rounded-lg dark:bg-[#272936]">
      <div className="w-full flex justify-end pr-2 mb-4">
        <IconTextBtn
          icon={<AddNewScreenIcon />}
          label="Add Row"
          btnSize="large"
          handleClick={() => handleAddNewRow()}
        />
      </div>
      {getRowsFromProcessData().map((row: any, rowIndex: any) => (
        <div key={rowIndex} className="w-full flex items-center mb-3">
          <div className="flex w-[90%] relative">
            {row.map((col: string, colIndex: number) => (
              <div
                key={colIndex}
                className="relative flex items-center mr-4 w-full max-xl:mr-2"
              >
                <input
                  className="flex-1 text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem] pr-10
                  max-xl::text-[1rem] max-xl:py-[1rem] max-xl:pr-5 max-xl:px-[1rem] lg:w-[80%] xl:w-[70%]" 
                  {...register(`resolutionType-${rowIndex}-${colIndex}`)}
                  value={col}
                  placeholder="Name"
                  onChange={(e) =>
                    handleInputChange(rowIndex, colIndex, e.target.value)
                  }
                  onClick={(e) => e.stopPropagation()}
                />
                {/* Move the Delete Icon below the input */}
                <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                  <IconBtn
                    icon={<DeleteIcon />}
                    size="w-[1.6rem]" // Icon size
                    handleClick={(e) => {
                      e.stopPropagation();
                      handleDeleteColumn(rowIndex, colIndex);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end ml-auto">
            <div className="w-full flex justify-end pr-2 mb-4">
              <IconTextBtn
                icon={<AddNewScreenIcon />}
                label=""
                btnSize="large"
                handleClick={() => handleAddNewColumn(rowIndex)}
                disabled={row.length >= 4}
              />
            </div>
            <div className="w-full flex justify-end pr-2 mb-4">
              <IconTextBtn
                icon={<DeleteIcon />}
                label=""
                btnSize="large"
                handleClick={() => handleDeleteRow(rowIndex)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GridComponent;
