import { InputField, TextAreaField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import PasteYourCode from "../../../form/paste-your-code";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface EditScreenFormProps {
  handleClose: () => void;
  editScreenValue: any;
  handleEditScreen: (data: any) => void;
}
const editScreenFormSchema = yup.object().shape({
  screenName: yup.string().required("Please enter the Screen Name"),
  screenDescription: yup.string(),
  privateOrPublic: yup.string().required("Please select any one of the Option"),
  uniqueId: yup.string(),
});

function EditScreenForm({
  handleClose,
  editScreenValue,
  handleEditScreen,
}: EditScreenFormProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(editScreenFormSchema) });

  const editOptions = [
    { id: "public", label: "Public" },
    { id: "private", label: "Private" },
  ];

  const handleFormSubmit = (data: any) => {
    handleEditScreen(data);
  };

  const handleFormCancel = () => {
    handleClose();
  };
  useEffect(() => {
    if (editScreenValue) {
      setValue("screenName", editScreenValue.name);
      setValue("screenDescription", editScreenValue.description);
      setValue("privateOrPublic", "private");
      setValue("uniqueId", editScreenValue.uniqueId);
    }
  }, []);
  return (
    <form
      className="w-full h-full flex flex-col"
      onSubmit={handleSubmit((data) => handleFormSubmit(data))}
    >
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[98%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4 pb-10 mb-10 border-b border-dashed border-io-gray-c8">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  <InputField
                    labelName={t("web.logic.label.editscreen")}
                    type="text"
                    id="screenName"
                    isRequired={true}
                    formHandle={register("screenName")}
                    error={errors?.screenName?.message}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <TextAreaField
                    labelName={t("web.logic.label.screendescription")}
                    id="screenDescription"
                    isRequired={true}
                    formHandle={register("screenDescription")}
                    error={errors?.screenDescription?.message}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-auto ">
            <PasteYourCode />
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
      />
    </form>
  );
}
export default EditScreenForm;
