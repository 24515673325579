import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import IconTextBtn from "../../form/icon-text-btn";
import { InputField } from "../../form/input-fields";
import { SelectInput } from "../../form/select-input";
import { ToggleButton } from "../../form/toggle-btn";
import { v4 as uuidv4 } from "uuid";
import { AddNewScreenIcon, DeleteIcon } from "../../svg";
import IconBtn from "../../form/icon-btn";
import "./index.css";

interface GridValue {
  id: string;
  uniqueId: string;
  placeHolder: string;
  rowIndex: number;
  columnIndex: number;
  columnSize: number;
  type: number;
  subType: number;
  typeValidations: any;
  typeManipulations: any;
}

interface AccordionProps {
  selectedScreen: any;
  register: any;
  errors: any;
  watchIsMandatory: boolean;
  watchIsEvidence: boolean;
  watchNotes: boolean;
  gridValues: GridValue[][];
  setGridValues: React.Dispatch<React.SetStateAction<GridValue[][]>>;
}

const Accordion: React.FC<AccordionProps> = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  gridValues,
  setGridValues,
}) => {
  // const { t } = useTranslation();
  // const [isGridVisible, setIsGridVisible] = useState(false);
  // const [ShowAddIcon, setShowAddIcon] = useState(false);

  // const generateGuid = () => uuidv4();

  // // Function to handle adding a new row with cell objects
  // const handleAddNewRow = () => {
  //   const newRow: GridValue[] = [
  //     {
  //       id: generateGuid(),
  //       uniqueId: generateGuid(),
  //       placeHolder: "",
  //       rowIndex: gridValues.length,
  //       columnIndex: 0,
  //       columnSize: 4,
  //       type: 7,
  //       subType: 0,
  //       typeValidations: null,
  //       typeManipulations: null,
  //     },
  //   ];
  //   setGridValues([...gridValues, newRow]);
  // };


  // const handleFirstRow = () => {
  //   const newRow: GridValue[] = [
  //     {
  //       id: generateGuid(),
  //       uniqueId: generateGuid(),
  //       placeHolder: "",
  //       rowIndex: gridValues.length,
  //       columnIndex: 0,
  //       columnSize: 4,
  //       type: 7,
  //       subType: 0,
  //       typeValidations: null,
  //       typeManipulations: null,
  //     },
  //   ];
  //   setGridValues([...gridValues, newRow]);
  //   // setShowAddIcon()
  // }

  // console.log("isGridVisible", gridValues);

  // // Function to handle adding a new column in a specific row
  // const handleAddNewColumn = (rowIndex: number) => {
  //   const updatedRows = [...gridValues];
  //   const row = updatedRows[rowIndex];

  //   if (row.length < 4) {
  //     const newColumn: GridValue = {
  //       id: generateGuid(),
  //       uniqueId: generateGuid(),
  //       placeHolder: "",
  //       rowIndex,
  //       columnIndex: row.length,
  //       columnSize: 4,
  //       type: 7,
  //       subType: 0,
  //       typeValidations: null,
  //       typeManipulations: null,
  //     };

  //     updatedRows[rowIndex] = [...row, newColumn];
  //     setGridValues(updatedRows);
  //   }
  // };

  // // Function to handle deleting a row
  // const handleDeleteRow = (rowIndex: number) => {
  //   const updatedRows = gridValues.filter((_, i) => i !== rowIndex);
  //   setGridValues(updatedRows);
  // };

  // // Function to handle deleting a column in a specific row
  // const handleDeleteColumn = (rowIndex: number, colIndex: number) => {
  //   const updatedRows = [...gridValues];
  //   if (updatedRows[rowIndex].length > 1) {
  //     updatedRows[rowIndex].splice(colIndex, 1);
  //     setGridValues(updatedRows);
  //   }
  // };

  // // Function to handle updating a specific cell's placeholder
  // const handleInputChange = (
  //   rowIndex: number,
  //   colIndex: number,
  //   value: string
  // ) => {
  //   const updatedRows = [...gridValues];
  //   updatedRows[rowIndex][colIndex].placeHolder = value;
  //   setGridValues(updatedRows);
  // };



  const { t } = useTranslation();
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isResponseGridVisible, setIsResponseGridVisible] = useState(true);
  const [isMagicConfigVisible, setIsMagicConfigVisible] = useState(false);

  const generateGuid = () => uuidv4();

  const handleAddNewRow = () => {
    const newRow = [
      {
        id: generateGuid(),
        uniqueId: generateGuid(),
        placeHolder: "",
        rowIndex: gridValues.length,
        columnIndex: 0,
        columnSize: 4,
        type: 7,
        subType: 0,
        typeValidations: null,
        typeManipulations: null,
      },
    ];
    setGridValues((prevGrid) => [...prevGrid, newRow]);
  };

  const handleAddNewColumn = (rowIndex: number) => {
    setGridValues((prevGrid) => {
      const updatedRows = [...prevGrid];
      const row = updatedRows[rowIndex];
      if (row.length < 4) {
        row.push({
          id: generateGuid(),
          uniqueId: generateGuid(),
          placeHolder: "",
          rowIndex,
          columnIndex: row.length,
          columnSize: 4,
          type: 7,
          subType: 0,
          typeValidations: null,
          typeManipulations: null,
        });
        updatedRows[rowIndex] = row;
      }
      return updatedRows;
    });
  };

  const handleDeleteRow = (rowIndex: number) => {
    setGridValues((prevGrid) => prevGrid.filter((_, i) => i !== rowIndex));
  };

  const handleDeleteColumn = (rowIndex: number, colIndex: number) => {
    setGridValues((prevGrid) => {
      const updatedRows = [...prevGrid];
      if (updatedRows[rowIndex].length > 1) {
        updatedRows[rowIndex].splice(colIndex, 1);
      }
      return updatedRows;
    });
  };

  const handleInputChange = (rowIndex: number, colIndex: number, value: string) => {
    setGridValues((prevGrid) => {
      const updatedRows = [...prevGrid];
      updatedRows[rowIndex][colIndex].placeHolder = value;
      return updatedRows;
    });
  };

  const renderGrid = () => (
    <div className="accordion-content mt-3 w-[90%] mx-auto items-center mb-6">
      {/* <div className="w-full flex justify-between items-center flex-col pr-2 mb-4 py-20 "> */}
      {/* <div>
      </div> */}
      {gridValues.length === 0 && <div className="flex justify-between items-center flex-col pr-2 mb-4 py-20" onClick={handleFirstRow}>
        <img src={require("../../../assets/images/add.png")} alt="add" className="w-[10rem]" />
        <p className="text-[1.8rem] text-[#ccc] w-[70%] text-center">Click here to add a row and start building your grid.</p>
      </div>}
      {gridValues.length !== 0 && <div className="flex justify-end">
        <IconTextBtn
          icon={<AddNewScreenIcon />}
          label="Add Row"
          btnSize="large"
          handleClick={handleAddNewRow}
        />
      </div>}
      {/* </div> */}
      <div className="border-b-[#ccc] border-dashed w-[80%] h-2"></div>
      {gridValues.length > 0 && <div className="grid grid-cols-1 gap-4 mb-4 p-3">
        {gridValues.length > 0 ? (
          gridValues.map((row, rowIndex) => (
            <div key={rowIndex} className="w-full flex items-center mb-3">
              <div className="flex w-[90%] relative">
                {row.map((col, colIndex) => (
                  <div
                    key={colIndex}
                    className="relative flex items-center mr-4 w-full"
                  >
                    <input
                      className="flex-1 text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem] pr-10 grid-input-width "
                      {...register(
                        `resolutionType-${rowIndex}-${colIndex}`
                      )}
                      value={col.placeHolder} // Binding placeHolder to the input
                      placeholder="Enter Text"
                      onChange={(e) =>
                        handleInputChange(
                          rowIndex,
                          colIndex,
                          e.target.value
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                      <IconBtn
                        icon={<DeleteIcon />}
                        size="w-[1.6rem]"
                        handleClick={(e) => {
                          e.stopPropagation();
                          handleDeleteColumn(rowIndex, colIndex);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-end ml-auto">
                <IconTextBtn
                  icon={<AddNewScreenIcon />}
                  label=""
                  handleClick={() => handleAddNewColumn(rowIndex)}
                  btnSize={""}
                />
                <IconBtn
                  icon={<DeleteIcon />}
                  size="w-[1.6rem]"
                  handleClick={() => handleDeleteRow(rowIndex)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="w-full flex items-center justify-center text-center p-6 min-h-[100px] border border-dashed border-gray-300 rounded-lg">
            <p className="accordion-no-row-text">
              No grids created. Please click Add Row to create gridssss.
            </p>
          </div>
        )}
      </div>
      }
    </div>
  )

  const handleFirstRow = () => {
    const newRow: GridValue[] = [
      {
        id: generateGuid(),
        uniqueId: generateGuid(),
        placeHolder: "",
        rowIndex: gridValues.length,
        columnIndex: 0,
        columnSize: 4,
        type: 7,
        subType: 0,
        typeValidations: null,
        typeManipulations: null,
      },
    ];
    setGridValues([...gridValues, newRow]);
    // setShowAddIcon()
  }

  const handleMagicFirstRow = () => {
    const newRow: GridValue[] = [
      {
        id: generateGuid(),
        uniqueId: generateGuid(),
        placeHolder: "",
        rowIndex: gridValues.length,
        columnIndex: 0,
        columnSize: 4,
        type: 7,
        subType: 0,
        typeValidations: null,
        typeManipulations: null,
      },
    ];
    setGridValues([...gridValues, newRow]);
    // setShowAddIcon()
  }


  return (
    <div className="accordion w-full mx-auto accordion-background  px-10 py-10">
      <div className="accordion-header border-b-[2px] border-darkgrey pb-5">
        <div
          className="cursor-pointer flex items-center justify-between h-12 w-full rounded-lg text-blue-500"
          // onClick={() => setIsGridVisible(!isGridVisible)}
          onClick={() => setIsResponseGridVisible(!isResponseGridVisible)}
        >
          <div className="flex items-center">
            <p className="accordion-header text-[1.8rem] text-black dark:text-io-white">Response Grids</p>
          </div>
          <div className="flex items-center">
            {isGridVisible ? (
              <FaChevronUp className="transition-transform duration-300 dark:text-io-white text-black w-6 h-6" />
            ) : (
              <FaChevronDown className="transition-transform duration-300 dark:text-io-white text-black w-6 h-6" />
            )}
          </div>
        </div>
      </div>
      {isResponseGridVisible &&
        <div className="accordion-content mt-3 w-[90%] mx-auto items-center mb-6">
          {/* <div className="w-full flex justify-between items-center flex-col pr-2 mb-4 py-20 "> */}
          {/* <div>
       </div> */}
          {gridValues.length === 0 && <div className="flex justify-between items-center flex-col pr-2 mb-4 py-20" onClick={handleFirstRow}>
            <img src={require("../../../assets/images/add.png")} alt="add" className="w-[10rem]" />
            <p className="text-[1.8rem] text-[#ccc] w-[70%] text-center">Click here to add a row and start building your grid.</p>
          </div>}
          {gridValues.length !== 0 && <div className="flex justify-end">
            <IconTextBtn
              icon={<AddNewScreenIcon />}
              label="Add Row"
              btnSize="large"
              handleClick={handleAddNewRow}
            />
          </div>}
          {/* </div> */}
          <div className="border-b-[#ccc] border-dashed w-[80%] h-2"></div>
          {gridValues.length > 0 && <div className="grid grid-cols-1 gap-4 mb-4 p-3">
            {gridValues.length > 0 ? (
              gridValues.map((row, rowIndex) => (
                <div key={rowIndex} className="w-full flex items-center mb-3">
                  <div className="flex w-[90%] relative">
                    {row.map((col, colIndex) => (
                      <div
                        key={colIndex}
                        className="relative flex items-center mr-4 w-full"
                      >
                        <input
                          className="flex-1 text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem] pr-10 grid-input-width "
                          {...register(
                            `resolutionType-${rowIndex}-${colIndex}`
                          )}
                          value={col.placeHolder} // Binding placeHolder to the input
                          placeholder="Enter Text"
                          onChange={(e) =>
                            handleInputChange(
                              rowIndex,
                              colIndex,
                              e.target.value
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                          <IconBtn
                            icon={<DeleteIcon />}
                            size="w-[1.6rem]"
                            handleClick={(e) => {
                              e.stopPropagation();
                              handleDeleteColumn(rowIndex, colIndex);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex justify-end ml-auto">
                    <IconTextBtn
                      icon={<AddNewScreenIcon />}
                      label=""
                      handleClick={() => handleAddNewColumn(rowIndex)}
                      btnSize={""}
                    />
                    <IconBtn
                      icon={<DeleteIcon />}
                      size="w-[1.6rem]"
                      handleClick={() => handleDeleteRow(rowIndex)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center text-center p-6 min-h-[100px] border border-dashed border-gray-300 rounded-lg">
                <p className="accordion-no-row-text dark:text-io-white">
                  No grids created. Please click Add Row to create gridssss.
                </p>
              </div>
            )}
          </div>
          }
        </div>}


      <div className="accordion-header border-b-[2px] border-darkgrey pb-5 mt-20">
        <div
          className="cursor-pointer flex items-center justify-between h-12 w-full rounded-lg text-blue-500"
          // onClick={() => setIsGridVisible(!isGridVisible)}
          onClick={() => setIsMagicConfigVisible(!isMagicConfigVisible)}
        >
          <div className="flex items-center">
            <p className="accordion-header text-[1.8rem] text-black dark:text-io-white">Magic Configuration</p>
          </div>
          <div className="flex items-center">
            {isGridVisible ? (
              <FaChevronUp className="transition-transform duration-300 text-black w-6 h-6 dark:text-io-white" />
            ) : (
              <FaChevronDown className="transition-transform duration-300 text-black w-6 h-6 dark:text-io-white" />
            )}
          </div>
        </div>
      </div>
      {isMagicConfigVisible &&
    //   <div className="accordion-content mt-3 w-[90%] mx-auto items-center mb-6">
    //   {/* <div className="w-full flex justify-between items-center flex-col pr-2 mb-4 py-20 "> */}
    //   {/* <div>
    //   </div> */}
    //   {gridValues.length === 0 && <div className="flex justify-between items-center flex-col pr-2 mb-4 py-20" onClick={handleMagicFirstRow}>
    //     <img src={require("../../../assets/images/add.png")} alt="add" className="w-[10rem]" />
    //     <p className="text-[1.8rem] text-[#ccc] w-[70%] text-center">Click here to add a row and start building your grid.</p>
    //   </div>}
    //   {gridValues.length !== 0 && <div className="flex justify-end">
    //     <IconTextBtn
    //       icon={<AddNewScreenIcon />}
    //       label="Add Row"
    //       btnSize="large"
    //       handleClick={handleAddNewRow}
    //     />
    //   </div>}
    //   {/* </div> */}
    //   <div className="border-b-[#ccc] border-dashed w-[80%] h-2"></div>
    //   {gridValues.length > 0 && <div className="grid grid-cols-1 gap-4 mb-4 p-3">
    //     {gridValues.length > 0 ? (
    //       gridValues.map((row, rowIndex) => (
    //         <div key={rowIndex} className="w-full flex items-center mb-3">
    //           <div className="flex w-[90%] relative">
    //             {row.map((col, colIndex) => (
    //               <div
    //                 key={colIndex}
    //                 className="relative flex items-center mr-4 w-full"
    //               >
    //                 <input
    //                   className="flex-1 text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem] pr-10 grid-input-width "
    //                   {...register(
    //                     `resolutionType-${rowIndex}-${colIndex}`
    //                   )}
    //                   value={col.placeHolder} // Binding placeHolder to the input
    //                   placeholder="Enter Text"
    //                   onChange={(e) =>
    //                     handleInputChange(
    //                       rowIndex,
    //                       colIndex,
    //                       e.target.value
    //                     )
    //                   }
    //                   onClick={(e) => e.stopPropagation()}
    //                 />
    //                 <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
    //                   <IconBtn
    //                     icon={<DeleteIcon />}
    //                     size="w-[1.6rem]"
    //                     handleClick={(e) => {
    //                       e.stopPropagation();
    //                       handleDeleteColumn(rowIndex, colIndex);
    //                     }}
    //                   />
    //                 </div>
    //               </div>
    //             ))}
    //           </div>

    //           <div className="flex justify-end ml-auto">
    //             <IconTextBtn
    //               icon={<AddNewScreenIcon />}
    //               label=""
    //               handleClick={() => handleAddNewColumn(rowIndex)}
    //               btnSize={""}
    //             />
    //             <IconBtn
    //               icon={<DeleteIcon />}
    //               size="w-[1.6rem]"
    //               handleClick={() => handleDeleteRow(rowIndex)}
    //             />
    //           </div>
    //         </div>
    //       ))
    //     ) : (
    //       <div className="w-full flex items-center justify-center text-center p-6 min-h-[100px] border border-dashed border-gray-300 rounded-lg">
    //         <p className="accordion-no-row-text">
    //           No grids created. Please click Add Row to create gridssss.
    //         </p>
    //       </div>
    //     )}
    //   </div>
    //   }
    // </div>
    <p className="accordion-no-row-text text-center p-10 text-[1.5rem] dark:text-io-white">
              No Configuration created.
            </p>
      }
    </div>
  );
};

export default Accordion;