import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import TabSlider from "../../../UI/tab-slider";
import ScreenListHeader from "../screen-list-header";
import VoiceSearchInput from "../../../form/voice-search-input";
import {
  DragSixDotIcon,
  HandIcon,
  ResponseAcknowledgementIcon,
  ResponseAudioIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseImageWithInputIcon,
  ResponseInformationIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseMultipleChoiceIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSignatureIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  ResponseGridsIcon,
} from "../../../svg";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import ResponseSettings from "../response-settings";
import ProcessPreview from "../process-preview";
import ProcessPreviewModal from "../process-preview-modal";
import ImportResponse from "../import-response";
import EditScreenWrapper from "../edit-screen-wrapper";
import CreateScreenWrapper from "../create-screen-wrapper";
import PageHeader from "../../../global-layout/page-header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  questionServices,
  questionnaireOptions,
} from "../../../../services/InspectionOneServices";
import Skeleton from "react-loading-skeleton";
import ToastifyMessage from "../../../../lib/toastify";
import { ToastSuccess } from "../../../UI/toast";
import { useTranslation } from "react-i18next";
import {
  dateTimeOptions,
  imageOptions,
  multipleChoiceOptions,
  scannerOptions,
  textOptions,
  typeOfResponse,
} from "../../../../services/questionConfig";
import QuestionCard from "../../../UI/question-card";
interface ReponseTypesContainerProps {
  items: any;
  openResponseSettings: (item: Item) => void;
  handleMakeAnCopy: (item: Item, index: number) => void;
  handleDelete: (item: Item, index: number) => void;
  selectedScreen: any;
  responseMultipleChoiceOptions: any;
  responseDateTimeOptions: any;
  responseScannerOptions: any;
  responseImageOptions: any;
  responseTextOptions: any;
  responseSettingsType: any;
}
interface ReponseTypesProps {
  items: any;
  disableReponseType: boolean;
  typeOfResponseIcons: any;
  currentProcess: any;
}
interface Item {
  id: string;
  order: number;
  label: string;
  icon: ReactNode;
}

interface OptionItem {
  id: any;
  value: any;
  label: any;
}
function ProcessScreens({ getToggleIsChecked, currentTheme }: any) {
  const { logicId, id } = useParams();
  const generateGuid = () => {
    return uuidv4();
  };
  const [responseValueOptions, setResponseValueOptions] = useState<any>([]);
  const [bindingoptions, setBindingoptions] = useState<any>([]);
  const [containerMaster, setContainerMaster] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [responseMagicContainerOptions, setResponseMagicContainerOptions] =
    useState<OptionItem[]>([]);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [genericDetails, setGenericDetails] = useState<any>({
    name: "",
    uniqueId: "",
  });

  const fetchQuestionProcessData = async () => {
    setIsLoading(true);
    setIsSkeletonLoading(true);
    try {
      const requestJson = {
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        mode: pathname.includes("/survey/") ? "2" : "1",
      };
      const response: any = await questionnaireOptions.QuestionnaireDetails(
        requestJson
      );
      const responseData = response.data.screenDetails;
      if (response.success) {
        setGenericDetails(response.data.genericDetails);
        if (responseData.length > 0) {
          setProcessData(responseData || []);
          setSelectedScreen(responseData[0]);
        }
        setIsLoading(false);
        setIsSkeletonLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchProcessData = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.QuestionnaireOptions(
        requestJson
      );
      const responseData = response.data.questionOption;
      if (response.success) {
        setResponseValueOptions(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBindingDetails = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.BindingDetails(
        requestJson
      );
      const responseData = response.data;
      if (response.success) {
        setBindingoptions(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchContainerMaster = async () => {
    try {
      const requestJson = {
        filterDetails: {},
      };
      const response: any = await questionnaireOptions.ContainerMasterFetch(
        requestJson
      );
      const responseData = response.data.magicContainerLists;
      if (response.success) {
        setContainerMaster(responseData || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchQuestionProcessData();
    fetchProcessData();
    fetchBindingDetails();
    fetchContainerMaster();
  }, [refresh]);

  const refreshScreen = () => {
    fetchQuestionProcessData();
    fetchProcessData();
    fetchBindingDetails();
    fetchContainerMaster();
  };

  const [currentProcess, setCurrentProcess] = useState("screencollection");
  const [selectedScreen, setSelectedScreen] = useState<any>({});
  const [slidersPerview] = useState(10);
  const [editScreen, setEditScreen] = useState(false);
  const [createScreen, setCreateScreen] = useState(false);
  const [multipleOptionValidation, setMultipleOptionValidation] =
    useState(false);
  const [responseContainerItems] = useState<Item[]>([]);
  const [disableReponseType, setDisableReponseType] = useState(false);
  const [showResponseSettings, setShowResponseSettings] = useState(false);
  const [showPreviewProcessModal, setShowPreviewProcessModal] = useState(false);
  const [responseSettingsType, setResponseSettingsType] = useState<any>({});
  const [showPreview, setShowPreview] = useState(false);

  const [showImportResponse, setShowImportResponse] = useState(false);
  const [fileName, setFileName] = useState("null");
  const [processData, setProcessData] = useState<any>([]);
  const [editScreenValue, setEditScreenValue] = useState<any>(null);
  const [selectedResponseItem, setSelectedResponseItem] = useState<any>(
    responseValueOptions[0]
  );
  const [selectedChoice, setSelectedChoice] = useState<any>(
    responseValueOptions[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAddBtnHide, setIsAddBtnHide] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const breadcrumbs = [
    { label: "AI Logic Studio", link: "/ai-logic-studio" },
    {
      label: "AI Sub Logic Studio",
      link: `/ai-logic-studio/process-logic/${logicId}`,
    },
    { label: "Automatic Inspection", link: undefined },
  ];

  useEffect(() => {
    const randomString = generateGuid();
    if (pathname.includes("/survey/")) {
      setCurrentProcess("screencollection");
      setProcessData([
        {
          screenId: 0,
          uniqueId: randomString,
          flowId: randomString,
          processLogicUniqueId: logicId,
          getStartedSurveyUniqueId: id,
          name: "Screen 1",
          description: "",
          mode: 2,
          isDeleted: 0,
          orderNo: 1,
          questionDetails: [],
        },
      ]);
      setSelectedScreen({
        screenId: 0,
        uniqueId: randomString,
        flowId: randomString,
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        name: "Screen 1",
        description: "",
        mode: 2,
        isDeleted: 0,
        orderNo: 1,
        questionDetails: [],
      });
    } else if (pathname.includes("/get-started/")) {
      setIsAddBtnHide(true);
      setCurrentProcess("get-started");
      setProcessData([
        {
          screenId: 0,
          uniqueId: randomString,
          flowId: "startupscreen",
          processLogicUniqueId: logicId,
          getStartedSurveyUniqueId: id,
          name: "Startup screen",
          description: "",
          mode: 1,
          isDeleted: 0,
          questionDetails: [],
        },
      ]);
      setSelectedScreen({
        screenId: 0,
        uniqueId: randomString,
        flowId: "startupscreen",
        processLogicUniqueId: logicId,
        getStartedSurveyUniqueId: id,
        name: "Startup screen",
        description: "",
        mode: 1,
        isDeleted: 0,
        questionDetails: [],
      });
    }
  }, [pathname]);

  useEffect(() => {}, [responseValueOptions]);

  const handleTabSelect = (data: any) => {
    setSelectedScreen(data);
  };

  const typeOfResponseIcons = [
    { id: 1, icon: <ResponseTenantNameIcon /> },
    { id: 2, icon: <ResponseSiteNameIcon /> },
    { id: 3, icon: <ResponsePersonNameIcon /> },
    { id: 4, icon: <ResponseLocationIcon /> },
    { id: 5, icon: <ResponseScannerIcon /> },
    { id: 6, icon: <ResponseScannerIcon /> },
    { id: 7, icon: <ResponseScannerIcon /> },
    { id: 8, icon: <ResponseScannerIcon /> },
    { id: 9, icon: <ResponseMultipleChoiceIcon /> },
    { id: 10, icon: <ResponseMultipleChoiceIcon /> },
    { id: 11, icon: <ResponseMultipleChoiceIcon /> },
    { id: 12, icon: <ResponseTextIcon /> },
    { id: 13, icon: <ResponseTextIcon /> },
    { id: 14, icon: <ResponseNumberIcon /> },
    { id: 15, icon: <ResponseDateTimeIcon /> },
    { id: 16, icon: <ResponseDateTimeIcon /> },
    { id: 17, icon: <ResponseDateTimeIcon /> },
    { id: 18, icon: <ResponseAcknowledgementIcon /> },
    { id: 19, icon: <ResponseInformationIcon /> },
    { id: 20, icon: <ResponseSignatureIcon /> },
    { id: 21, icon: <ResponseImageIcon /> },
    { id: 22, icon: <ResponseImageWithInputIcon /> },
    { id: 23, icon: <ResponseVideoIcon /> },
    { id: 24, icon: <ResponseMagicContainerIcon /> },
    { id: 25, icon: <ResponseAudioIcon /> },
    { id: 26, icon: <ResponseToggleIcon /> },
    { id: 27, icon: <ResponseDateTimeIcon /> },
    { id: 28, icon: <ResponseMultipleChoiceIcon /> },
    { id: 29, icon: <ResponseGridsIcon /> },
    { id: 30, icon: <ResponseImageWithInputIcon /> },
  ];

  const responseMultipleChoiceOptions = multipleChoiceOptions;

  const responseDateTimeOptions = dateTimeOptions;

  const responseScannerOptions = scannerOptions;

  const responseImageOptions = imageOptions;

  const responseTextOptions = textOptions;
  const [filteredResponses, setFilteredResponses] = useState(typeOfResponse);
  useEffect(() => {
    if (containerMaster.length > 0) {
      const options = containerMaster.map(
        (item: any): OptionItem => ({
          id: item.uniqueId,
          value: item.uniqueId,
          label: item.name,
        })
      );
      setResponseMagicContainerOptions(options);
    }
  }, [containerMaster]);

  const handleEditOrDelete = (id: string, item: any) => {
    if (id === "edit") {
      setEditScreenValue(item);
      setEditScreen(true);
    } else {
      if (processData.length > 1) {
        handleDeleteScreen(item);
      }
    }
  };

  const reorder = (
    list: Item[],
    startIndex: number,
    endIndex: number
  ): Item[] => {
    const newList = Array.from(list);
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);
    return newList;
  };
  const { t } = useTranslation();
  const copy = (
    source: any,
    destination: Item[],
    droppableSource: any,
    droppableDestination: any
  ): Item[] => {
    const item = source[droppableSource.index - 1];

    const randomString = generateGuid();
    const newItem = {
      ...item,
      id: randomString,
      uniqueId: randomString,
      questionDetailId: generateGuid(),
      questionId: 0,
      questionType: item.id,
      questionTypeId: item.id,
      title: "Question",
      inputType: item.id,
      subType: item.subType,
      optionGroupId: 0,
      isGroupMasterAvailable: 0,
      inputOptions: [],
      convertCase: "0",
      isMandatory: false,
      isEvidence: false,
      isNotes: false,
      isDeleted: 0,
      isApiRequired: 0,
      isBindparameter: 0,
      isMasterTable: 0,
      serviceAccessKey: "",
      masterAccessKey: "",
      magicContainerId: null,
      serviceBindValues: [],
      masterBindValues: [],
      orderNo: 1,
      type: item.type,
    };
    const newDestination = Array.from(destination);
    newDestination.splice(droppableDestination.index, 0, newItem);
    return newDestination;
  };

  const makeAnCopy = (
    destination: Item[],
    item: any,
    droppableDestination: any
  ): Item[] => {
    const randomString = generateGuid();
    const newItem = {
      ...item,
      id: randomString,
      uniqueId: randomString,
      questionDetailId: generateGuid(),
      questionId: 0,
      questionType: item.id,
      questionTypeId: item.id,
      title: item.title,
      inputType: item.inputType,
      subType: item.subType,
      inputOptions: [],
      convertCase: "0",
      isBindparameter: 0,
      orderNo: 1,
      type: item.type,
    };
    const newDestination = Array.from(destination);
    newDestination.splice(droppableDestination + 1, 0, newItem);
    return newDestination;
  };

  const onDragStart = () => {
    setDisableReponseType(true);
  };

  const updateSubProcess = () => {
    // if (selectedScreen.flowId === "getstarted") {
    //   const getStartedScreen = processData.find(
    //     (item: any) => item.flowId === "getstarted"
    //   );
    //   if (getStartedScreen) {
    //     const screenDetailsToAdd = getStartedScreen.questionDetails;
    //     const updatedProcessData = processData.map((item: any) => {
    //       if (item.uniqueId === "subprocess") {
    //         return {
    //           ...item,
    //           questionDetails: screenDetailsToAdd,
    //         };
    //       }
    //       return item;
    //     });
    //     setProcessData(updatedProcessData);
    //   }
    // }
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;
      if (!destination) {
        setDisableReponseType(false);
        return;
      }
      switch (source.droppableId) {
        case destination.droppableId:
          const itemIndex = processData.findIndex((item: any) => {
            return item.uniqueId === selectedScreen?.uniqueId;
          });
          if (itemIndex !== -1) {
            const updatedProcessData = [...processData];
            const copiedVal = reorder(
              updatedProcessData[itemIndex].questionDetails,
              source.index,
              destination.index
            );
            updatedProcessData[itemIndex].questionDetails = copiedVal;
            const updatedScreenData = addOrderNoToQuestionDetails(
              updatedProcessData[itemIndex]
            );
            updatedProcessData[itemIndex] = updatedScreenData;

            setProcessData(updatedProcessData);
            setTimeout(() => {
              // updateSubProcess();
            }, 300);
          }
          setDisableReponseType(false);
          break;
        case "RESPONSEITEMS":
          const index = processData.findIndex((item: any) => {
            return item.uniqueId === selectedScreen?.uniqueId;
          });
          if (index !== -1) {
            const updatedProcessData = [...processData];
            const copiedVal = copy(
              typeOfResponse,
              updatedProcessData[index].questionDetails,
              source,
              destination
            );
            updatedProcessData[index].questionDetails = copiedVal;
            const updatedScreenData = addOrderNoToQuestionDetails(
              updatedProcessData[index]
            );
            updatedProcessData[index] = updatedScreenData;

            setProcessData(updatedProcessData);
            // updateSubProcess();
          }
          setDisableReponseType(false);
          break;
        default:
          setDisableReponseType(false);
          break;
      }
    },
    [selectedScreen]
  );

  const openResponseSettings = (item: any) => {
    console.log("item", item);
    setShowResponseSettings(true);
    setMultipleOptionValidation(false);
    setResponseSettingsType(item);
    const selectedGroup =
    item.optionGroupId !== null &&
    item.optionGroupId !== undefined &&
    item.optionGroupId !== 0
      ? responseValueOptions.find(
          (group: any) => group.groupId === item.optionGroupId.toString()
        )
      : null;

    selectedResponseValue(selectedGroup || []);
  };

  const handleMakeAnCopy = (item: Item, index: number) => {
    const itemIndex = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen?.uniqueId;
    });
    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const copiedVal = makeAnCopy(
        updatedProcessData[itemIndex].questionDetails,
        item,
        index
      );
      updatedProcessData[itemIndex].questionDetails = copiedVal;
      const updatedScreenData = addOrderNoToQuestionDetails(
        updatedProcessData[itemIndex]
      );
      updatedProcessData[itemIndex] = updatedScreenData;
      setProcessData(updatedProcessData);
      updateSubProcess();
    }
  };

  const handleDelete = (item: any, index: number) => {
    const screenIndex = processData.findIndex((itemIndex: any) => {
      return itemIndex.uniqueId === selectedScreen.uniqueId;
    });
    if (screenIndex !== -1) {
      const updatedScreen = [...processData];
      updatedScreen[screenIndex].questionDetails.splice(index, 1);
      const updatedScreenData = addOrderNoToQuestionDetails(
        updatedScreen[screenIndex]
      );
      updatedScreen[screenIndex] = updatedScreenData;
      setProcessData(updatedScreen);
      updateSubProcess();
    }
  };

  const handleImport = (file: File) => {
    setShowImportResponse(true);
    setFileName(file?.name);
  };

  const handleCreateNewScreen = (data: any) => {
    const newScreen = {
      screenId: 0,
      uniqueId: generateGuid(),
      processLogicUniqueId: logicId,
      getStartedSurveyUniqueId: id,
      name: data.screenName,
      description: data.screenDescription,
      mode: pathname.includes("/survey/") ? "2" : "1",
      orderNo: processData.length + 1,
      isDeleted: 0,
      questionDetails: [],
    };
    setProcessData((prevData: any) => [...prevData, newScreen]);
    setCreateScreen(false);
  };

  const handleEditScreen = (newData: any) => {
    if (editScreenValue) {
      const itemIndex = processData.findIndex((item: any) => {
        return item.uniqueId === selectedScreen?.uniqueId;
      });
      if (itemIndex !== -1) {
        const updatedProcessData = [...processData];
        updatedProcessData[itemIndex].uniqueId = newData.uniqueId
          .trim()
          .replace(/\s/g, "");
        updatedProcessData[itemIndex].name = newData.screenName;
        updatedProcessData[itemIndex].description = newData.screenDescription;
        setProcessData(updatedProcessData);
      }
      setEditScreen(false);
    }
  };

  const handleDeleteScreen = (item: any) => {
    const deleteScreen = processData.filter(
      (data: any) => data.uniqueId !== item.uniqueId
    );
    setProcessData(deleteScreen);
    if (deleteScreen.length === 0) {
      setSelectedScreen(processData[1]);
    } else {
      setSelectedScreen(processData[0]);
    }
  };

  const updateResponseSettings = (data: any, type: any) => {
    const itemIndex = processData.findIndex((item: any) => {
      return item.uniqueId === selectedScreen?.uniqueId;
    });

    if (itemIndex !== -1) {
      const updatedProcessData = [...processData];
      const updatedItem = { ...processData[itemIndex] };
      const screenDetailIndex = updatedItem.questionDetails.findIndex(
        (item: any) => {
          return item.id === type.id;
        }
      );
      const updatedScreenDetail = {
        ...updatedItem.questionDetails[screenDetailIndex],
      };
      updatedScreenDetail.title = data.questionName;
      updatedScreenDetail.isEvidence = data.isEvidence;
      updatedScreenDetail.isMandatory = data.isMandatory;
      updatedScreenDetail.isNotes = data.notes;
      if (data?.responseStyle) {
        updatedScreenDetail.inputType === 24
          ? (updatedScreenDetail.magicContainerId = data.responseStyle.id)
          : (updatedScreenDetail.subType = data.responseStyle.id);
      }
      if (updatedScreenDetail.inputType === 22) {
        updatedScreenDetail.imageInputDetails = data.imageInputDetails;
      }
      updatedScreenDetail.isApiRequired = data?.isApiRequiredKey ? 1 : 0;

      if (data?.apiRequired) {
        if (updatedScreenDetail.isApiRequired === 0) {
          updatedScreenDetail.serviceAccessKey = "";
          updatedScreenDetail.serviceBindValues = [];
        } else {
          updatedScreenDetail.serviceAccessKey = data.apiRequired.id;
          const transformed = data.serviceBindParameter.map(
            (item: any, index: any) => ({
              uniqueId: generateGuid(),
              questionId: item.serviceBindParameterTwo.id,
              key: item.serviceBindParameterOne.id,
              isDeleted: 0,
            })
          );
          updatedScreenDetail.serviceBindValues = transformed;
        }
      }
      updatedScreenDetail.isMasterTable = data?.isMasterRequiredKey ? 1 : 0;
      if (data?.masterTableOne) {
        if (updatedScreenDetail.isMasterTable === 0) {
          updatedScreenDetail.masterAccessKey = "";
          updatedScreenDetail.masterBindValues = [];
        } else {
          updatedScreenDetail.masterAccessKey = data.masterTableOne.id;
          const transformed = data.masterBindParameter.map(
            (item: any, index: any) => ({
              uniqueId: generateGuid(),
              questionId: item.masterBindParameterTwo.id,
              key: item.masterBindParameterOne.id,
              isDeleted: 0,
            })
          );
          updatedScreenDetail.masterBindValues = transformed;
        }
      }

      if (
        data?.responseStyle?.value === "radioButton" ||
        data?.responseStyle?.value === "multipleChoice" ||
        data?.responseStyle?.value === "dropDown" ||
        data?.responseStyle?.value === "dropdownMultiSelect"
      ) {
        if (data?.selectedMultiOptionChoice === undefined) {
          setMultipleOptionValidation(true);
          return;
        } else {
          setMultipleOptionValidation(false);
        }
      }

      updatedScreenDetail.optionGroupId = data?.selectedMultiOptionChoice
        ? data?.selectedMultiOptionChoice
        : 0;
      updatedScreenDetail.isGroupMasterAvailable =
        data?.selectedMultiOptionChoice ? 1 : 0;
      updatedScreenDetail.gridValues = data?.gridValues
        ? data?.gridValues
        : null;

      updatedItem.questionDetails[screenDetailIndex] = updatedScreenDetail;
      updatedProcessData[itemIndex] = updatedItem;
      setProcessData(updatedProcessData);
      setShowResponseSettings(false);
    }
  };

  useEffect(() => {
    if (processData.length > 0) {
      const screenData = processData.find((data: any, i: any) => {
        return data.uniqueId === selectedScreen?.uniqueId;
      });
      if (screenData && pathname.includes("/get-started/")) {
        screenData.flowId = "getstarted";
      }
      setSelectedScreen(screenData);
    }
  }, [processData]);

  const addOrderNoToQuestionDetails = (screenData: any) => {
    const updatedQuestionDetails = screenData.questionDetails.map(
      (question: any, index: number) => {
        return { ...question, orderNo: index + 1 };
      }
    );
    return { ...screenData, questionDetails: updatedQuestionDetails };
  };

  const selectedResponseValue = (selectedItem: any) => {
    setSelectedChoice(selectedItem);
    setSelectedResponseItem(selectedItem);
    setMultipleOptionValidation(false);
  };

  const handleSaveResponse = (data: any) => {
    updateSubProcess();
  };

  const passUpdatedResponseval = (data: any) => {
    setResponseValueOptions(data);
  };
  const requestDetails = (screenDetails: any) => ({
    screenDetails,
  });
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const requestJson = requestDetails(processData);
      const response: any = await questionServices.questionAction(requestJson);
      if (response.success) {
        handleSuccess(t("web.logic.label.question"), response.data);
        setIsLoading(false);
        setSubmitLoading(false);
      } else {
        setIsLoading(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSubmitLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCloseResponseSettings = () => {
    setShowResponseSettings(false);
    setResponseSettingsType({});
  };

  const handleSearch = (query: any) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = typeOfResponse.filter(
      (item) =>
        item.displayText.toLowerCase().includes(lowercasedQuery) ||
        item.token.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredResponses(filtered);
  };

  return (
    <>
      <PageHeader
        title={`${genericDetails?.name}`}
        breadcrumbs={breadcrumbs}
        hasSaveBtn={true}
        hasEditBtn={false}
        handleEdit={() => navigate("edit")}
        handleSaveResponse={handleSubmit}
        isLoading={submitLoading}
        isSkeletonLoading={isSkeletonLoading}
        hasToggleButton={true}
        getToggleIsChecked={getToggleIsChecked}
        currentTheme={currentTheme}
      />
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-col">
          <ScreenListHeader
            screensList={processData}
            selectedScreen={selectedScreen}
            addNewScreen={() => setCreateScreen(true)}
            refreshScreen={refreshScreen}
            showPreview={() => setShowPreview(true)}
            setShowPreviewProcessModal={() => setShowPreviewProcessModal(true)}
            hideAddBtn={isAddBtnHide}
          />
          <TabSlider
            sliderData={processData}
            handleTabSelect={handleTabSelect}
            selectedTab={selectedScreen?.uniqueId}
            selectedScreen={selectedScreen}
            slidersPerview={slidersPerview}
            hasMoreOption={true}
            selectedValue={handleEditOrDelete}
            isContainer={false}
          />
          <div className="w-full flex-1 mt-[3.5rem]">
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <div className="w-full h-full flex justify-between gap-[5rem] pb-[2rem] max-md:gap-[2rem]">
                <div className="w-[32rem] h-full max-md:w-[25rem]">
                  <div className="w-full h-full flex flex-col rounded-[1.5rem] bg-io-white dark:bg-[#1A1C28] border border-io-gray-df dark:border-io-black-48 pb-[1.4rem]">
                    <div className="w-full h-auto px-[2rem] py-[1.4rem] border-b border-io-gray-df dark:border-io-black-48">
                      <p className="text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white leading-none">
                        {t("web.process.label.typeofresponse")}
                      </p>
                    </div>
                    <div className="w-full h-auto px-[2rem] pt-[2rem] pb-[1.4rem]">
                      <VoiceSearchInput
                        inputSize="small"
                        onSearch={handleSearch}
                      />
                    </div>
                    <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium px-[2rem]">
                      <ReponseTypes
                        items={filteredResponses}
                        disableReponseType={disableReponseType}
                        typeOfResponseIcons={typeOfResponseIcons}
                        currentProcess={currentProcess}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 h-full relative">
                  {selectedScreen?.questionDetails?.length !== 0 && (
                    <p className="text-[1.2rem] font-inter font-normal italic text-io-gray-66 dark:text-io-black-d1 absolute left-0 bottom-full mb-1">
                      {t("web.process.label.drag&drop")}
                    </p>
                  )}
                  <div className="w-full h-full flex justify-between">
                    <div
                      className={`w-full h-full flex flex-col relative rounded-[1.5rem] overflow-hidden border border-io-gray-df dark:border-io-black-48`}
                    >
                      {isLoading ? (
                        <div className="w-full h-full absolute top-0 left-0 z-[1] flex-auto basis-0 overflow-y-auto scrollbar-medium">
                          {Array.from({ length: 10 }).map(
                            (row: any, k: number) => (
                              <Fragment key={k}>
                                <Skeleton
                                  width="100%"
                                  height={100}
                                  borderRadius={15}
                                />
                              </Fragment>
                            )
                          )}
                        </div>
                      ) : (
                        selectedScreen?.questionDetails?.length === 0 && (
                          <div className="w-full h-full absolute top-0 left-0 z-[1]  p-[3rem]">
                            <div className="w-full h-full border-2 border-dashed border-io-gray-df dark:border-io-black-48 rounded-[0.7rem] flex justify-center items-center">
                              <div className="w-auto h-auto">
                                <div className="w-[15rem] h-auto mb-10 mx-auto">
                                  <HandIcon />
                                </div>
                                <p className="text-[1.8rem] font-inter font-normal text-[#B8B8B8] w-[60%] text-center mx-auto select-none">
                                  {t("web.process.label.drag&drop")}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium">
                        <ReponseTypesContainer
                          items={selectedScreen?.questionDetails}
                          openResponseSettings={openResponseSettings}
                          handleMakeAnCopy={handleMakeAnCopy}
                          handleDelete={handleDelete}
                          selectedScreen={selectedScreen}
                          responseMultipleChoiceOptions={
                            responseMultipleChoiceOptions
                          }
                          responseDateTimeOptions={responseDateTimeOptions}
                          responseScannerOptions={responseScannerOptions}
                          responseImageOptions={responseImageOptions}
                          responseTextOptions={responseTextOptions}
                          responseSettingsType={responseSettingsType}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[32rem] h-full block max-md:hidden">
                  {showPreview && (
                    <ProcessPreview
                      processData={processData}
                      questionDetails={selectedScreen?.questionDetails}
                      handleExpandPreview={() =>
                        setShowPreviewProcessModal(true)
                      }
                      handleHidePreview={() => setShowPreview(false)}
                    />
                  )}
                </div>
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
      {editScreen && (
        <EditScreenWrapper
          handleClose={() => setEditScreen(false)}
          editScreenValue={editScreenValue}
          handleEditScreen={handleEditScreen}
        />
      )}
      {createScreen && (
        <CreateScreenWrapper
          handleClose={() => setCreateScreen(false)}
          handleCreateNewScreen={handleCreateNewScreen}
        />
      )}
      {showResponseSettings && (
        <ResponseSettings
          selectedScreen={selectedScreen}
          handleClose={handleCloseResponseSettings}
          responseSettingsType={responseSettingsType}
          handleImport={handleImport}
          handleReponseSettingsSubmit={updateResponseSettings}
          selectedResponseValue={selectedResponseValue}
          responseValueOptions={responseValueOptions}
          selectedResponseItem={selectedResponseItem}
          selectedChoice={selectedChoice}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
          responseDateTimeOptions={responseDateTimeOptions}
          responseScannerOptions={responseScannerOptions}
          responseImageOptions={responseImageOptions}
          responseTextOptions={responseTextOptions}
          responseMagicContainerOptions={responseMagicContainerOptions}
          handleSaveResponse={(data: any) => handleSaveResponse(data)}
          passUpdatedResponseval={passUpdatedResponseval}
          bindingoptions={bindingoptions}
          multipleOptionValidation={multipleOptionValidation}
        />
      )}
      {/* {showPreviewProcessModal && (
        <ProcessPreviewModal
          processData={processData}
          questionDetails={selectedScreen.questionDetails}
          responseContainerItems={responseContainerItems}
          handleCancel={() => setShowPreviewProcessModal(false)}
        />
      )} */}
      {showImportResponse && (
        <ImportResponse
          handleCancel={() => setShowImportResponse(false)}
          fileName={fileName}
        />
      )}
    </>
  );
}

const ReponseTypes: React.FC<ReponseTypesProps> = ({
  items,
  disableReponseType,
  typeOfResponseIcons,
  currentProcess,
}) => {
  const filteredItems = items.filter((response: any) => {
    if (currentProcess === "get-started") {
      return response.isDefault === 1;
    }
    return true;
  });

  const groupedResponses = filteredItems.reduce(
    (groups: any, response: any) => {
      const { groupId, subType } = response;
      if (!groups[groupId]) {
        groups[groupId] = [];
      }
      if (subType === 0) {
        groups[groupId].push(response);
      }
      return groups;
    },
    {}
  );
  const { t } = useTranslation();
  const hasItems = Object.keys(groupedResponses).length > 0;
  return (
    <Droppable droppableId="RESPONSEITEMS" isDropDisabled={true}>
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          className={`w-full h-auto ${
            disableReponseType && "pointer-events-none"
          }`}
        >
          {hasItems ? (
            Object.keys(groupedResponses).map((groupId: any, index: any) => (
              <Fragment key={groupId}>
                {groupedResponses[groupId].map((response: any, idx: any) => {
                  const iconObj = typeOfResponseIcons.find(
                    (icon: any) => icon.id === response.id
                  );
                  const iconComponent = iconObj ? iconObj.icon : null;

                  return (
                    <Draggable
                      key={response.id}
                      draggableId={response.id.toString()}
                      index={response.id}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`w-full h-auto group ${
                            snapshot.isDragging ? "dragging shadow-lg" : ""
                          }`}
                        >
                          <div className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white group-hover:bg-[#F4F9FD] dark:bg-transparent dark:group-hover:bg-[#182233]">
                            <div className="w-full h-auto flex justify-between items-center">
                              <div className="w-full h-auto flex justify-start items-center gap-[1.5rem]">
                                <div className="w-[1.8rem] h-auto">
                                  {iconComponent}
                                </div>
                                <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
                                  {t(response.token)}
                                </p>
                              </div>
                              <div className="w-[1.2rem] h-auto hidden group-hover:block">
                                <DragSixDotIcon />
                              </div>
                            </div>
                          </div>
                          {index !== Object.keys(groupedResponses).length - 1 &&
                            idx === groupedResponses[groupId].length - 1 && (
                              <div className="w-full h-auto border-b border-dashed border-io-gray-df dark:border-io-black-48 my-3"></div>
                            )}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
              </Fragment>
            ))
          ) : (
            <li className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white dark:bg-[#1A1C28]">
              <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none text-center">
                No data found
              </p>
            </li>
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

const ReponseTypesContainer: React.FC<ReponseTypesContainerProps> = ({
  items,
  openResponseSettings,
  handleMakeAnCopy,
  handleDelete,
  selectedScreen,
  responseMultipleChoiceOptions,
  responseDateTimeOptions,
  responseScannerOptions,
  responseImageOptions,
  responseTextOptions,
  responseSettingsType,
}) => {
  return (
    <Droppable droppableId="RESPONSECONTAINER">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className="w-full h-full overflow-y-auto scrollbar-medium z-[2]"
        >
          {items?.map((item: any, index: any) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`w-full h-auto group ${
                    snapshot.isDragging ? "dragging" : ""
                  }`}
                >
                  <QuestionCard
                    item={item}
                    items={items}
                    index={index}
                    openResponseSettings={(item: any) =>
                      openResponseSettings(item)
                    }
                    handleMakeAnCopy={handleMakeAnCopy}
                    handleDelete={handleDelete}
                    snapshot={snapshot}
                    responseMultipleChoiceOptions={
                      responseMultipleChoiceOptions
                    }
                    responseDateTimeOptions={responseDateTimeOptions}
                    responseScannerOptions={responseScannerOptions}
                    responseImageOptions={responseImageOptions}
                    responseTextOptions={responseTextOptions}
                    responseSettingsType={responseSettingsType}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
//#region Alert
const { ToastifyHandle } = ToastifyMessage();
const handleSuccess = (titleName: any, message: any) => {
  ToastifyHandle(
    <ToastSuccess title={titleName} description={message} />,
    "success"
  );
};

//#endregion
export default ProcessScreens;
