import { TextBtn } from "../text-btn";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CalendarIcon } from "../../svg";
import { SelectInputWithoutForm } from "../select-input";
import { processServices } from "../../../services/InspectionOneServices";
import Skeleton from "react-loading-skeleton";

function GridFilterOptions({
  handleClose,
  siteConfigDetails,
  resetFilter,
  processFilterState,
  setProcessFilterState,
}: any) {
  const { t } = useTranslation();
  const [logicAndSubProcess, setLogicAndSubProcess] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [RangeDateValue, setRangeDateValue] = useState<
    [Date, Date] | undefined
  >(undefined);
  const [filterState, setFilterState] = useState(processFilterState);
  useEffect(() => {}, [processFilterState]);
  useEffect(() => {
    if (processFilterState.dateRange) {
      const { startDate, endDate } = processFilterState.dateRange;
      setRangeDateValue([new Date(startDate), new Date(endDate)]);
    }
    fetchProcessData();
  }, [processFilterState]);
  const filterSelectOptions =
    siteConfigDetails?.shiftsDetails?.map((shift: any) => ({
      label: shift.shiftType,
      value: shift.uniqueId,
    })) || [];

  const fetchProcessData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const requestJson = { processLogicId: "" };
        const response: any = await processServices.GetLogicAndSubProcess(
          requestJson
        );
        if (response.success) {
          setLogicAndSubProcess(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateFilterState = (key: string, value: any) => {
    setFilterState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleDateChange = (newRange: any) => {
    setRangeDateValue(newRange);
  
    if (newRange && newRange.length >= 1) {
      let [startDate, endDate] = newRange;
  
      // Ensure startDate and endDate are valid Date objects
      startDate = new Date(startDate);
      endDate = endDate ? new Date(endDate) : startDate;
  
      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        // Convert to local time
        const localStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
        const localEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
  
        updateFilterState("dateRange", {
          startDate: localStartDate.toISOString(),
          endDate: localEndDate.toISOString(),
        });
      } else {
        console.error("Invalid date range selected");
        updateFilterState("dateRange", { startDate: "", endDate: "" });
      }
    } else {
      updateFilterState("dateRange", { startDate: "", endDate: "" });
    }
  };
  

  const handleShiftChange = (selectedUniqueId: any) => {
    if (selectedUniqueId?.value) {
      const selectedShift = siteConfigDetails.shiftsDetails.find(
        (shift: any) => shift.uniqueId === selectedUniqueId.value
      );
      if (selectedShift) {
        const { startTime, endTime } = selectedShift;
        updateFilterState("shift", {
          startTime,
          endTime,
          uniqueId: selectedUniqueId.value,
        });
      }
    }
  };

  const handleProcessInputChange = (name: string, value: any) => {
    updateFilterState(name, value);

    if (name === "processLogicId") {
      updateFilterState("subProcessLogicId", "");
    }
  };

  const handleFormSubmit = () => {
    debugger;
    const updatedFilterState = {
      ...filterState,
      refresh: !filterState.refresh,
    };

    console.log("Final Filter State:", updatedFilterState);
    setFilterState(updatedFilterState); // Update local state
    setProcessFilterState(updatedFilterState); // Update parent state
    handleClose(); // Close the modal or popup
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <div className="relative">
      <div className="w-[35rem] px-[2rem] py-[2rem] bg-io-white dark:bg-io-black-1a shadow-xl rounded-[0.6rem] ">
        {siteConfigDetails?.isProcessLogic === 1 && (
          <>
            <div className="w-full h-auto mb-10">
              {isLoading ? (
                <Skeleton width="100%" height={47} borderRadius={3} />
              ) : (
                <SelectInputWithoutForm
                  labelName="Process Logic"
                  id="processLogic"
                  isRequired={false}
                  options={logicAndSubProcess.map((logic: any) => ({
                    label: logic.name,
                    id: logic.id,
                  }))}
                  defaultValue={
                    logicAndSubProcess.find(
                      (logic: any) => logic.id === filterState.processLogicId
                    ) || null
                  }
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.id}
                  getSelection={(option: any) =>
                    handleProcessInputChange("processLogicId", option.id)
                  }
                />
              )}
            </div>
            <div className="w-full h-auto mb-10">
              {isLoading ? (
                <Skeleton width="100%" height={47} borderRadius={3} />
              ) : (
                <SelectInputWithoutForm
                  key={filterState.processLogicId}
                  labelName="Sub Process Logic"
                  id="subProcessLogic"
                  isRequired={false}
                  options={
                    logicAndSubProcess
                      .find(
                        (logic: any) => logic.id === filterState.processLogicId
                      )
                      ?.subProcessLogicList.map((subLogic: any) => ({
                        label: subLogic.name,
                        id: subLogic.id,
                      })) || []
                  }
                  defaultValue={
                    filterState.subProcessLogicId
                      ? {
                          label: logicAndSubProcess
                            .find(
                              (logic: any) =>
                                logic.id === filterState.processLogicId
                            )
                            ?.subProcessLogicList.find(
                              (subLogic: any) =>
                                subLogic.id === filterState.subProcessLogicId
                            )?.name,
                          id: filterState.subProcessLogicId,
                        }
                      : null
                  }
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.id}
                  getSelection={(option: any) =>
                    handleProcessInputChange("subProcessLogicId", option.id)
                  }
                />
              )}
            </div>{" "}
          </>
        )}
        <div className="w-full h-auto mb-10 relative">
          <DatePicker
            value={RangeDateValue}
            onChange={handleDateChange}
            range
            rangeHover
            dateSeparator=" to "
            portal
            inputClass="cus-date-range"
            format="YYYY-MM-DD HH:mm"
            placeholder="Select Date Range"
            plugins={[
              <TimePicker position="bottom" />
            ]}
          />
          {/* <div className="w-[2rem] h-auto absolute top-[50%] translate-y-[-50%] right-[5%] pointer-events-none">
            <CalendarIcon />
          </div> */}
        </div>
        {siteConfigDetails?.isShiftEnabled === 1 && (
          <div className="w-full h-auto mb-10">
            <SelectInputWithoutForm
              labelName={t("web.filter.label.shift")}
              id="getStartedUniqueId"
              isRequired={false}
              options={filterSelectOptions}
              defaultValue={
                filterSelectOptions.find(
                  (option: any) => option.value == filterState.shift.uniqueId
                ) || null
              }
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              getSelection={(selectedUniqueId: any) =>
                handleShiftChange(selectedUniqueId)
              }
            />
          </div>
        )}

        <div className="w-full h-auto flex justify-end items-center gap-4">
          <TextBtn
            label={t("web.common.button.cancel")}
            btnSize="small"
            btnStyle="fill-secondary"
            btnType="button"
            handleClick={handleCancel}
          />
          <TextBtn
            label={t("web.common.button.submit")}
            btnSize="small"
            btnStyle="fill-primary"
            btnType="submit"
            handleClick={handleFormSubmit}
          />
        </div>
      </div>
      <div className="absolute top-[-2rem] right-4 w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent shadow-2xl"></div>
    </div>
  );
}

export default GridFilterOptions;
