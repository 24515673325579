import { useEffect, useState } from "react";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import { useTranslation } from "react-i18next";

interface GridColumnProps {
  columnsData: Column[];
  handleClose: () => void;
  handleCheckboxChange: (event: any, key: string) => void;
  selectedColumns: string[];
}
interface Column {
  label: string;
  token: string;
  key: string;
}

function GridColumn({
  columnsData,
  handleClose,
  handleCheckboxChange,
  selectedColumns,
}: GridColumnProps) {
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.toolbar.label.columns")}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto overflow-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              <div className="w-full h-auto">
                {columnsData
                  .filter((item: any) => item.isView === 1)
                  .map((item, i) => (
                    <div
                      key={i}
                      className="w-full h-auto flex justify-start items-center gap-4 mb-6"
                    >
                      <input
                        type="checkbox"
                        name={item.key}
                        id={item.key}
                        className="accent-io-primary"
                        checked={selectedColumns.includes(item.key)}
                        onChange={(event) =>
                          handleCheckboxChange(event, item.key)
                        }
                      />
                      <label
                        htmlFor={item.key}
                        className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1"
                      >
                        {t(item.token)}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}

export default GridColumn;
