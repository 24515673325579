import Tippy from "@tippyjs/react";
import VoiceSearchInput from "../../../form/voice-search-input";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import {
  ResponseAcknowledgementIcon,
  ResponseAudioIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseImageWithInputIcon,
  ResponseInformationIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseMultipleChoiceIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSignatureIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  ResponseGridsIcon,
} from "../../../svg";
import { typeOfResponse } from "../../../../services/questionConfig";
interface SelectTypeOfResponseProps {
  tippyRef: any;
  visible: boolean;
  // show: () => void;
  hide: () => void;
  children: any;
  handleChangeTypeOfResponse: () => void;
  currentProcess: string;
}

interface ReponseTypesProps {
  items: any;
  typeOfResponseIcons: any;
  currentProcess: any;
  handleChangeTypeOfResponse: (data: any) => void;
  hide: () => void;
}

function SelectTypeOfResponse({
  tippyRef,
  visible,
  hide,
  children,
  handleChangeTypeOfResponse,
  currentProcess,
}: SelectTypeOfResponseProps) {
  const [filteredResponses, setFilteredResponses] = useState(typeOfResponse);
  const typeOfResponseIcons = [
    { id: 1, icon: <ResponseTenantNameIcon /> },
    { id: 2, icon: <ResponseSiteNameIcon /> },
    { id: 3, icon: <ResponsePersonNameIcon /> },
    { id: 4, icon: <ResponseLocationIcon /> },
    { id: 5, icon: <ResponseScannerIcon /> },
    { id: 6, icon: <ResponseScannerIcon /> },
    { id: 7, icon: <ResponseScannerIcon /> },
    { id: 8, icon: <ResponseScannerIcon /> },
    { id: 9, icon: <ResponseMultipleChoiceIcon /> },
    { id: 10, icon: <ResponseMultipleChoiceIcon /> },
    { id: 11, icon: <ResponseMultipleChoiceIcon /> },
    { id: 12, icon: <ResponseTextIcon /> },
    { id: 13, icon: <ResponseTextIcon /> },
    { id: 14, icon: <ResponseNumberIcon /> },
    { id: 15, icon: <ResponseDateTimeIcon /> },
    { id: 16, icon: <ResponseDateTimeIcon /> },
    { id: 17, icon: <ResponseDateTimeIcon /> },
    { id: 18, icon: <ResponseAcknowledgementIcon /> },
    { id: 19, icon: <ResponseInformationIcon /> },
    { id: 20, icon: <ResponseSignatureIcon /> },
    { id: 21, icon: <ResponseImageIcon /> },
    { id: 22, icon: <ResponseImageWithInputIcon /> },
    { id: 23, icon: <ResponseVideoIcon /> },
    { id: 24, icon: <ResponseMagicContainerIcon /> },
    { id: 25, icon: <ResponseAudioIcon /> },
    { id: 26, icon: <ResponseToggleIcon /> },
    { id: 27, icon: <ResponseDateTimeIcon /> },
    { id: 28, icon: <ResponseMultipleChoiceIcon /> },
    { id: 29, icon: <ResponseMultipleChoiceIcon /> },
    { id: 29, icon: <ResponseMultipleChoiceIcon /> },
    { id: 30, icon: <ResponseImageWithInputIcon /> },
  ];

  const handleSearch = (query: any) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = typeOfResponse.filter(
      (item) =>
        item.displayText.toLowerCase().includes(lowercasedQuery) ||
        item.token.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredResponses(filtered);
  };

  const dropDownContent = (
    <div className="w-[40rem] h-[35vh] ">
      <div className="w-full h-full flex flex-col rounded-[1.5rem] bg-io-white dark:bg-[#1A1C28] border border-io-gray-df dark:border-io-black-48 pb-[1.4rem]">
        <div className="w-full h-auto px-[2rem] pt-[2rem] pb-[1.4rem]">
          <VoiceSearchInput inputSize="small" onSearch={handleSearch} />
        </div>
        <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium px-[2rem]">
          <ReponseTypes
            items={filteredResponses}
            typeOfResponseIcons={typeOfResponseIcons}
            currentProcess={currentProcess}
            handleChangeTypeOfResponse={handleChangeTypeOfResponse}
            hide={hide}
          />
        </div>
      </div>
    </div>
  );
  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom"
      maxWidth={"500px"}
      delay={0}
    >
      <div className="w-full">{children}</div>
    </Tippy>
  );
}
const ReponseTypes: React.FC<ReponseTypesProps> = ({
  items,
  typeOfResponseIcons,
  currentProcess,
  handleChangeTypeOfResponse,
  hide,
}) => {
  const filteredItems = items.filter((response: any) => {
    if (currentProcess === "get-started") {
      return response.isDefault === 1;
    }
    return true;
  });

  const groupedResponses = filteredItems.reduce(
    (groups: any, response: any) => {
      const { groupId, subType } = response;
      if (!groups[groupId]) {
        groups[groupId] = [];
      }
      if (subType === 0) {
        groups[groupId].push(response);
      }
      return groups;
    },
    {}
  );
  const { t } = useTranslation();

  const hasItems = Object.keys(groupedResponses).length > 0;
  return (
    <ul className="w-full h-auto">
      {hasItems ? (
        Object.keys(groupedResponses).map((groupId: any, index: any) => (
          <Fragment key={groupId}>
            {groupedResponses[groupId].map((response: any, idx: any) => {
              const iconObj = typeOfResponseIcons.find(
                (icon: any) => icon.id === response.id
              );
              const iconComponent = iconObj ? iconObj.icon : null;
              return (
                <li
                  key={idx}
                  className="w-full h-auto group"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeTypeOfResponse(response);
                    hide();
                  }}
                >
                  <div className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white group-hover:bg-[#F4F9FD] dark:bg-transparent dark:group-hover:bg-[#182233]">
                    <div className="w-full h-auto flex justify-between items-center ">
                      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem]">
                        <div className="w-[1.8rem] h-auto">{iconComponent}</div>
                        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
                          {t(response.token)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {index !== Object.keys(groupedResponses).length - 1 &&
                    idx === groupedResponses[groupId].length - 1 && (
                      <div className="w-full h-auto border-b border-dashed border-io-gray-df dark:border-io-black-48 my-3"></div>
                    )}
                </li>
              );
            })}
          </Fragment>
        ))
      ) : (
        <li className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white dark:bg-[#1A1C28]">
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none text-center">
            No data found
          </p>
        </li>
      )}
    </ul>
  );
};
export default SelectTypeOfResponse;
