import { useState, useEffect } from "react";

interface Option {
    id: string;
    label: string;
}
interface RadioBtnsProps {
    options: Option[];
    name: string;
    formHandle?: any;
    onOptionChange?: (selectedOption: Option) => void;
    error?: string;
}
interface RadioBtnsPropsWithoutForm {
    options: Option[];
    name: string;
    error?: any;
    defaultValue?: Option; // Add defaultValue to props
    onOptionChange?: (selectedOption: Option) => void;
  }
function RadioBtns({ options, name, formHandle, onOptionChange, error }: RadioBtnsProps) {

    return (
        <div className="w-full h-auto flex justify-start items-center gap-8 relative">
            {options.map((option, i) => (
                <div key={i} className="w-auto flex justify-start items-center gap-4">
                    <input type="radio" id={option.id} className="accent-io-primary cursor-pointer" {...formHandle} value={option.id} />
                    <label htmlFor={option.id} className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white">{option.label}</label>
                </div>
            ))}
            {error && <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">{error}</p>}
        </div>
    )
}

function RadioBtnsWithoutForm({
    options,
    name,
    defaultValue,
    error,
    onOptionChange,
  }: RadioBtnsPropsWithoutForm) {
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(
      defaultValue
    );
    useEffect(() => {
      if (defaultValue) {
        setSelectedOption(defaultValue);
      }
    }, [defaultValue]);
  
    const handleChange = (option: Option) => {
      setSelectedOption(option);
      onOptionChange?.(option);
    };
  
    return (
      <>
        <div className="w-full h-auto flex justify-start items-center gap-8 flex-wrap">
          {options.map((option, i) => (
            <div key={i} className="w-auto flex justify-start items-center gap-4">
              <input
                type="radio"
                name={name}
                id={option.id}
                className="accent-io-primary"
                checked={selectedOption?.id === option.id}
                onChange={() => handleChange(option)}
              />
              <label
                htmlFor={option.id}
                className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left  top-full left-0">
            {error}
          </p>
        )}
      </>
    );
  }
export { RadioBtns, RadioBtnsWithoutForm };