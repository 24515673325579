import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateProcessForm from "../create-process-form";

function CreateProcess({setIsInspectionModalOpen,handleRefresh, processUpdated }: any) {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={"New Inspection"}
          handleClose={() => setIsInspectionModalOpen(false)}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateProcessForm handleRefresh={handleRefresh} setIsInspectionModalOpen={setIsInspectionModalOpen} processUpdated={processUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateProcess;
