import { useTranslation } from "react-i18next";
import {
  DropDownIcon,
  ResponseAcknowledgementIcon,
  ResponseAudioIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseInformationIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseMultipleChoiceIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSignatureIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  ResponseGridsIcon,
  ResponseImageWithInputIcon,
} from "../../../svg";
import SelectTypeOfResponse from "../select-type-of-response";
import { useEffect, useState } from "react";

const ResponseTenantName = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };

  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseTenantNameIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.tenantname")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseTenantNameIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.tenantname")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseSiteName = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseSiteNameIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.sitename")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseSiteNameIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.sitename")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponsePersonName = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponsePersonNameIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.personname")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponsePersonNameIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.personname")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseLocation = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseLocationIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.location")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseLocationIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.location")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseMultipleChoice = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseMultipleChoiceIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.multiplechoice")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseMultipleChoiceIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.multiplechoice")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseText = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseTextIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.text")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseTextIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.text")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseDateTime = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseDateTimeIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.datetime")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseDateTimeIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.datetime")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseSignature = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseSignatureIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.signature")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseSignatureIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.signature")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseScanner = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseScannerIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.scanner")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseScannerIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.scanner")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseNumber = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseNumberIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.number")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseNumberIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.number")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseImage = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseImageIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.image")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseImageIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.image")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseMagicContainer = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseMagicContainerIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.magiccontainer")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseMagicContainerIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.magiccontainer")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseAcknowledgement = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseAcknowledgementIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.acknowledgment")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseAcknowledgementIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.acknowledgment")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseInformation = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseInformationIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.information")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseInformationIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.information")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseVideo = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseVideoIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.video")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseVideoIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.video")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseImageWithInputs = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseImageWithInputIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.imagewithInputs")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseImageWithInputIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.imagewithInputs")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseAudio = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseAudioIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.audio")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseAudioIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.audio")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseToggle = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseToggleIcon />
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.toggle")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseToggleIcon />
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.toggle")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseGridsName = ({
  questionData,
  tippyRef,
  isEditable,
  visible,
  hide,
  show,
  handleChangeTypeOfResponse,
  currentProcess,
}: any) => {
  const { t } = useTranslation();

  const handleSelectQuestionType = (e: any) => {
    e.stopPropagation();
    visible ? hide() : show();
  };
  
  return (
    <div
      className={`w-[40rem]  ${
        isEditable && "border border-io-primary rounded-[0.6rem]"
      }`}
    >
      {isEditable ? (
        <SelectTypeOfResponse
          tippyRef={tippyRef}
          visible={visible}
          hide={hide}
          handleChangeTypeOfResponse={handleChangeTypeOfResponse}
          currentProcess={currentProcess}
        >
          <div
            className="w-full flex justify-between items-center px-[2rem] py-[1.5rem] gap-[1.4rem]"
            onClick={(e) => handleSelectQuestionType(e)}
          >
            <div className="w-[2rem]">
              <ResponseGridsIcon/>
            </div>
            <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
              {t("web.logic.label.grids")}
            </p>
            <div className="w-[1.6rem] h-auto">
              <DropDownIcon />
            </div>
          </div>
        </SelectTypeOfResponse>
      ) : (
        <div className="w-full flex justify-between items-center gap-[1.4rem]">
          <div className="w-[2rem]">
            <ResponseGridsIcon/>
          </div>
          <p className="flex-1 text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white">
            {t("web.logic.label.grids")}
          </p>
        </div>
      )}
    </div>
  );
};

export {
  ResponseTenantName,
  ResponseSiteName,
  ResponsePersonName,
  ResponseLocation,
  ResponseMultipleChoice,
  ResponseText,
  ResponseDateTime,
  ResponseSignature,
  ResponseScanner,
  ResponseNumber,
  ResponseImage,
  ResponseMagicContainer,
  ResponseAcknowledgement,
  ResponseInformation,
  ResponseVideo,
  ResponseImageWithInputs,
  ResponseAudio,
  ResponseToggle,
  ResponseGridsName,
};
