export function formatNumberAmericanSystem(number: any) {
  if (number >= 1e6) {
      return (Math.round(number / 1e6 * 10) / 10) + 'M';
  } else if (number >= 1e3) {
      return (Math.round(number / 1e3 * 10) / 10) + 'K';
  } else {
      return Math.round(number).toString();
  }
}


  export function findPercentageWithRoundOff(amount: number, total: number) {
    let percentage =  (amount / total) * 100

    return Math.round(percentage);
  }

  export function isValidDomainName(domain: string): boolean {
    // Regular expression for a simple domain name validation
    const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)+$/;
  
    // Test if the provided domain matches the regular expression
    return domainRegex.test(domain);
  }


  export function isValidEmailAddress(email: string): boolean { 
    email= email.trim()
    const emailRegexx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegexx.test(email);
  }

  export function isPasswordCreationTypeValid(password: string): boolean {
    // Password should contain at least one symbol, one capital letter, one small letter, one number, and be at least 8 characters long
    const passwordRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  export function formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',  // 'numeric' is valid here
      month: 'short',    // 'short' is valid for abbreviated month names (e.g., Dec)
      day: '2-digit',    // '2-digit' is valid here for two-digit day
      hour: '2-digit',   // '2-digit' is valid here for two-digit hour
      minute: '2-digit', // '2-digit' is valid here for two-digit minute
      hour12: true,      // 'true' is valid for 12-hour format
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  }

  export const getDefaultFormData = (mode:any) => {
    return mode === 'insert'
      ? {
          firstName: '',
          lastName: '',
          displayName: '',
          email: '',
          phoneNumber: '',
          role: '1',
          profileImageUrl: '',
        }
      : {};
  };
  
  export const createRequestJson = (mode:any, id:any, formData:any) => {
    return {
      uniqueId: mode === 'insert' ? uuidv4() : id,
      ...formData,
      password: 'Admin@123',
      authorization_scheme: formData.roleId,
      isLoggedOnce: 0,
      mobile: formData.mobile,
      countryCode: '+91',
      address: 'No.2, Back Street, Canada',
      languageId: formData.languageId||1,
    };
  };

function uuidv4() {
  throw new Error("Function not implemented.");
}



export const flagText = (flag: any) => {
  if(flag === 1){
    return "Yes"
  }
  else{
    return "No"
  }
}
export const validatePassword = (formData:any) => {
  const errors: any = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }; 
  if (!formData.currentPassword.trim()) {
    errors.currentPassword = "Current password is required";
  }

  if (!formData.newPassword) {
    errors.newPassword = "New password is required";
  } else if (formData.currentPassword === formData.newPassword) {
    errors.newPassword = "New password should not be the same as the current password";
  } else if (formData.newPassword.length < 8) {
    errors.newPassword = "Password should contain at least one uppercase letter, one lowercase letter, one numeric character, one special character and more than 8 characters";
  } else if (!/[A-Z]/.test(formData.newPassword) || 
             !/[a-z]/.test(formData.newPassword) || 
             !/\d/.test(formData.newPassword) || 
             !/[!@#$%^&*(),.?":{}|<>]/.test(formData.newPassword)) {
    errors.newPassword = "Password should contain at least one uppercase letter, one lowercase letter, one numeric character, one special character and more than 8 characters";
  }

  if (!formData.confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  } else if (formData.newPassword !== formData.confirmPassword) {
    errors.confirmPassword = "New password and confirm password do not match";
  } 
  return errors;
};

export const msalConfig  = { 
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT}`,
    authority: `${process.env.REACT_APP_AUTHORITY}`,
    endSessionEndpoint: `${process.env.REACT_APP_END_SESSION_ENDPOINT}`,
     redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
     postLogoutRedirectUri: `${process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI}`,
  }, 
};

export const loginRequest = {
  scopes: ["User.Read"], // Permissions you want the user to consent to
};
 

export const languageDetails =  [
      {
          "id": 1,
          "short": "en",
          "value": "English",
          "localValue": "English",
          "threeShort": "eng",
          "code": "en"
      },
      {
          "id": 2,
          "short": "ph",
          "value": "Filipino",
          "localValue": "Filipino",
          "threeShort": "tgl",
          "code": "fil"
      },
      {
          "id": 3,
          "short": "fr",
          "value": "French",
          "localValue": "Français",
          "threeShort": "fre",
          "code": "fr"
      },
      {
          "id": 4,
          "short": "es",
          "value": "Spanish",
          "localValue": "Español",
          "threeShort": "spa",
          "code": "es"
      },
      {
          "id": 5,
          "short": "ru",
          "value": "Russian",
          "localValue": "???????",
          "threeShort": "rus",
          "code": "ru"
      },
      {
          "id": 6,
          "short": "ch",
          "value": "Chinese",
          "localValue": "??",
          "threeShort": "chi",
          "code": "zh"
      },
      {
          "id": 7,
          "short": "kr",
          "value": "Korean",
          "localValue": "???",
          "threeShort": "kor",
          "code": "ko"
      },
      {
          "id": 8,
          "short": "ge",
          "value": "German",
          "localValue": "Deutsch",
          "threeShort": "ger",
          "code": "de"
      },
      {
          "id": 9,
          "short": "ja",
          "value": "Japanese",
          "localValue": "???",
          "threeShort": "jap",
          "code": "ja"
      },
      {
          "id": 10,
          "short": "ta",
          "value": "Tamil",
          "localValue": "?????",
          "threeShort": "tam",
          "code": "ta"
      },
      {
          "id": 11,
          "short": "te",
          "value": "Telugu",
          "localValue": "??????",
          "threeShort": "tel",
          "code": "te"
      },
      {
          "id": 12,
          "short": "po",
          "value": "Portuguese",
          "localValue": "Portuguese",
          "threeShort": "por",
          "code": "pt"
      },
      {
          "id": 13,
          "short": "it",
          "value": "Italian",
          "localValue": "Italia",
          "threeShort": "ita",
          "code": "it"
      }
  ] 