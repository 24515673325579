import { AgGridReact } from "ag-grid-react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../user-management/user-management-grid/user-management-grid.css";
import { useNavigate, useParams } from "react-router-dom";
import GridToolBar from "../../../UI/grid-toolbar";

import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";
import {
  DeleteOptionRenderer,
  NameCellEditorRenderer,
  SelectCellEditorRenderer,
  ToggleRenderer,
} from "../../../UI/grid-cells";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { masterAccess } from "../../../../services/InspectionOneServices";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../global-layout/page-header";

function MasterDetailsDetailGrid({
  handleColumns,
  selectedColumns,
  rowSelection,
  moreOptions,
  userColumnUpdated,
  setUserColumnUpdated,
  handleDataDelete,
  setShowDeletePopup,
  handleDelete,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [finalFetch, setFinalFetch] = useState(false);
  const [limit] = useState(20);
  const [sorting, setSorting] = useState<number>(0);
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [masterColumnsData, setMasterColumnsData] = useState<any>([]);
  const [initialLoading, setIntialLoading] = useState(true);
  const chatGroundRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toolItems, setToolItems] = useState<any>([]);
  const [isGridView, setIsGridView] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [submitData, setSubmitData] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [masterTableName, setMasterTableName] = useState<string>("");
  const [isLive, setIsLive] = useState(0);

  const [resetSearch, setResetSearch] = useState<any>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);

  const [tableNameData, setTableNameData] = useState<any>([]);
  const [columnNameData, setColumnNameData] = useState<any>([]);

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "Name",
      token: "web.master.label.name",
      field: "name",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Data Type",
      token: "web.master.label.datatype",
      field: "type",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Primary Value",
      token: "web.master.label.primaryvalue",
      field: "isPrimaryValue",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Mandatory",
      token: "web.logic.label.mandatory",
      field: "isMandatory",
      width: "flex-1",
      align: "left",
    },
    // {
    //   headerName: "View Details",
    //   token: "web.common.label.viewDetails",
    //   field: "editDetails",
    //   width: "flex-1",
    //   align: "left",
    // },
  ]);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const createFormSchema = yup.object().shape({
    name: yup.string().required("Please enter the Name"),
    dataType: yup.object().required("Please Select the Data Type"),
    isPrimaryValue: yup.bool(),
    isMandatory: yup.bool(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(createFormSchema),
  });
  const dataTypeOptions = [
    { value: 1, label: "Text" },
    { value: 2, label: "Dropdown" },
    { value: 3, label: "Date & time" },
    { value: 4, label: "Multi Choice" },
    { value: 5, label: "File Upload" },
  ];

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setIsDeleteAvailble(true);
    } else {
      setIsDeleteAvailble(false);
    }
  }, []);

  const [isDeleteAvailble, setIsDeleteAvailble] = useState<any>(false);

  const { id } = useParams();

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setStartIndex(0);
      setMasterColumnsData([]);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);
  useEffect(() => {
    if (userColumnUpdated === true) {
      setMasterColumnsData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [userColumnUpdated]);

  useEffect(() => {
    masterColumnsListFetch(searchedInputVal);
    setUserColumnUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    searchedInputVal,
    isUpdated,
    isDeleted,
    isRefreshed,
  ]);

  useEffect(() => {
    if (isLive === 1) {
      setToolItems(["refresh"]);
    } else {
      setToolItems(["addrow", "refresh"]);
    }
  }, [isGridView, isLive]);

  const handleAddRow = (data: void) => {
    navigate("/master-details/detail/" + id + "/new-master-details/new");
  };
  const handleRefresh = () => {
    setMasterColumnsData([]);
    setStartIndex(0);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
    setSearchedQuery("");
    setSearchedInputVal("");
    setResetSearch(true);
  };
  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = chatGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  const handleSort = (column: any, value: number, columnName: string) => {
    setMasterColumnsData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  const masterColumnsListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        masterAccessId: id,
      };
      const response: any = await masterAccess.MasterColumnDetailsFetch(
        requestJson
      );
      const responseData = response.data.masterColumnDetails;
      if (response.success) {
        setMasterColumnsData(responseData);
        setSubmitData(responseData);
        setMasterTableName(response.data.masterColumnTableName);
        setTotalCount(response.data.masterColumnDetails.length);
        setIsLive(response.data.isLive);
        sessionStorage.setItem("dependentTableNameList", JSON.stringify(response.data.tableNameData));
        sessionStorage.setItem("dependentColumnNameList", JSON.stringify(response.data.columnNameData));
        setIsLoading(false);
        setIsSkeletonLoader(false);
        if (responseData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const currentUrl: string = window.location.href;
  const parts = currentUrl.split("/");
  const masterId = parts[5];

  const handleEditDetails = (data: any) => {
    navigate(
      "/master-details/detail/" + masterId + "/new-master-details/" + data.id
    );
  };

  const handleColumnDetailsDelete = async () => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    try {
      const requestJson = {
        actionMode: 1,
        statusId: 1,
        masterColumnDetailsAction: selectedRows,
      };
      const response = await masterAccess.MasterColumnDetailsAction(
        requestJson
      );

      if (response.success) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      } else {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };

  const handleColumnDetailsSubmit = async () => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    try {
      const requestJson = {
        actionMode: 1,
        statusId: 1,
        masterColumnDetailsAction: submitData,
      };
      const response = await masterAccess.MasterColumnDetailsAction(
        requestJson
      );
      if (response.success) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      } else {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };

  const breadcrumbs = [
    { label: "Master Details", link: "/master-details" },
    { label: masterTableName, link: undefined },
  ];

  interface Row {
    id: string;
  }

  const updateValueById = (
    targetId: any,
    updateKey: string,
    newValue: any
  ): void => {
    let updatedData;

    if (newValue === 1) {
      // If the new value is 1, set all other updateKey values to 0
      updatedData = masterColumnsData.map((obj: any) =>
        obj.id === targetId
          ? { ...obj, [updateKey]: newValue }
          : { ...obj, [updateKey]: 0 }
      );
    } else {
      // If the new value is 0, just update the target object
      updatedData = masterColumnsData.map((obj: any) =>
        obj.id === targetId ? { ...obj, [updateKey]: newValue } : obj
      );
    }
    setMasterColumnsData(updatedData);
    setSubmitData(updatedData);
  };

  const handleInputChange = async (
    id: string,
    cellkey: any,
    inputValue: any
  ) => {
    setSubmitData((prevState: Row[]) => {
      const updatedRows = prevState.map((oldRow: Row) => {
        if (oldRow.id === id) {
          return { ...oldRow, [cellkey]: inputValue };
        }
        return oldRow;
      });
      if (cellkey === "isPrimaryValue") {
        setSelectedRow(inputValue ? id : null);
        return updatedRows.map((row: Row) => {
          return { ...row, isPrimaryValue: row.id === id ? inputValue : 0 };
        });
      }
      return updatedRows;
    });

    if (cellkey === "isPrimaryValue") {
      updateValueById(id, cellkey, inputValue);
    }
  };
  const selectedMoreOption = (option: string, row: any) => {
    if (option === "edit") {
      navigate("/master-details/new-master-details/" + row.id);
    } else if (option === "delete") {
      handleDelete(row);
    }
  };

  useEffect(() => {
    if (handleDataDelete?.id) {
      handleRowDataDelete(handleDataDelete);
    }
  }, [handleDataDelete]);

  const handleRowDataDelete = async (rowData: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setShowDeletePopup(false);
    try {
      const requestJson = {
        actionMode: 3,
        statusId: 1,
        masterColumnDetailsAction: [rowData],
      };
      const response = await masterAccess.MasterColumnDetailsAction(
        requestJson
      );
      if (response.success) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
        setMasterColumnsData([]);
        setStartIndex(0);
        setIsDeleted((prev) => !prev);
      } else {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <PageHeader
        title={masterTableName}
        hasSaveBtn={isLive === 1 ? false : true}
        breadcrumbs={breadcrumbs}
        handleSaveResponse={() => handleColumnDetailsSubmit()}
      />
      <GridToolBar
        columnCount={totalCount}
        gridType={`${masterTableName} ${t(
          totalCount === 1
            ? "web.toolbar.label.column"
            : "web.toolbar.label.columns"
        )}`}
        selectView={false}
        tools={toolItems}
        //handleDelete={() => handleColumnDetailsDelete()}
        handleColumns={() => setShowColumns(true)}
        handleAddRow={handleAddRow}
        isGridView={isGridView}
        fetchData={(query: any) => setSearchedQuery(query)}
        getSearchedQuery={(query: any) => setSearchedQuery(query)}
        handleRefresh={handleRefresh}
        resetSearch={resetSearch}
        isSkeletonLoader={isSkeletonLoader}
        setIsGridView={(isChecked: any) => setIsGridView(isChecked)}
      />
      <InfiniteScrollGrid
        columnDefs={columnDefs}
        rowData={masterColumnsData}
        initialLoading={initialLoading}
        searchedQuery=""
        handleScroll={handleScroll}
        chatGroundRef={chatGroundRef}
        selectedColumns={selectedColumns}
        isLoading={isLoading}
        finalFetch={finalFetch}
        rowSelection={rowSelection}
        moreOptions={moreOptions}
        handleSort={handleSort}
        sorting={sorting}
        sortingColumnName={sortingColumnName}
        noDataFound={noDataFound}
      >
        {masterColumnsData?.map((rowData: any, i: number) => (
          <Fragment key={i}>
            <tr className="w-full h-auto flex justify-between p-[0.65rem] border-b border-[#EDEDED] hover:bg-[#eff8ff] dark:hover:bg-[#112A46]">
              <NameCellEditorRenderer
                cellKey="name"
                selectedColumns={selectedColumns}
                rowData={rowData}
                onUpdate={(row, cellkeyVal, inputValue) =>
                  handleInputChange(row.id, cellkeyVal, inputValue)
                }
              />
              <SelectCellEditorRenderer
                cellKey="type"
                selectedColumns={selectedColumns}
                rowData={rowData}
                options={dataTypeOptions}
                onUpdate={(row, cellkeyVal, inputValue) =>
                  handleInputChange(row.id, cellkeyVal, inputValue)
                }
              />
              <ToggleRenderer
                cellKey="isPrimaryValue"
                selectedColumns={selectedColumns}
                rowData={rowData}
                onUpdate={(row, cellkeyVal, inputValue) =>
                  handleInputChange(
                    row.id,
                    cellkeyVal,
                    inputValue === true ? 1 : 0
                  )
                }
              />
              <ToggleRenderer
                cellKey="isMandatory"
                selectedColumns={selectedColumns}
                rowData={rowData}
                onUpdate={(row, cellkeyVal, inputValue) =>
                  handleInputChange(
                    row.id,
                    cellkeyVal,
                    inputValue === true ? 1 : 0
                  )
                }
              />
              <DeleteOptionRenderer
                rowData={rowData}
                moreOptions={moreOptions}
                selectedMoreOption={selectedMoreOption}
              />
            </tr>
          </Fragment>
        ))}
      </InfiniteScrollGrid>
    </div>
  );
}
export default MasterDetailsDetailGrid;
