import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import IconTextBtn from "../../../form/icon-text-btn";
import { AddNewScreenIcon, DeleteIcon } from "../../../svg";
import UploadDamageImage from "../../../form/upload-image/damageIndex";
import IconBtn from "../../../form/icon-btn";

interface ImageDetails {
  uniqueId: string;
  name: string;
  overlay: string;
  labelIndex: number;
  isDeleted: number;
  imageInputsGroupUniqueId: any;
}

interface ImageLoaderState {
  [key: string]: boolean;
}

const ImageWithInput = ({
  register,
  processData,
  selectedScreen,
  currentQuestion,
  setProcessData,
}: any) => {
  const [imageDetails, setImageInputDetails] = useState<ImageDetails[]>([]);
  const [imageLoaderState, setImageLoaderState] = useState<ImageLoaderState>(
    {}
  );

  // Generates a new unique ID
  const generateGuid = () => uuidv4();

  // Gets image input details data from processData
  const getImageInputDetailsFromProcessData = () => {
    const item = processData.find(
      (item: any) => item.uniqueId === selectedScreen?.uniqueId
    );
    if (item) {
      const questionDetail = item.questionDetails.find(
        (detail: any) => detail.id === currentQuestion?.id
      );
      // Ensure questionDetail and imageInputDetails exist before accessing imageDetails
      return questionDetail?.imageInputDetails?.imageDetails || [];
    }
    return [];
  };

  // Update processData with new image input details data
  const updateProcessDataWithImageInputDetails = (
    updatedDetails: ImageDetails[]
  ) => {
    const updatedProcessData = [...processData];
    const itemIndex = updatedProcessData.findIndex(
      (item: any) => item.uniqueId === selectedScreen?.uniqueId
    );
    if (itemIndex !== -1) {
      const updatedItem = updatedProcessData[itemIndex];
      const screenDetailIndex = updatedItem.questionDetails.findIndex(
        (item: any) => item.id === currentQuestion?.id
      );
      if (screenDetailIndex !== -1) {
        // Ensure imageInputDetails exists before accessing imageDetails
        if (!updatedItem.questionDetails[screenDetailIndex].imageInputDetails) {
          updatedItem.questionDetails[screenDetailIndex].imageInputDetails = {
            imageDetails: [],
          }; // Initialize if it doesn't exist
        }
        updatedItem.questionDetails[
          screenDetailIndex
        ].imageInputDetails.imageDetails = updatedDetails;
        updatedProcessData[itemIndex] = updatedItem;
        setProcessData(updatedProcessData);
      }
    }
  };

  // Handles adding a new image input detail
  const handleAddNewImageInputDetail = () => {
    const newDetail: ImageDetails = {
      uniqueId: generateGuid(),
      name: "",
      overlay: "",
      labelIndex: imageDetails?.length + 1,
      isDeleted: 0,
      imageInputsGroupUniqueId:
        currentQuestion.imageInputDetails?.imageDetails[0]
          .imageInputsGroupUniqueId ?? "",
    };

    // Add the new detail to the imageDetails array
    const updatedDetails = [...imageDetails, newDetail];
    setImageInputDetails(updatedDetails);

    // Set initial loader state for the new image
    setImageLoaderState((prevState) => ({
      ...prevState,
      [newDetail.uniqueId]: false,
    }));

    // Update the process data with the new details
    updateProcessDataWithImageInputDetails(updatedDetails);
  };

  const updateImageInputDetailOverlay = (uniqueId: string, overlay: string) => {
    // Ensure the overlay URL is valid
    const updatedDetails = imageDetails.map((detail) =>
      detail.uniqueId === uniqueId
        ? { ...detail, overlay, labelIndex: detail.labelIndex } // Keep labelIndex unchanged
        : detail
    );
    setImageInputDetails(updatedDetails);
    updateProcessDataWithImageInputDetails(updatedDetails);
  };

  // Handles marking an image input detail as deleted
  // Handles marking an image input detail as deleted
  const handleDeleteImageInputDetail = (uniqueId: string) => {
    const updatedDetails = imageDetails.map((detail) =>
      detail.uniqueId === uniqueId
        ? { ...detail, isDeleted: 1 } // Mark as deleted
        : detail
    );

    setImageInputDetails(updatedDetails);
    updateProcessDataWithImageInputDetails(updatedDetails);
  };

  // Updates image input detail's overlay URL

  // Sets loader state for an image input detail
  const setImageInputDetailLoader = (uniqueId: string, isLoading: boolean) => {
    setImageLoaderState((prevState) => ({
      ...prevState,
      [uniqueId]: isLoading,
    }));
  };

  // Fetch image input details data from processData on mount or when processData/selectedScreen changes
  useEffect(() => {
    const initialDetails = getImageInputDetailsFromProcessData();
    if (initialDetails.length > 0) {
      setImageInputDetails(initialDetails);
      const initialLoaderState: ImageLoaderState = {};
      initialDetails.forEach((detail: ImageDetails) => {
        initialLoaderState[detail.uniqueId] = false;
      });
      setImageLoaderState(initialLoaderState);
    }
  }, [processData, selectedScreen]);

  return (
    <div className="bg-white dark:bg-[#272936] p-6 rounded-lg m-[3.75rem]">
      <div className="w-full flex justify-end pr-2 mb-5">
        <IconTextBtn
          icon={<AddNewScreenIcon />}
          label="Add New Part"
          btnSize="large"
          handleClick={handleAddNewImageInputDetail}
        />
      </div>
      <div className="grid grid-cols-12 gap-4">
        {imageDetails
          .filter((item) => item.isDeleted === 0)
          .map((item) => (
            <div
              key={item.uniqueId}
              className="h-[6.4rem] col-span-12 md:col-span-6 flex items-center justify-center space-x-8 m-8"
            >
              <div className="w-[25%] flex items-center justify-center">
                <UploadDamageImage
                  id={item.uniqueId}
                  imageUrl={item.overlay}
                  setImage={(url: string) =>
                    updateImageInputDetailOverlay(item.uniqueId, url)
                  }
                  isImageLoader={imageLoaderState[item.uniqueId] || false}
                  setImageLoader={(isLoading: boolean) =>
                    setImageInputDetailLoader(item.uniqueId, isLoading)
                  }
                />
              </div>
              <div className="w-full flex items-center justify-center">
                <input
                  className="w-full text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white border border-io-primary bg-transparent px-[2rem] py-[1.2rem] rounded-[0.5rem]"
                  placeholder="Input Name"
                  value={item.name}
                  onChange={(e) => {
                    const updatedDetails = [...imageDetails];
                    updatedDetails[
                      imageDetails.findIndex(
                        (detail) => detail.uniqueId === item.uniqueId
                      )
                    ].name = e.target.value;
                    setImageInputDetails(updatedDetails);
                    updateProcessDataWithImageInputDetails(updatedDetails);
                  }}
                />
              </div>
              <div className="flex items-center justify-center">
                <IconBtn
                  icon={<DeleteIcon />}
                  size="w-[1.5rem]"
                  handleClick={() =>
                    handleDeleteImageInputDetail(item.uniqueId)
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageWithInput;
