const typeOfResponse = [
  {
    id: 1,
    groupId: 1,
    type: 1,
    subType: 0,
    displayText: "Tenant Name",
    token: "web.logic.label.tenantname",
    createdAt: "1/4/2024 12:46:10.47",
    isDefault: 1,
  },
  {
    id: 2,
    groupId: 1,
    type: 2,
    subType: 0,
    displayText: "Site Name",
    token: "web.logic.label.sitename",
    createdAt: "1/4/2024 12:46:10.507",
    isDefault: 1,
  },
  {
    id: 3,
    groupId: 1,
    type: 3,
    subType: 0,
    displayText: "Person Name",
    token: "web.logic.label.personname",
    createdAt: "1/4/2024 12:46:10.533",
    isDefault: 1,
  },
  {
    id: 4,
    groupId: 1,
    type: 4,
    subType: 0,
    displayText: "Location",
    token: "web.logic.label.location",
    createdAt: "1/4/2024 12:46:10.567",
    isDefault: 1,
  },
  {
    id: 5,
    groupId: 2,
    type: 5,
    subType: 0,
    displayText: "Scanner - All",
    token: "web.logic.label.scannerall",
    createdAt: "1/4/2024 12:46:10.597",
    isDefault: 1,
  },
  {
    id: 6,
    groupId: 2,
    type: 5,
    subType: 1,
    displayText: "Scanner - BarCode",
    token: "web.logic.label.scannerbarcode",
    createdAt: "1/4/2024 12:46:10.63",
    isDefault: 1,
  },
  {
    id: 7,
    groupId: 2,
    type: 5,
    subType: 2,
    displayText: "Scanner - QRCode",
    token: "web.logic.label.scannerqrcode",
    createdAt: "1/4/2024 12:46:10.66",
    isDefault: 1,
  },
  {
    id: 8,
    groupId: 2,
    type: 5,
    subType: 3,
    displayText: "Scanner - OCR",
    token: "web.logic.label.scannerocr",
    createdAt: "1/4/2024 12:46:10.69",
    isDefault: 1,
  },
  {
    id: 9,
    groupId: 2,
    type: 6,
    subType: 0,
    displayText: "Multiple Choice - Radio",
    token: "web.logic.label.multiplechoiceradio",
    createdAt: "1/4/2024 12:46:10.717",
    isDefault: 1,
  },
  {
    id: 10,
    groupId: 2,
    type: 6,
    subType: 1,
    displayText: "Multiple Choice - Checkbox",
    token: "web.logic.label.multiplechoicecheckbox",
    createdAt: "1/4/2024 12:46:10.75",
    isDefault: 1,
  },
  {
    id: 11,
    groupId: 2,
    type: 6,
    subType: 2,
    displayText: "Multiple Choice - Dropdown",
    token: "web.logic.label.multiplechoicedropdown",
    createdAt: "1/4/2024 12:46:10.78",
    isDefault: 1,
  },
  {
    id: 12,
    groupId: 2,
    type: 7,
    subType: 0,
    displayText: "Text Short",
    token: "web.logic.label.textshort",
    createdAt: "1/4/2024 12:46:10.81",
    isDefault: 1,
  },
  {
    id: 13,
    groupId: 2,
    type: 7,
    subType: 1,
    displayText: "Text Long",
    token: "web.logic.label.textlong",
    createdAt: "1/4/2024 12:46:10.84",
    isDefault: 1,
  },
  {
    id: 14,
    groupId: 2,
    type: 8,
    subType: 0,
    displayText: "Number",
    token: "web.logic.label.number",
    createdAt: "1/4/2024 12:46:10.87",
    isDefault: 0,
  },
  {
    id: 15,
    groupId: 2,
    type: 9,
    subType: 0,
    displayText: "Date",
    token: "web.logic.label.date",
    createdAt: "1/4/2024 12:46:10.897",
    isDefault: 0,
  },
  {
    id: 16,
    groupId: 2,
    type: 9,
    subType: 1,
    displayText: "Time",
    token: "web.logic.label.time",
    createdAt: "1/4/2024 12:46:10.93",
    isDefault: 0,
  },
  {
    id: 17,
    groupId: 2,
    type: 9,
    subType: 2,
    displayText: "Date Time",
    token: "web.logic.label.datetime",
    createdAt: "1/4/2024 12:46:10.96",
    isDefault: 0,
  },
  {
    id: 18,
    groupId: 2,
    type: 10,
    subType: 0,
    displayText: "Acknowledgment",
    token: "web.logic.label.acknowledgment",
    createdAt: "1/4/2024 12:46:10.99",
    isDefault: 0,
  },
  {
    id: 19,
    groupId: 2,
    type: 11,
    subType: 0,
    displayText: "Information",
    token: "web.logic.label.information",
    createdAt: "1/4/2024 12:46:11.02",
    isDefault: 0,
  },
  {
    id: 20,
    groupId: 2,
    type: 12,
    subType: 0,
    displayText: "Signature",
    token: "web.logic.label.signature",
    createdAt: "1/4/2024 12:46:11.047",
    isDefault: 0,
  },
  {
    id: 21,
    groupId: 2,
    type: 13,
    subType: 0,
    displayText: "Image",
    token: "web.logic.label.image",
    createdAt: "1/4/2024 12:46:11.077",
    isDefault: 0,
  },
  {
    id: 22,
    groupId: 2,
    type: 14,
    subType: 0,
    displayText: "Image with Inputs",
    token: "web.logic.label.imagewithInputs",
    createdAt: "1/4/2024 12:46:11.107",
    isDefault: 0,
  },
  {
    id: 23,
    groupId: 2,
    type: 15,
    subType: 0,
    displayText: "Video",
    token: "web.logic.label.video",
    createdAt: "1/4/2024 12:46:11.137",
    isDefault: 0,
  },
  {
    id: 24,
    groupId: 2,
    type: 16,
    subType: 0,
    displayText: "Magix Container",
    token: "web.logic.label.magiccontainer",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 25,
    groupId: 2,
    type: 17,
    subType: 0,
    displayText: "Audio",
    token: "web.logic.label.audio",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 26,
    groupId: 2,
    type: 18,
    subType: 0,
    displayText: "Toggle",
    token: "web.logic.label.toggle",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 27,
    groupId: 2,
    type: 9,
    subType: 3,
    displayText: "Range Date",
    token: "web.logic.label.rangeDate",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 28,
    groupId: 2,
    type: 6,
    subType: 3,
    displayText: "Multiple Choice - Dropdown Multi Select",
    token: "web.logic.label.mcmp",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 29,
    groupId: 2,
    type: 19,
    subType: 0,
    displayText: "Grids",
    token: "web.logic.label.grids",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 30,
    groupId: 2,
    type: 14,
    subType: 1,
    displayText: "Image with Inputs",
    token: "web.logic.label.imagewithInputs",
    createdAt: "1/4/2024 12:46:11.107",
    isDefault: 0,
  }
];
const multipleChoiceOptions = [
  { id: 0, value: "radioButton", label: "Radio Button" },
  { id: 1, value: "multipleChoice", label: "Multiple Choice" },
  { id: 2, value: "dropDown", label: "DropDown" },
  { id: 3, value: "dropdownMultiSelect", label: "Dropdown Multi Select" },
];
const dateTimeOptions = [
  { id: 0, value: "date", label: "Date" },
  { id: 1, value: "time", label: "Time" },
  { id: 2, value: "datetime", label: "Date Time" },
  { id: 3, value: "daterange", label: "Date Range" },
];

const scannerOptions = [
  { id: 0, value: "all", label: "All" },
  { id: 1, value: "barCode", label: "Bar Code" },
  { id: 2, value: "qrCode", label: "QR Code" },
  { id: 3, value: "ocr", label: "OCR" },
];

const imageOptions = [
  { id: 0, value: "na", label: "N/A" },
  { id: 1, value: "1", label: "1" },
  { id: 2, value: "2", label: "2" },
  { id: 3, value: "3", label: "3" },
  { id: 4, value: "4", label: "4" },
  { id: 5, value: "5", label: "5" },
];

const textOptions = [
  { id: 0, value: "shorttext", label: "Short Text" },
  { id: 1, value: "longtext", label: "Long Text" },
];

export {
  typeOfResponse,
  multipleChoiceOptions,
  dateTimeOptions,
  scannerOptions,
  imageOptions,
  textOptions,
};
