const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const baseWrapperUrl = process.env.REACT_APP_WRAPPER_URL;


const baseApiUrlList = {
    "api.web.loginValidate": `${baseApiUrl}/LoginValidation`,
    "api.web.loginAzure": `${baseApiUrl}/LoginValidationAzure`,
    "api.web.userLists": `${baseApiUrl}/UserListFetch`,
    "api.web.usersDetails": `${baseApiUrl}/EditUserListFetch`,
    "api.web.userCreation": `${baseApiUrl}/UserCreation`,
    "api.web.userUpdate": `${baseApiUrl}/UserEdit`,
    "api.web.userDelete": `${baseApiUrl}/UserDelete`,
    "api.web.blobstorage": `${baseApiUrl}/BlobStorage`,
    "api.web.magicList": `${baseApiUrl}/MagicLists`,
    "api.web.magicAction": `${baseApiUrl}/MagicAction`,
    "api.web.magicDetails": `${baseApiUrl}/MagicDetails`,
    "api.web.magicAllList": `${baseApiUrl}/MagicAllList`,
    "api.web.containerLists": `${baseApiUrl}/ContainerLists`,
    "api.web.containerAction": `${baseApiUrl}/ContainerAction`,
    "api.web.containerDetails": `${baseApiUrl}/ContainerDetails`,
    "api.web.sidebarDetails": `${baseApiUrl}/SidebarDetails`,
    "api.web.logicList": `${baseApiUrl}/LogicList`,
    "api.web.logicAction": `${baseApiUrl}/LogicAction`,
    "api.web.logicDetail": `${baseApiUrl}/LogicDetail`,
    "api.web.questionAction": `${baseApiUrl}/QuestionnaireAction`,
    "api.web.questionOption": `${baseApiUrl}/QuestionnaireOptions`,
    "api.web.processLists": `${baseApiUrl}/ProcessLists`,
    "api.web.processStatusAction": `${baseApiUrl}/ProcessStatusAction`,
    "api.web.processAuthTokenAction": `${baseApiUrl}/ProcessAuthTokenAction`,
    "api.web.screenCollectionList": `${baseApiUrl}/ScreenCollectionList`,
    "api.web.screenCollectionAction": `${baseApiUrl}/ScreenCollectionAction`,
    "api.web.screenCollectionDetail": `${baseApiUrl}/ScreenCollectionDetail`,
    "api.web.startupScreenList": `${baseApiUrl}/StartupScreenList`,
    "api.web.startupScreenAction": `${baseApiUrl}/StartupScreenAction`,
    "api.web.startupScreenDetail": `${baseApiUrl}/StartupScreenDetail`,
    "api.web.screenMappingList": `${baseApiUrl}/ScreenMappingList`,
    "api.web.screenMappingAction": `${baseApiUrl}/ScreenMappingAction`,
    "api.web.screenMappingDetail": `${baseApiUrl}/ScreenMappingDetail`,
    "api.web.questionnaireOption": `${baseApiUrl}/QuestionnaireOptions`,
    "api.web.questionnaireOptionAction": `${baseApiUrl}/QuestionnaireOptionAction`,
    "api.web.startupScreenLists": `${baseApiUrl}/StartupScreenLists`,
    "api.web.questionnaireDetails": `${baseApiUrl}/QuestionnaireDetails`,
    "api.web.bindingDetails": `${baseApiUrl}/BindingsFetch`,
    "api.web.containerMasterFetch": `${baseApiUrl}/ContainerMasterFetch`,
    "api.web.logicMappingAction": `${baseApiUrl}/LogicMappingAction`,
    "api.web.logicMappingDetails": `${baseApiUrl}/LogicMappingDetails`,
    "api.web.masterAccessDetailsFetch": `${baseApiUrl}/MasterAccessDetailsFetch`,
    "api.web.masterAccessActionFetch": `${baseApiUrl}/MasterAccessActionFetch`,
    "api.web.masterAccessAction": `${baseApiUrl}/MasterAccessAction`,
    "api.web.masterColumnDetailsFetch": `${baseApiUrl}/MasterColumnDetailsFetch`,
    "api.web.masterColumnDetailsAction": `${baseApiUrl}/MasterColumnDetailsAction`,
    "api.web.masterColumnDetailsActionFetch": `${baseApiUrl}/MasterColumnDetailsActionFetch`,
    "api.web.masterBindingsFetch": `${baseApiUrl}/BindingsFetch`,
    "api.web.masterDetailsFetch": `${baseApiUrl}/MasterDetailsFetch`,
    "api.web.masterDetailsActionFetch": `${baseApiUrl}/MasterDetailsActionFetch`,
    "api.web.changePassword": `${baseApiUrl}/ChangePassword`,
    "api.web.masterDetailsAction": `${baseApiUrl}/MasterDetailsAction`,
    "api.web.masterDetailsExcelUpload": `${baseApiUrl}/MasterDetailsExcelUpload`,
    "api.web.samModelServices": `${baseWrapperUrl}/SamModelSegmentation`,
    "api.web.userSiteSwitch": `${baseApiUrl}/UserSiteSwitchWeb`,
    "api.web.processExportAction": `${baseApiUrl}/ProcessExportAction`,
    "api.web.dashboardWeb": `${baseApiUrl}/DashboardWeb`,
    "api.web.getLogicAndSubProcess": `${baseApiUrl}/GetLogicAndSubProcess`,
    "api.web.getStartedFetch": `${baseApiUrl}/GetStartedFetch`,
    "api.web.getStartedAction": `${baseApiUrl}/GetStartedAction`, 
  };

   
  export {  baseApiUrlList  };