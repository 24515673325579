import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import {
  MultiSelectInputWithoutForm,
  SelectInputWithoutForm,
} from "../../../form/select-input";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import {
  ResponseLocationIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
} from "../../../svg";
import { RadioBtnsWithoutForm } from "../../../form/radio-btns";
import { processServices } from "../../../../services/InspectionOneServices";
import CheckBoxGroup from "../../../form/checkbox-group";
import Authentication from "../../../../auth/Authentication";
import { v4 as uuidv4 } from "uuid";
function CreateProcessForm({setIsInspectionModalOpen,handleRefresh, processUpdated }: any) {
  const navigate = useNavigate();
  const [processFormData, setProcessFormData] = useState({
    processLogicId: "",
    subProcessLogicId: "",
    processId: "",
  });
  const [errors, setErrors] = useState({
    processLogicId: "",
    subProcessLogicId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [LogicAndSubProcess, setLogicAndSubProcess] = useState<any>([]);
  const [StartedFetch, setStartedFetch] = useState<any>([]);
  const [fieldErrors, setFieldErrors] = useState<any>({});

  //Initial Fetch APi
  useEffect(() => {
    fetchProcessData();
  }, []);

  const fetchProcessData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const requestJson = {
          processLogicId: "",
        };
        const response: any = await processServices.GetLogicAndSubProcess(
          requestJson
        );
        if (response.success) {
          const responseData = response.data;
          setLogicAndSubProcess(responseData);
          setIsLoading(false);
        }
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchGetStartedata = async (
    processLogicId: any,
    subProcessLogicId: any
  ) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const requestJson = {
          processLogicId: processLogicId,
          subProcessLogicId: subProcessLogicId,
        };
        const response: any = await processServices.GetStartedFetch(
          requestJson
        );
        if (response.success) {
          const responseData = response.data;
          setStartedFetch(responseData);
          setIsLoading(false);
        }
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  //

  useEffect(() => {
    if (processFormData?.processLogicId && processFormData?.subProcessLogicId) {
      fetchGetStartedata(
        processFormData.processLogicId,
        processFormData.subProcessLogicId
      );
    }
  }, [processFormData.processLogicId, processFormData.subProcessLogicId]);

  const handleProcessInputChange = (name: string, value: any) => {
    setProcessFormData((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };
      if (name === "processLogicId") {
        updatedState.subProcessLogicId = "";
      }
      return updatedState;
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleMultiSelectChange = (
    screenId: any,
    questionId: any,
    subType: any,
    selectedOptions: any[]
  ) => {
    handleInputChange(
      screenId,
      questionId,
      "defaultAnswer",
      selectedOptions,
      false
    );
  };

  const handleMultipleOptionChange = (
    screenId: any,
    questionId: any,
    subType: any,
    option: any
  ) => {
    switch (subType) {
      case 0:
        handleInputChange(
          screenId,
          questionId,
          "defaultAnswer",
          [option],
          false
        );
        break;
      case 1:
        handleInputChange(screenId, questionId, "defaultAnswer", option, false);
        break;
      case 2:
        option = Array.isArray(option) ? option : [option];
        handleInputChange(screenId, questionId, "defaultAnswer", option, false);
        break;
      default:
        handleInputChange(screenId, questionId, "defaultAnswer", option, false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    StartedFetch?.screenDetail?.initialScreenDetails[0]?.questionDetails.forEach((dataItem:any) => {
      var screenId = StartedFetch?.screenDetail?.initialScreenDetails[0].id;
      var value = StartedFetch?.screenDetail?.answerDetail[screenId][dataItem.id].defaultAnswer;
      if (dataItem.isMandatory === 1 && !value) {
        newErrors[dataItem.id] = `${dataItem.title} is required.`;
      }
    });
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleFormValidation = () => {
    const isProcessLogicEmpty = processFormData.processLogicId === "";
    const isSubProcessLogicEmpty = processFormData.subProcessLogicId === "";
    setErrors({
      processLogicId: isProcessLogicEmpty ? "Process Logic is required" : "",
      subProcessLogicId: isSubProcessLogicEmpty
        ? "Sub Process Logic is required"
        : "",
    });
    return !isProcessLogicEmpty && !isSubProcessLogicEmpty;
  };
  //#region SDK

  const authService = Authentication();
  const [accessMode, setAccessMode] = useState<string>("");

  useEffect(() => {
    let parsedLoginDetails = null;
    parsedLoginDetails = authService.decryptData("UserDetails", 2);
    parsedLoginDetails = parsedLoginDetails || "{}";
    setAccessMode(parsedLoginDetails.isViewAccess);
    setUserProfileImage(parsedLoginDetails.imageUrl);
    setUserName(parsedLoginDetails.userName);
  }, []);
  const generateGuid = () => {
    return uuidv4();
  };
  const [userProfileImage, setUserProfileImage] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  
  const handleNavigate = async () => {
    const webSdkUrl = process.env.REACT_APP_WEB_SDK_URL;

    if (webSdkUrl) {
      const baseUrl = `${webSdkUrl}/InspectionOne`;
      try {
        const requestJson = {
          uniqueId: generateGuid(),
          logicUniqueId: StartedFetch.processLogicId,
          subLogicUniqueId: StartedFetch.subProcessLogicId,
          processUniqueId: StartedFetch.processId,
          sdkPortalLink: baseUrl,
          UserType:
            accessMode === "Verified"
              ? "External"
              : accessMode === "0"
              ? "Internal"
              : "External",
          languageCode: "en",
          userProfileImage: userProfileImage,
        };
        const response: any = await processServices.ProcessAuthTokenAction(
          requestJson
        );

        if (response.success) {
          const { authToken, siteId, languageCode } = response.data;
          const url = `${baseUrl}/${authToken}/${siteId}/${languageCode}`;
          window.open(url, "_blank");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };
  //#endregion
  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      if (!validateFields()) {
        return;
      }
      setIsLoading(true);
      StartedFetch.screenDetail.labelValue = getLabelValue(StartedFetch)
      StartedFetch.userName = userName;
      try {
        const response: any = await processServices.GetStartedAction(
          StartedFetch
        );
        if (response.success) {
          processUpdated(true);
          setIsInspectionModalOpen(false);
          setIsLoading(false);
          handleRefresh()
          setTimeout(() => {
            handleNavigate();
          }, 1000);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const getLabelValue = (StartedFetch:any) => {
    const { initialScreenDetails,answerDetail } = StartedFetch.screenDetail;
    if (initialScreenDetails) {
      const initialScreen = initialScreenDetails[0];
      if (initialScreen) {
        const firstQuestion = initialScreen.questionDetails[0];
        if (firstQuestion) {
          return answerDetail[initialScreen.id]?.[firstQuestion.id]?.defaultAnswer;
        }
      }
    }
  };

  const handleFormCancel = () => {
    setIsInspectionModalOpen(false)
  };

  const handleInputChange = (
    selectedScreenId: any,
    questionUniqueId: any,
    name: any,
    value: any,
    isMultiSelect: boolean
  ) => {
    const updatedData = { ...StartedFetch };

    if (isMultiSelect) {
      const currentValues =
        updatedData?.screenDetail?.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] || [];

      const valueIndex = currentValues.findIndex((v: any) => v.id === value.id);

      if (valueIndex === -1) {
        updatedData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] = [...currentValues, value];
      } else {
        updatedData.screenDetail.answerDetail[selectedScreenId][
          questionUniqueId
        ][name] = currentValues.filter((v: any) => v.id !== value.id);
      }
    } else {
      updatedData.screenDetail.answerDetail[selectedScreenId][questionUniqueId][
        name
      ] = value;
    }
    setStartedFetch(updatedData);
  };

  const getDefaultInputValueAnswer = (questionuniqueId: string) => {
    const typeDetail =
      StartedFetch.screenDetail?.typeDetail?.[questionuniqueId];
    if (!typeDetail) return [];

    const multiChoice = typeDetail.multiChoiceDetailsKey;
    if (!multiChoice) return [];

    const masterDetail = StartedFetch.screenDetail?.masterDetail?.[multiChoice];
    return masterDetail || [];
  };

  const renderFields = () => {
    const iconMapping: { [key: number]: JSX.Element } = {
      1: <ResponseTenantNameIcon />,
      2: <ResponseSiteNameIcon />,
      3: <ResponsePersonNameIcon />,
      4: <ResponseLocationIcon />,
      5: <ResponseScannerIcon />,
      7: <ResponseTextIcon />,
    };

    return StartedFetch?.screenDetail?.initialScreenDetails[0]?.questionDetails
      ?.sort((a: any, b: any) => a.orderNo - b.orderNo)
      .map((dataItem: any, j: number) => {
        const screenId = StartedFetch?.screenDetail?.initialScreenDetails[0].id;
        const defaultInputValue = getDefaultInputValueAnswer(dataItem.id);

        switch (dataItem.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return (
              <div key={j} className="w-[98%] h-auto mb-[3.8rem]">
                <InputFieldWithoutForm
                  labelName={dataItem.title}
                  type="text"
                  id={`mappingItems.${dataItem.id}`}
                  isRequired={dataItem.isMandatory === 1}
                  fieldIcon={iconMapping[dataItem.type] || null} // Dynamically set icon
                  error={fieldErrors[dataItem.id] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      screenId,
                      dataItem.id,
                      "defaultAnswer",
                      e,
                      false
                    )
                  }
                />
              </div>
            );
          case 7:
            return (
              <div key={j} className="w-[98%] h-auto mb-[3.8rem]">
                {dataItem.subType === 0 ? (
                  <InputFieldWithoutForm
                    labelName={dataItem.title}
                    type="text"
                    id="personName"
                    isRequired={dataItem.isMandatory === 1}
                    error={fieldErrors[dataItem.id] || ""}
                    onChange={(e) =>
                      handleInputChange(
                        screenId,
                        dataItem.id,
                        "defaultAnswer",
                        e,
                        false
                      )
                    }
                    fieldIcon={<ResponseTextIcon />}
                  />
                ) : dataItem.subType === 1 ? (
                  <TextAreaField
                    labelName={dataItem.title}
                    type="text"
                    id="notes"
                    isRequired={dataItem.isMandatory === 1}
                    // value={""}
                    onChange={(e) =>
                      handleInputChange(
                        screenId,
                        dataItem.id,
                        "defaultAnswer",
                        e,
                        false
                      )
                    }
                    error={fieldErrors[dataItem.id] || ""}
                  />
                ) : null}
              </div>
            );
          case 6:
            return (
              <div key={j} className="w-[98%] h-auto mb-[3.8rem]">
                {dataItem.subType === 0 ? (
                  <RadioBtnsWithoutForm
                    options={defaultInputValue.map((item: any) => ({
                      id: item.id,
                      value: item.value,
                      label: item.value,
                    }))}
                    name="multipleChoiceRadio"
                    defaultValue={undefined}
                    onOptionChange={(selectedOption: any) =>
                      handleMultipleOptionChange(
                        screenId,
                        dataItem.id,
                        dataItem.subType,
                        selectedOption
                      )
                    }
                    error={fieldErrors[dataItem.id] || ""}
                  />
                ) : dataItem.subType === 1 ? (
                  <CheckBoxGroup
                    options={defaultInputValue.map((item: any) => ({
                      id: item.id,
                      value: item.value,
                      label: item.value,
                    }))}
                    name="multipleChoiceCheckBox"
                    defaultValues={[]}
                    checked={[]}
                    onOptionChange={(selectedOptions: any[]) =>
                      handleMultipleOptionChange(
                        screenId,
                        dataItem.id,
                        dataItem.subType,
                        selectedOptions
                      )
                    }
                  />
                ) : dataItem.subType === 2 ? (
                  <SelectInputWithoutForm
                    labelName={dataItem.title}
                    id="multipleChoiceDropdown"
                    isRequired={false}
                    options={defaultInputValue.map((item: any) => ({
                      id: item.id,
                      value: item.value,
                      label: item.value,
                    }))}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.id}
                    getSelection={(selectedOption: any) =>
                      handleMultipleOptionChange(
                        screenId,
                        dataItem.id,
                        dataItem.subType,
                        selectedOption
                      )
                    }
                    defaultValue={undefined}
                    error={fieldErrors[dataItem.id] || ""}
                  />
                ) : dataItem.subType === 3 ? (
                  <MultiSelectInputWithoutForm
                    labelName={dataItem.title}
                    idName="LabelNames"
                    options={defaultInputValue.map((item: any) => ({
                      id: item.id,
                      value: item.value,
                      label: item.value,
                    }))}
                    onChange={(selectedOptions: any[]) =>
                      handleMultiSelectChange(
                        screenId,
                        dataItem.id,
                        dataItem.subType,
                        selectedOptions
                      )
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    defaultVal={undefined}
                    error={fieldErrors[dataItem.id] || ""}
                  />
                ) : dataItem.subType === 4 ? (
                  <MultiSelectInputWithoutForm
                    labelName={dataItem.title}
                    idName="LabelNames"
                    options={defaultInputValue.map((item: any) => ({
                      id: item.id,
                      value: item.value,
                      label: item.value,
                    }))}
                    onChange={(selectedOptions: any[]) =>
                      handleMultiSelectChange(
                        screenId,
                        dataItem.id,
                        dataItem.subType,
                        selectedOptions
                      )
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    defaultVal={undefined}
                    error={fieldErrors[dataItem.id] || ""}
                  />
                ) : null}
              </div>
            );
          default:
            return null;
        }
      });
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 pr-2 overflow-y-auto scrollbar-large">
        <div className="w-[100%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[48%]">
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <SelectInputWithoutForm
                    labelName="Process Logic"
                    id="processLogic"
                    isRequired={true}
                    error={errors.processLogicId}
                    options={LogicAndSubProcess.map((logic: any) => ({
                      label: logic.name,
                      id: logic.id,
                    }))}
                    defaultValue={
                      LogicAndSubProcess.map((logic: any) => ({
                        label: logic.name,
                        id: logic.id,
                      })).find(
                        (option: any) =>
                          option.id === processFormData.processLogicId
                      ) || null
                    } // Find the matching option or set to null
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.id}
                    getSelection={(option: any) =>
                      handleProcessInputChange("processLogicId", option.id)
                    }
                  />
                )}
              </div>
            </div>
            <div className="w-[48%]">
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <SelectInputWithoutForm
                    key={processFormData.processLogicId} // Force re-render when processLogicId changes
                    labelName="Sub Process Logic"
                    id="subProcessLogic"
                    isRequired={true}
                    error={errors.subProcessLogicId}
                    options={
                      LogicAndSubProcess.find(
                        (logic: any) =>
                          logic.id === processFormData.processLogicId
                      )?.subProcessLogicList.map((subLogic: any) => ({
                        label: subLogic.name,
                        id: subLogic.id,
                      })) || []
                    }
                    defaultValue={
                      processFormData.subProcessLogicId
                        ? {
                            label: LogicAndSubProcess.find(
                              (logic: any) =>
                                logic.id === processFormData.processLogicId
                            )?.subProcessLogicList.find(
                              (subLogic: any) =>
                                subLogic.id ===
                                processFormData.subProcessLogicId
                            )?.name,
                            id: processFormData.subProcessLogicId,
                          }
                        : null
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.id}
                    getSelection={(option: any) =>
                      handleProcessInputChange("subProcessLogicId", option.id)
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="border border-1"></div>
          <div className="pt-10">
            <div className="w-full h-auto flex justify-between items-start ">
              <div className="w-full">
                {isLoading
                  ? Array.from({ length: 5 }).map((_, index) => (
                      <div key={index} className="mb-[3.8rem]">
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      </div>
                    ))
                  : renderFields()}
              </div>
            </div>
          </div>
          {/* <div className="w-full pt-4">
            {isLoading ? (
              <Skeleton width="100%" height={100} borderRadius={3} />
            ) : (
              <FileUpload />
            )}
          </div> */}
        </div>
      </div>
      {StartedFetch?.screenDetail?.initialScreenDetails[0]?.questionDetails
        .length > 0 && (
        <ModalPageFooter
          footerType="onlySaveButtonForm"
          handleCancel={handleFormCancel}
          handleSubmit={handleFormSubmit}
          isLoading={isLoading}
          buttonText={
            StartedFetch?.screenDetail?.getStartedButtonLabel
              ? StartedFetch.screenDetail.getStartedButtonLabel
              : "Save"
          }
        />
      )}
    </div>
  );
}

export default CreateProcessForm;
