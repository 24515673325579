/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useState } from "react";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import ResponseSettingsForm from "../response-settings-form";
import ResponseValueForm from "../response-value-form";
import { useTranslation } from "react-i18next";
import ResponseSettingsFormNew from "../response-settings-form-new";

function ResponseSettingsNew({
  selectedScreen,
  handleClose,
  responseSettingsType,
  handleImport,
  handleReponseSettingsSubmit,
  selectedResponseValue,
  responseValueOptions,
  selectedResponseItem,
  selectedChoice,
  responseMagicContainerOptions,
  handleSaveResponse,
  passUpdatedResponseval,
  bindingoptions,
  watchedFields,
  multipleOptionValidation,
}: any) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<string>("settings");

  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <ModalPageHeader
              title={t("web.common.responsesettings")}
              handleClose={handleClose}
            />
            <div className="w-full h-full flex-auto">
              <div className="w-full h-full pl-[4.7rem] pt-[3.7rem] pr-4">
                <ResponseSettingsFormNew
                  selectedScreen={selectedScreen}
                  responseSettingsType={responseSettingsType}
                  handleReponseSettingsSubmit={handleReponseSettingsSubmit}
                  handleFormCancel={handleClose}
                  selectedResponseItem={selectedResponseItem}
                  selectedChoice={selectedChoice}
                  responseMagicContainerOptions={responseMagicContainerOptions}
                  handleSaveResponse={(data: any) => handleSaveResponse(data)}
                  bindingoptions={bindingoptions}
                  handleImport={handleImport}
                  selectedResponseValue={selectedResponseValue}
                  responseValueOptions={responseValueOptions}
                  passUpdatedResponseval={passUpdatedResponseval}
                  watchedFields={watchedFields}
                  multipleOptionValidation={multipleOptionValidation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ResponseSettingsNew;
