import { useEffect, useRef } from "react";
import uploadFile from "../../../services/uploadFile";
import Skeleton from "react-loading-skeleton";
import defaultIconImage from "../../../assets/images/upload-image-thumbnail.png";
import IconBtn from "../icon-btn";
import { DeleteIcon } from "../../svg";

function UploadDamageImage({
  id,
  imageUrl,
  setImage,
  isImageLoader,
  setImageLoader,
}: any) {
  const uploadFiles = useRef<HTMLInputElement>(null);
  useEffect(() => {
    console.log("isImageLoader updated:", isImageLoader);
    console.log("isImageLoader imageUrl:", imageUrl);
  }, [isImageLoader]);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    let url: any;
    if (file) {
      try {
        setImageLoader(true);
        const extension = file.name.split(".").pop() || "";
        const { response } = await uploadFile(file, extension);
        url = response.data.fileUrl;
        setImage(url);
        console.log("url:", url);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setImageLoader(false);
      }
    }
  };
  const handleDelete = () => {
    setImage("");
  };
  const handleError = (e: any) => {
    console.log("error:");
    e.target.src = defaultIconImage;
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full h-[15.4rem] flex items-center justify-center">
        {isImageLoader ? (
          <>
            <div>{isImageLoader}</div>
            <Skeleton className="w-20 h-20" borderRadius={3} />
          </>
        ) : (
          <>
            <input
              type="file"
              accept="Image/*"
              id={`uploadNewUserProfile-${id}`}
              onChange={handleFileInputChange}
              ref={uploadFiles}
              className="hidden w-0 h-0"
            />

            {imageUrl ? (
              <div className="w-full h-[50%] relative flex items-center justify-center">
                <div className="w-full h-full rounded-[0.5rem] overflow-hidden border border-io-gray-c8 flex items-center justify-center">
                  <img
                    src={imageUrl}
                    alt="uploadedImage"
                    className="max-w-full max-h-full object-contain"
                    onError={(e) => handleError(e)}
                  />
                </div>
                <div className="w-auto absolute bottom-[2%] right-[2%]">
                  <div className="w-auto flex justify-end items-center gap-[1rem]">
                    <div className="w-[1.2rem]">
                      <IconBtn
                        icon={<DeleteIcon />}
                        size="small"
                        handleClick={handleDelete}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <label
                htmlFor={`uploadNewUserProfile-${id}`}
                className="w-full h-[50%] flex items-center justify-center border border-dashed border-io-primary rounded-[0.5rem]"
              >
                <div className="w-full h-full flex justify-center items-center">
                  <div className="w-[7.2rem] h-auto mx-auto">
                    <img
                      src={defaultIconImage}
                      alt="uploadimagethumbnail"
                      className="object-contain"
                    />
                  </div>
                </div>
              </label>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default UploadDamageImage;
